import { useDispatch } from 'react-redux';

import {
  setPicklistData,
  getLocationLooseItems,
  clearState,
  checkLooseItemsLocation,
  addLooseItemsToPicklist,
  editLooseItemOfPicklist
} from 'actions/managePicklistItemActions';

export function useManagePicklistItemActions() {
  const dispatch = useDispatch();

  const getLocationLooseItemsAction = (q) => dispatch(getLocationLooseItems(q));

  const checkLooseItemsLocationAction = (q) => dispatch(checkLooseItemsLocation(q));

  const addLooseItemsToPicklistAction = (data) => dispatch(addLooseItemsToPicklist(data));
  const editLooseItemOfPicklistAction = (data) => dispatch(editLooseItemOfPicklist(data));

  const setPicklistDataAction = (data) => dispatch(setPicklistData(data));

  const clearStateAction = () => dispatch(clearState());

  return {
    setPicklistDataAction,
    getLocationLooseItemsAction,
    checkLooseItemsLocationAction,
    addLooseItemsToPicklistAction,
    editLooseItemOfPicklistAction,
    clearStateAction
  };
}
