import { useSelector } from 'react-redux';
import { selectUnsavedFormData } from 'pages/commonSelectors';
import {
  selectActiveTemplate,
  selectLocationReportsFilter,
  selectSingleGeneralReport,
  selectStartAutoSaveTimerData,
  selectFilterCriteriaData,
  selectAssetReportsFilter,
  selectGeneralReportsFilter,
  selectCreationFormData
} from 'pages/ReportLists/selectors';

export function useReportSelector() {
  const locationReportsFilter = useSelector(selectLocationReportsFilter());
  const assetReportsFilter = useSelector(selectAssetReportsFilter());
  const generalReportsFilter = useSelector(selectGeneralReportsFilter());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  const unsavedFormData = useSelector(selectUnsavedFormData());
  const currentGeneralReport = useSelector(selectSingleGeneralReport());

  const autoSaveEnabled = useSelector(selectStartAutoSaveTimerData());

  const activeTemplate = useSelector(selectActiveTemplate());
  const creationForm = useSelector(selectCreationFormData());

  return {
    creationForm,
    locationReportsFilter,
    assetReportsFilter,
    generalReportsFilter,
    unsavedFormData,
    currentGeneralReport,
    autoSaveEnabled,
    filterCriteria,
    activeTemplate
  };
}
