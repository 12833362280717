import React from 'react';
import styles from './StyledLink.module.scss';

import { Link as RouterLink } from 'react-router-dom';

import clsx from 'clsx';

export const StyledLink = ({ to, children, isRedirectAvailable = true, bold = false }) => {
  return (
    <RouterLink
      to={isRedirectAvailable ? to : '#'}
      className={clsx(styles.link, {
        [styles.bold]: bold,
        [styles.underline]: isRedirectAvailable
      })}
      onClick={(e) => {
        if (!isRedirectAvailable) {
          e.preventDefault();
        }
      }}>
      {children}
    </RouterLink>
  );
};
