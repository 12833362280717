const TITLES = {
  ASSET_DASHBOARDS: {
    MAIN: 'Assets Dashboards',
    GENERAL: 'General',
    VERIFICATION: 'Verification & Inspection'
  },
  ASSET: {
    MAIN: 'Asset Summary'
  },
  LOOSE_ITEMS: {
    MAIN: 'Loose Items',
    DASHBOARD: 'Dashboard',
    LIST: 'Loose Items List',
    BOM_CONFIGURATION: 'BOM configuration',
    ASSEMBLED_BOM: 'Assembled BOM'
  },
  BATCH_UPDATES: {
    MAIN: 'Batch Updates',
    ASSET: 'Asset',
    LOOSE_ITEM: 'Loose items'
  },
  REQUEST_ORDERS: {
    MAIN: 'Request Orders'
  },
  ASSET_TICKETS: {
    MAIN: 'Asset Tickets',
    SUMMARY: 'Asset Ticket Summary',
    ADD: 'Add Asset Ticket',
    TYPES: 'Ticket Types',
    ROOT_CAUSES: 'Ticket Root Causes',
    DASHBOARD: 'Dashboard'
  },
  REPORTS: {
    MAIN: 'Reports',
    LOCATION: 'Location report',
    ASSET: 'Asset report',
    GENERAL: 'General report',
    OVERVIEW: 'Overview report',
    COMPARE: 'Compare reports data',
    TEMPLATE: 'Report template'
  },
  TEAM_SCHEDULE: {
    MAIN: 'Team Schedule',
    PERSONAL_PROFILE: 'Personal Profile',
    TEAM_LIST: 'Team List',
    PROJECT_SCHEDULE: 'Project Schedule',
    MEMBER_DASHBOARD: 'Team Member Dashboard',
    MEMBER_HISTORY: 'Team Member History',
    MEMBER_LICENSES: 'Training and Certifications'
  },
  PICKLIST_AND_LEAVELIST: {
    MAIN: 'Picklist and Leavelist',
    PICKLIST_SUMMARY: 'Picklist',
    LEAVELIST_SUMMARY: 'Leavelist',
    BILL_OF_LADING_SUMMARY: 'Bill of Lading Summary',
    BILL_OF_LADING_LOGS: 'Bill of Lading Logs'
  },
  LOCATION_MANAGEMENT: {
    MAIN: 'Location Management',
    MAP: 'Map of Active Locations',
    SUMMARY: 'Location Summary',
    PROJECT_SUMMARY: 'Projects'
  },
  PREFIX_LIST: {
    MAIN: 'Prefix List'
  },
  USER_MANAGEMENT: {
    MAIN: 'User Management',
    LIST: 'User List',
    LOGS: 'User Logs',
    NOTIFICATION_CENTER: 'Notification Settings Center'
  }
};

const checkAccess = ({ isMobile, userConfig }) => {
  const isDesktop = !isMobile;
  const {
    isTeamMemberUser,
    isUserAssignedToTheDepartments,
    isTeamMemberAttachedToTheProjects,
    isAdminUser,
    isFieldTechUser,
    isOperationsManagerUser,
    isPersonnelManagerUser
  } = userConfig;

  const accessConfig = {
    [TITLES.ASSET_DASHBOARDS.VERIFICATION]: isDesktop,
    [TITLES.ASSET_TICKETS.TYPES]: isAdminUser || isOperationsManagerUser,
    [TITLES.ASSET_TICKETS.ROOT_CAUSES]: isAdminUser || isOperationsManagerUser,
    [TITLES.ASSET_TICKETS.DASHBOARD]: isDesktop,
    [TITLES.REPORTS.OVERVIEW]: isAdminUser || isOperationsManagerUser || isFieldTechUser,
    [TITLES.REPORTS.TEMPLATE]: isAdminUser && isDesktop,
    [TITLES.TEAM_SCHEDULE.PERSONAL_PROFILE]: isTeamMemberUser,
    [TITLES.TEAM_SCHEDULE.TEAM_LIST]:
      isAdminUser ||
      isPersonnelManagerUser ||
      (!isTeamMemberUser && isUserAssignedToTheDepartments),
    [TITLES.TEAM_SCHEDULE.PROJECT_SCHEDULE]:
      (isAdminUser ||
        isPersonnelManagerUser ||
        (isTeamMemberUser ? isTeamMemberAttachedToTheProjects : isUserAssignedToTheDepartments)) &&
      isDesktop,
    [TITLES.TEAM_SCHEDULE.MEMBER_DASHBOARD]:
      (isAdminUser ||
        isPersonnelManagerUser ||
        (!isTeamMemberUser && isUserAssignedToTheDepartments)) &&
      isDesktop,
    [TITLES.TEAM_SCHEDULE.MEMBER_HISTORY]:
      isAdminUser ||
      isPersonnelManagerUser ||
      (!isTeamMemberUser && isUserAssignedToTheDepartments),
    [TITLES.TEAM_SCHEDULE.MEMBER_LICENSES]: isAdminUser,
    [TITLES.PICKLIST_AND_LEAVELIST.BILL_OF_LADING_LOGS]: isDesktop,
    [TITLES.USER_MANAGEMENT.LIST]: isAdminUser,
    [TITLES.USER_MANAGEMENT.LOGS]: isAdminUser && isDesktop,
    [TITLES.USER_MANAGEMENT.NOTIFICATION_CENTER]: isAdminUser && isDesktop
  };

  return accessConfig;
};

const checkSidebarCollapseAccess = (params) => {
  const conditions = checkAccess(params);

  const sidebarConfig = {
    [TITLES.ASSET_DASHBOARDS.MAIN]: {
      [TITLES.ASSET_DASHBOARDS.VERIFICATION]: conditions[TITLES.ASSET_DASHBOARDS.VERIFICATION]
    },
    [TITLES.ASSET_TICKETS.MAIN]: {
      [TITLES.ASSET_TICKETS.TYPES]: conditions[TITLES.ASSET_TICKETS.TYPES],
      [TITLES.ASSET_TICKETS.ROOT_CAUSES]: conditions[TITLES.ASSET_TICKETS.ROOT_CAUSES],
      [TITLES.ASSET_TICKETS.DASHBOARD]: conditions[TITLES.ASSET_TICKETS.DASHBOARD]
    },
    [TITLES.REPORTS.MAIN]: {
      [TITLES.REPORTS.OVERVIEW]: conditions[TITLES.REPORTS.OVERVIEW],
      [TITLES.REPORTS.TEMPLATE]: conditions[TITLES.REPORTS.TEMPLATE]
    },
    [TITLES.TEAM_SCHEDULE.MAIN]: {
      [TITLES.TEAM_SCHEDULE.PERSONAL_PROFILE]: conditions[TITLES.TEAM_SCHEDULE.PERSONAL_PROFILE],
      [TITLES.TEAM_SCHEDULE.TEAM_LIST]: conditions[TITLES.TEAM_SCHEDULE.TEAM_LIST],
      [TITLES.TEAM_SCHEDULE.PROJECT_SCHEDULE]: conditions[TITLES.TEAM_SCHEDULE.PROJECT_SCHEDULE],
      [TITLES.TEAM_SCHEDULE.MEMBER_DASHBOARD]: conditions[TITLES.TEAM_SCHEDULE.MEMBER_DASHBOARD],
      [TITLES.TEAM_SCHEDULE.MEMBER_HISTORY]: conditions[TITLES.TEAM_SCHEDULE.MEMBER_HISTORY],
      [TITLES.TEAM_SCHEDULE.MEMBER_LICENSES]: conditions[TITLES.TEAM_SCHEDULE.MEMBER_LICENSES]
    },
    [TITLES.PICKLIST_AND_LEAVELIST.MAIN]: {
      [TITLES.PICKLIST_AND_LEAVELIST.BILL_OF_LADING_LOGS]:
        conditions[TITLES.PICKLIST_AND_LEAVELIST.BILL_OF_LADING_LOGS]
    },
    [TITLES.USER_MANAGEMENT.MAIN]: {
      [TITLES.USER_MANAGEMENT.LIST]: conditions[TITLES.USER_MANAGEMENT.LIST],
      [TITLES.USER_MANAGEMENT.LOGS]: conditions[TITLES.USER_MANAGEMENT.LOGS],
      [TITLES.USER_MANAGEMENT.NOTIFICATION_CENTER]:
        conditions[TITLES.USER_MANAGEMENT.NOTIFICATION_CENTER]
    }
  };

  return sidebarConfig;
};

export { checkAccess, checkSidebarCollapseAccess, TITLES as SIDEBAR_TITLES };
