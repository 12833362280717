import React from 'react';
import styles from './commonComponents.module.scss';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import SectionTitle from './SectionTitle';
import SectionSubtitle from './SectionSubtitle';

export default function FilesDetails() {
  return (
    <div className={styles.files}>
      <SectionTitle>Site files</SectionTitle>
      <SectionSubtitle>
        You may upload up to twenty files (either photos or pdf files) of the site to accompany this
        report. (Each file may not be larger than 10mb.)
      </SectionSubtitle>
      <section className={styles.files_field}>
        <label>Upload File</label>
        <FormFilesUpload name="resources" />
      </section>
    </div>
  );
}
