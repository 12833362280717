import React, { useState, useEffect } from 'react';
import styles from './InputSearchWithMultiselect.module.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Checkbox, Chip, IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import clsx from 'clsx';

export default function InputSearchWithMultiselect({
  name,
  defaultValue,
  options,
  onFilterSelect,
  multiselect,
  isInvalid,
  index = 0,
  tableView,
  specialView,
  disabled,
  refreshKey,
  optionType,
  shouldHideTags,
  clearable,
  CustomSingleOptionComponent
}) {
  const [inputValueState, setInputValueState] = useState('');

  useEffect(() => {
    if (!defaultValue && !multiselect) {
      setInputValueState('');
    }
  }, [defaultValue]);

  const getOptionLabel = (option) => {
    switch (optionType) {
      case 'assetPrefixType':
        return option?.description || '';
      case 'prefixType':
        return option?.prefix || '';
      case 'teamMember':
        return option?.lastName && option?.firstName
          ? option.lastName + ', ' + option.firstName
          : option?.lastName || option?.firstName || '';
      case 'locationWithSublocation':
        return option?.sublocation?.id
          ? option?.location?.siteCode + ' (' + option.sublocation.name + ')'
          : option?.location?.siteCode || '';
      case 'category':
        return option?.categoryName || '';
      default:
        return option?.firstName && option?.lastName
          ? option.firstName + ' ' + option.lastName
          : option?.jobNumber && option?.name
          ? option.jobNumber + ' ' + option.name
          : '' || option?.siteCode || option?.prefix || option?.displayName || option?.name || '';
    }
  };

  useEffect(() => {
    if (Number(defaultValue) === defaultValue && options.length) {
      const option = options?.find(({ id }) => id === defaultValue);
      setInputValueState(getOptionLabel(option));
    }
  }, [defaultValue, options]);

  const inputChangeHandle = (e, value) => {
    let interValue = e?.target?.value || value || '';
    interValue = interValue.replace(allKeyboardLatSymbols, '');
    setInputValueState(interValue);
  };

  const getSortedArray = (array) => array.sort((a, b) => a.id > b.id);

  const onChangeHandle = (e, value, selectionType, processedOption) => {
    if (multiselect) {
      const fieldState = options.filter(({ id }) => defaultValue.includes(id));
      if (selectionType === 'remove-option') {
        const data = getSortedArray(
          fieldState.filter(({ id }) => id !== processedOption.option.id)
        );
        onFilterSelect(name, data, index);
        return;
      }
      if (selectionType === 'clear') {
        onFilterSelect(name, [], index);
        return;
      }
      const data = getSortedArray(fieldState.concat([processedOption.option]));
      onFilterSelect(name, data, index);
    } else {
      onFilterSelect(name, value, index);
    }
  };

  const filterOptions = (options, state) => {
    const option = options?.find(({ id }) => id === defaultValue);
    const filteredOptions = options?.filter((option) => {
      const optionValue = getOptionLabel(option);
      return optionValue.toLowerCase().indexOf(state.inputValue.toLowerCase()) >= 0;
    });
    return getOptionLabel(option) === state.inputValue ? options : filteredOptions;
  };

  const onLostFocus = () => {
    if (!defaultValue) return;
    const name = getOptionLabel(options?.find(({ id }) => id === +defaultValue));
    if (inputValueState !== name && !multiselect) {
      setInputValueState(name);
    }
  };

  const getDefaultValue = () =>
    multiselect
      ? options?.filter(({ id }) => defaultValue.includes(id)) || []
      : defaultValue || null;

  const handleChipDelete = (chipId) => {
    const filtered = defaultValue.filter((id) => id !== chipId);
    const fieldOptions = options.filter(({ id }) => filtered.includes(id));
    onFilterSelect(name, getSortedArray(fieldOptions), index);
  };

  const handleClearClick = () => {
    onFilterSelect(name, null, index);
  };

  return (
    <div
      className={clsx(
        styles.selectInput,
        isInvalid && styles.invalid,
        tableView && styles.tableView,
        specialView && styles.specialView,
        disabled && styles.disabled
      )}
      key={refreshKey || 1}
      onBlur={onLostFocus}>
      <Autocomplete
        classes={{
          inputRoot: clsx(styles.inputRoot, clearable && styles.clearable_input),
          noOptions: styles.noOptions,
          popper: styles.popper,
          popupIndicator: styles.popupIndicator,
          tag: styles.tag
        }}
        multiple={multiselect}
        id={name}
        limitTags={1}
        noOptionsText={inputValueState.length ? 'No matches' : 'No available items'}
        options={options}
        inputValue={inputValueState}
        onInputChange={inputChangeHandle}
        onChange={onChangeHandle}
        disabled={disabled}
        disableClearable={shouldHideTags ? true : !multiselect}
        disableCloseOnSelect={multiselect}
        popupIcon={<ExpandMore />}
        filterOptions={filterOptions}
        getOptionLabel={(option) => getOptionLabel(option)}
        getOptionSelected={(option) => (multiselect ? defaultValue.includes(option.id) : null)}
        renderOption={(option, { selected }) =>
          multiselect ? (
            <div className={styles.checkbox_option}>
              <Checkbox
                classes={{
                  root: styles.checkbox,
                  checked: styles.checked
                }}
                checked={selected}
              />
              <div className={styles.checkbox_option__label}>{getOptionLabel(option)}</div>
            </div>
          ) : CustomSingleOptionComponent ? (
            CustomSingleOptionComponent(option, getOptionLabel(option))
          ) : (
            <div className={styles.single_option}>{getOptionLabel(option)}</div>
          )
        }
        defaultValue={getDefaultValue()}
        value={!multiselect ? defaultValue || null : defaultValue || []}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        renderTags={(value, getTagProps) =>
          !shouldHideTags &&
          options
            .filter(({ id }) => defaultValue.includes(id))
            .map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                deleteIcon={<CloseIcon />}
                variant="outlined"
                key={index}
                label={getOptionLabel(option)}
                onDelete={() => handleChipDelete(option.id)}
              />
            ))
        }
      />
      {clearable && !!defaultValue && (
        <IconButton className={styles.clear} onClick={handleClearClick}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
}
