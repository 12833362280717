import React, { useState } from 'react';
import { TextField, Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './AddingNonExistingItemsField.module.scss';
import clsx from 'clsx';
import { reformatInputValue } from 'helpers/AppHelpers';

export default function AddingNonExistingItemsField({
  name,
  rule,
  max,
  hintText,
  values,
  chipName,
  onChange,
  isInvalid
}) {
  const [inputValue, setInputValue] = useState('');

  const handleInput = (e) => {
    let value = e.target.value;
    value = reformatInputValue(value, max, rule);
    setInputValue(value);

    if (e.key === 'Enter' && value) {
      const newValues = inputValue
        .split(',')
        .filter(Boolean)
        .map((str) => ({ name: str.trim() }));
      let result = [...values, ...newValues];
      result = result.filter(
        (value, index, array) => array.findIndex((item) => item.name === value.name) === index
      );

      onChange(name, result);
      setInputValue('');
    }
  };

  const handleChipDelete = (chip) => {
    if (chip?.id) {
      const result = values.filter((value) => value.id !== chip.id);
      onChange(name, result);
    } else {
      const result = values.filter((value) => value.name !== chip.name);
      onChange(name, result);
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.field}>
        <TextField
          name={name}
          className={clsx(styles.input, isInvalid && styles.error)}
          variant="outlined"
          value={inputValue || ''}
          onChange={handleInput}
          onKeyDown={handleInput}
        />
        {hintText && <span className={styles.clue}>{hintText || ''}</span>}
      </div>
      <section className={styles.chips}>
        {!!values?.length &&
          values.map((item, index) => (
            <Chip
              key={index}
              label={item[chipName] || ''}
              deleteIcon={<CloseIcon />}
              className={styles.chip}
              onDelete={() => handleChipDelete(item)}
            />
          ))}
      </section>
    </section>
  );
}
