import React from 'react';
import styles from './Footer.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

import { useFormContext } from 'react-hook-form';
import { useManagePicklistItemSelector } from 'hooks/ManagePicklistItem';

export default function Footer({ onCancel, onCreate }) {
  const { picklistData } = useManagePicklistItemSelector();

  const { formState, watch } = useFormContext();
  const { errors } = formState;

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  const itemsWatcher = watch('items');

  return (
    <div className={styles.footer}>
      <MainButton text="cancel" type="secondary" action={onCancel} />
      <MainButton
        text={picklistData?.locationLooseItemDetails?.id ? 'Apply' : 'Add'}
        type="primary"
        action={onCreate}
        isDisabled={!itemsWatcher?.length || isFormInvalid()}
      />
    </div>
  );
}
