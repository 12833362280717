import React, { useState } from 'react';
import styles from './TicketComponents.module.scss';

import { useTicketSelector } from 'hooks/Ticket';

import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { PhotoGallery } from 'components/PhotoGallery';

import 'assets/styles/gallery.scss';

import { DOWNLOAD_FILE } from 'constants/dialogPopupsData';
import { fileDownload } from 'helpers/AppHelpers';

export function Files() {
  const { currentTicket } = useTicketSelector();
  const { resources } = currentTicket;

  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});

  const [modalData, setModalData] = useState({});

  const videos = resources?.filter((resource) => resource['resourceType']?.id === 3) || [];
  const files = resources?.filter((resource) => resource['resourceType']?.id === 2) || [];

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsPlayerOpen(true);
  };

  const onFileDownload = (file) => setModalData({ ...DOWNLOAD_FILE, isOpened: true, file });

  const agreeModal = () => fileDownload(modalData.file).then(() => closeModal());
  const closeModal = () => setModalData({ isOpened: false });

  return (
    <div className={styles.files}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />

      <PhotoGallery resources={resources} />

      {isPlayerOpen && (
        <VideoPlayer open={isPlayerOpen} setOpen={setIsPlayerOpen} video={selectedVideo} />
      )}

      <div className={styles.files__row}>
        <label>Video:</label>
        {!!videos.length && (
          <div className={styles.videos}>
            {videos.map((video, index) => (
              <div
                key={index}
                className={styles.videos__item}
                onClick={() => handleVideoClick(video)}>
                <video src={video.link} />
                <div className={styles.videos__item_icon}>
                  <PlayCircleOutlineIcon />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.files__row}>
        <label>File:</label>
        {!!files.length && (
          <div className={styles.data}>
            {files.map((file, index) => (
              <div key={index} className={styles.data__item} onClick={() => onFileDownload(file)}>
                {file.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
