import React, { useEffect, useState } from 'react';

import SelectLooseItemsPopup from 'components/SelectLooseItemsPopup';
import { AddButton } from 'components/Buttons';

import {
  useManagePicklistItemActions,
  useManagePicklistItemSelector
} from 'hooks/ManagePicklistItem';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { DASHBOARD_PATH } from 'constants/routeConstants';

import { pluck } from 'helpers/AppHelpers';

import Wrapper from './components/Wrapper';
import LooseItemsTable from './components/LooseItemsTable';

export default function ManagePicklistItem() {
  const history = useHistory();

  const [openPopup, setOpenPopup] = useState(false);

  const { picklistData, isEditMode } = useManagePicklistItemSelector();
  const { getLocationLooseItemsAction, clearStateAction } = useManagePicklistItemActions();

  const { activePicklist, locationLooseItemDetails } = picklistData;
  const picklistId = activePicklist.id;
  const { id, moveQuantity, moveByAheadAccount, picklistAssetId } = locationLooseItemDetails;

  const methods = useForm({
    defaultValues: {
      items: [],
      picklist: {},
      warnings: []
    },
    mode: 'onChange'
  });
  const { setValue, getValues } = methods;

  useEffect(() => {
    if (!activePicklist?.id) {
      history.push(DASHBOARD_PATH);
    }
  }, []);

  useEffect(() => {
    if (!isEditMode) return;

    getLocationLooseItemsAction({
      filters: { locationLooseItemIds: [id], picklistId: picklistId, picklistAssetId }
    }).then((res) => {
      if (Array.isArray(res) && !!res?.length && res.length === 1) {
        const item = res[0];
        const modifiedItem = {
          ...item,
          moveQuantity: item?.moveQuantity || moveQuantity || 0,
          moveByAheadAccount: item?.moveByAheadAccount || moveByAheadAccount || 0
        };
        setValue('items', [modifiedItem]);
      }
    });
  }, []);

  useEffect(() => {
    if (picklistData?.activePicklist?.id) {
      setValue('picklist', picklistData.activePicklist);
    }
  }, [picklistData]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const addLooseItems = async (items) => {
    const looseItemIds = pluck(items, 'id');

    const locationData = await getLocationLooseItemsAction({
      filters: { looseItemIds, picklistId }
    });

    if (!Array.isArray(locationData) || locationData.length === 0) return;

    const currentSelectedItems = getValues('items');

    const getAdditionalTableRows = (id) => {
      const dropdownLocationsForItem = locationData.filter(({ looseItem }) => looseItem.id === id);

      const currentSelectedItemIds = pluck(
        currentSelectedItems.filter(({ looseItem }) => looseItem.id === id),
        'id'
      );

      const filteredDropdownLocations = dropdownLocationsForItem.filter(
        ({ id }) => !currentSelectedItemIds.includes(id)
      );

      return { ...filteredDropdownLocations[0], locationLooseItems: dropdownLocationsForItem };
    };

    const additionalTableRows = looseItemIds.map(getAdditionalTableRows);

    const updatedSelectedItems = currentSelectedItems.concat(additionalTableRows);

    setValue('items', updatedSelectedItems, { shouldDirty: true });
  };

  const showPopup = () => setOpenPopup(true);

  return (
    <FormProvider {...methods}>
      {openPopup && (
        <SelectLooseItemsPopup
          open={openPopup}
          setOpen={setOpenPopup}
          onAdd={addLooseItems}
          queryOptions={{
            picklistId,
            excludeLocationLooseItemIds: pluck(getValues('items'), 'id')
          }}
        />
      )}
      <Wrapper>
        <LooseItemsTable />
        {!isEditMode && <AddButton onClick={showPopup} />}
      </Wrapper>
    </FormProvider>
  );
}
