import React from 'react';
import styles from './LooseItemsTable.module.scss';

import { NoDataTableRow, TableHeadComponent, TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import Comment from 'components/Comment';
import { FormSearchInput } from 'components/FormComponents';
import ErrorBlock from 'components/ErrorBlock';

import { IconButton, Table, TableBody } from '@material-ui/core';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useManagePicklistItemSelector } from 'hooks/ManagePicklistItem';

import RemoveIcon from 'assets/images/deleteIcon.svg';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { pluck } from 'helpers/AppHelpers';

import clsx from 'clsx';

import {
  checkMoveQuantity,
  checkByAheadAccount,
  getQuantityCellValue,
  tableHeadConfig,
  getLocationCellValue
} from '../../helpers';

export default function LooseItemsTable() {
  const { control, getValues, setError, setValue, clearErrors, formState, watch } =
    useFormContext();
  const { errors } = formState;

  const { fields, update, remove } = useFieldArray({
    control,
    name: 'items'
  });

  const { isEditMode } = useManagePicklistItemSelector();

  const resetRowErrors = (i) => {
    const rowPath = `items[${i}]`;
    clearErrors([rowPath, `${rowPath}.moveQuantity`, `${rowPath}.moveByAheadAccount`]);
  };

  const resetRowWarnings = (i) => {
    const warningName = `items[${i}]`;
    const warnings = getValues('warnings');
    setValue(
      'warnings',
      warnings.filter((name) => name !== warningName)
    );
  };

  const removeRow = (i) => {
    resetRowErrors(i);
    resetRowWarnings(i);
    remove(i);
  };

  const handleLocationSelect = (_, value, index) => {
    const locationLooseItems = getValues(`items[${index}].locationLooseItems`);
    resetRowErrors(index);
    update(index, { ...value, locationLooseItems });
  };

  const handleWarning = (index) => {
    const warnings = getValues('warnings');
    const itemName = `items[${index}]`;

    if (warnings.includes(itemName)) {
      setValue(
        'warnings',
        warnings.filter((name) => name !== itemName)
      );
    }
  };

  const handleValidation = (index, field, value, checkFunction) => {
    const handledRow = getValues(`items[${index}]`);
    const newRow = { ...handledRow, [field]: +value };

    const errorMessage = checkFunction(newRow);
    const errorPath = `items[${index}].${field}`;

    if (errorMessage) {
      setError(errorPath, getErrorsProperties(errorMessage));
    } else {
      if (errors?.items?.[index]?.[field]?.message) {
        clearErrors(errorPath);
      }
    }
  };

  const validateQuantity = (_, value, index) => {
    handleWarning(index);
    handleValidation(index, 'moveQuantity', value, checkMoveQuantity);
    handleValidation(
      index,
      'moveByAheadAccount',
      getValues(`items[${index}].moveByAheadAccount`),
      checkByAheadAccount
    );
  };

  const validateByAheadAccount = (_, value, index) => {
    handleValidation(index, 'moveByAheadAccount', value, checkByAheadAccount);
  };

  const getDropdownLocations = (index) => {
    const row = getValues(`items[${index}]`);
    const rowLocations = getValues(`items[${index}].locationLooseItems`);
    const currentSelectedItemIds = pluck(getValues('items'), 'id');

    const updatedDropdownLocations = rowLocations.filter(
      ({ id }) => !currentSelectedItemIds.includes(id)
    );

    updatedDropdownLocations.unshift(row);

    return updatedDropdownLocations;
  };

  const warningsWatcher = watch('warnings');

  return (
    <div className={styles.tableWrapper}>
      <TableWrapper>
        <Table>
          <TableHeadComponent config={tableHeadConfig} />
          <TableBody>
            {fields?.length ? (
              fields.map((row, index) => (
                <StyledTableRow key={row.id} className={styles.loose_item_row}>
                  <StyledTableCell className={styles.nameCell}>
                    {row?.looseItem?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.locationCell}>
                    {isEditMode ? (
                      getLocationCellValue(row)
                    ) : (
                      <FormSearchInput
                        name={`items[${index}]`}
                        options={getDropdownLocations(index)}
                        onSelect={handleLocationSelect}
                        classes={styles.select_input_style}
                        disableError
                        optionType="locationWithSublocation"
                        index={index}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell className={styles.qtyCell}>
                    {getQuantityCellValue(row)}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      styles.addQtyCell,
                      errors?.items?.[index]?.moveQuantity?.message && styles.invalid,
                      warningsWatcher.includes(`items[${index}]`) && styles.warning
                    )}>
                    <FormInputText
                      name={`items[${index}].moveQuantity`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        index: index,
                        extraAction: validateQuantity,
                        disableError: true
                      }}
                      classes={styles.quantity_input_style}
                    />
                    <ErrorBlock errorText={errors?.items?.[index]?.moveQuantity?.message} />
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      styles.baaCell,
                      errors?.items?.[index]?.moveByAheadAccount?.message && styles.invalid
                    )}>
                    <FormInputText
                      name={`items[${index}].moveByAheadAccount`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        index: index,
                        extraAction: validateByAheadAccount,
                        disableError: true
                      }}
                      classes={styles.quantity_input_style}
                    />
                    <ErrorBlock errorText={errors?.items?.[index]?.moveByAheadAccount?.message} />
                  </StyledTableCell>
                  <StyledTableCell className={styles.removeCell}>
                    {!isEditMode && (
                      <IconButton onClick={() => removeRow(index)} className={styles.removeButton}>
                        <img src={RemoveIcon} alt="remove" />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <NoDataTableRow label="No data in the table." />
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      <Comment label="[1]Buy Ahead Account" />
    </div>
  );
}
