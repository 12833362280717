import React, { useState } from 'react';
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import styles from './PageTable.module.scss';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { LOCATION_DETAILED_PATH, LOOSE_ITEM_DETAILED_PATH } from 'constants/routeConstants';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import EmptyTableView from 'components/SummaryComponents/EmptyTableView/EmptyTableView';
import QuantityCell from './QuantityCell';
import ByAheadAccountCell from './ByAheadAccountCell';
import clsx from 'clsx';
import SortableBlock from 'components/SortableBlock/SortableBlock';
import { getSum, pluck } from 'helpers/AppHelpers';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCheckedItemsData,
  selectSelectedColumnsData,
  selectTotalBaaData,
  selectTotalQuantityData
} from '../../../../../../hooks/LooseItems/selectors';
import {
  setCheckedItems,
  setTotalBaa,
  setTotalQuantity
} from 'actions/looseItemsAndPartsListActions';

function PageTable({
  isMobile,
  data,
  tableRef,
  responseReceived,
  emptyFilters,
  sortRules,
  updateSortParam,
  updateRow
}) {
  const dispatch = useDispatch();

  const [editableCell, setEditableCell] = useState('');

  const totalQuantity = useSelector(selectTotalQuantityData());
  const totalBaa = useSelector(selectTotalBaaData());
  const checkedItems = useSelector(selectCheckedItemsData());

  const customColumns = useSelector(selectSelectedColumnsData());

  const shouldDisplayItem = (itemId, index) =>
    itemId ? !(index !== 0 && data[index]?.looseItem?.id === data[index - 1]?.looseItem?.id) : true;
  const getItemSpan = (rowId, itemId) =>
    data.filter(({ id }) => id === rowId).filter(({ looseItem }) => looseItem.id === itemId).length;

  const makeCellEditable = (value) => setEditableCell(value);
  const handleQuantityUpdate = (count, row) => {
    const newRowValue = { ...row, quantity: +count };
    updateRow(newRowValue);
    recalculate(newRowValue);
  };
  const handleBaaUpdate = (count, row) => {
    const newRowValue = { ...row, byAheadAccount: +count };
    updateRow(newRowValue);
    recalculate(newRowValue);
  };

  const getCheckedRowId = (row) => row['locationLooseItemId'];

  const recalculate = (row) => {
    const needIndex = checkedItems.findIndex(({ id }) => id === getCheckedRowId(row));

    if (needIndex === -1) return;

    const newCheckedArray = checkedItems.filter(({ id }) => id !== getCheckedRowId(row));
    calculateSum(newCheckedArray);
    dispatch(setCheckedItems(newCheckedArray));
  };

  const calculateSum = (arr) => {
    const newQuantitySum = getSum(pluck(arr, 'quantity'));
    const newBaaSum = getSum(pluck(arr, 'baa'));
    dispatch(setTotalQuantity(arr?.length ? newQuantitySum : null));
    dispatch(setTotalBaa(arr?.length ? newBaaSum : null));
  };

  const handleCheckboxClick = (e, row) => {
    let newCheckedArray = [...checkedItems];
    if (e.target.checked) {
      newCheckedArray.push({
        id: getCheckedRowId(row),
        quantity: row?.quantity || 0,
        baa: row?.byAheadAccount || 0
      });
    } else {
      newCheckedArray = newCheckedArray.filter(({ id }) => id !== getCheckedRowId(row));
    }

    calculateSum(newCheckedArray);
    dispatch(setCheckedItems(newCheckedArray));
  };

  const doesRowHaveCounts = (item) => item?.quantity || item?.byAheadAccount;
  const isHeadCheckboxSelected = () => {
    const checkedIds = checkedItems.map((el) => el.id);
    const availableIds = data.filter(doesRowHaveCounts).map((el) => getCheckedRowId(el));
    const uniqueIds = availableIds.filter((el) => !checkedIds.includes(el));
    return !uniqueIds.length && availableIds.length;
  };
  const handleHeadCheckboxClick = (event) => {
    let newCheckedArray;
    if (event.target.checked) {
      const checkedIds = checkedItems.map((el) => el.id);
      const allAvailableRows = data.filter(doesRowHaveCounts);
      const notSelectedRows = allAvailableRows
        .filter((el) => !checkedIds.includes(getCheckedRowId(el)))
        .map((el) => {
          return {
            id: getCheckedRowId(el),
            quantity: el?.quantity || 0,
            baa: el?.byAheadAccount || 0
          };
        });
      newCheckedArray = [...checkedItems, ...notSelectedRows];
    } else {
      const allAvailableRowsIds = data.filter(doesRowHaveCounts).map((el) => getCheckedRowId(el));
      newCheckedArray = checkedItems.filter((el) => !allAvailableRowsIds.includes(el.id));
    }
    calculateSum(newCheckedArray);
    dispatch(setCheckedItems(newCheckedArray));
  };

  const handleClearCounts = () => {
    calculateSum([]);
    dispatch(setCheckedItems([]));
  };

  return (
    <TableContainer
      ref={tableRef}
      className={clsx(styles.tableContainer, !customColumns?.length && styles.stickyHeader)}
      component={Paper}>
      <Table stickyHeader={!isMobile && !customColumns?.length}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <SortableBlock
                label="Loose Items"
                parameter="name"
                sortRules={sortRules}
                onSortApply={updateSortParam}
              />
            </StyledTableCell>
            {!!customColumns?.length && (
              <>
                {customColumns.map(({ id, name }) => (
                  <StyledTableCell key={id}>
                    <SortableBlock
                      label={name || ''}
                      parameter={`customFields.${name}`}
                      sortRules={sortRules}
                      onSortApply={updateSortParam}
                    />
                  </StyledTableCell>
                ))}
              </>
            )}
            <StyledTableCell>Location</StyledTableCell>
            <StyledTableCell>Sublocation</StyledTableCell>

            <StyledTableCell className={styles.quantityHead}>
              <div className={styles.countBlock}>
                <span>QTY</span>
                {totalQuantity !== null && (
                  <div className={styles.counter}>
                    {totalQuantity}
                    <IconButton onClick={handleClearCounts}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </StyledTableCell>
            <StyledTableCell className={styles.baaHead}>
              <div className={styles.countBlock}>
                <span>BAA</span>
                {totalBaa !== null && <div className={styles.counter}>{totalBaa}</div>}
              </div>
            </StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>
              {!!data?.length && (
                <Checkbox
                  classes={{ root: styles.checkbox, checked: styles.checked }}
                  onChange={handleHeadCheckboxClick}
                  value={'selectAll'}
                  checked={isHeadCheckboxSelected()}
                />
              )}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length ? (
            data.map((row, index) => (
              <StyledTableRow key={row.id + ' ' + index}>
                {shouldDisplayItem(row?.looseItem?.id, index) && (
                  <StyledTableCell
                    rowSpan={row?.looseItem?.id && getItemSpan(row.id, row.looseItem.id)}
                    className={clsx(styles.whiteCell, styles.itemCell)}>
                    <LinkComponent
                      path={`${LOOSE_ITEM_DETAILED_PATH}/${row?.looseItem?.id}`}
                      name={row?.looseItem?.name}
                      boldText
                    />
                  </StyledTableCell>
                )}
                {!!customColumns?.length && (
                  <>
                    {shouldDisplayItem(row?.looseItem?.id, index) &&
                      customColumns.map(({ id }) => (
                        <StyledTableCell
                          key={id}
                          rowSpan={row?.looseItem?.id && getItemSpan(row.id, row.looseItem.id)}
                          className={clsx(styles.whiteCell, styles.customCell)}>
                          {row?.customFields?.find((field) => field.id === id)?.value || ''}
                        </StyledTableCell>
                      ))}
                  </>
                )}
                <StyledTableCell className={styles.locationCell}>
                  <LinkComponent
                    path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                    isRedirectAvailable={!row.location?.deleted}
                    name={row.location?.siteCode}
                  />
                </StyledTableCell>
                <StyledTableCell className={styles.sublocationCell}>
                  {row.sublocation?.name || ''}
                </StyledTableCell>

                <QuantityCell
                  name="quantity"
                  value={row.quantity || 0}
                  index={index}
                  isCellEditable={editableCell === `quantity-${index}`}
                  setCellEditable={makeCellEditable}
                  onUpdate={(count) => handleQuantityUpdate(count, row)}
                  rowBaa={row.byAheadAccount || 0}
                  isEditAvailable={row?.status?.displayName === 'Available'}
                />
                <ByAheadAccountCell
                  name="byAheadAccount"
                  value={row.byAheadAccount || 0}
                  index={index}
                  isCellEditable={editableCell === `byAheadAccount-${index}`}
                  setCellEditable={makeCellEditable}
                  onUpdate={(count) => handleBaaUpdate(count, row)}
                  rowQuantity={row.quantity || 0}
                  isEditAvailable={row?.status?.displayName === 'Available'}
                />
                <StyledTableCell className={styles.statusCell}>
                  {row?.status?.displayName || ''}
                  {row?.status?.displayName === 'In Picklist' && ` (${row?.picklist?.name})`}
                </StyledTableCell>
                <StyledTableCell className={styles.checkboxCell}>
                  {!!(row?.quantity || row?.byAheadAccount) && (
                    <Checkbox
                      classes={{ root: styles.checkbox, checked: styles.checked }}
                      onChange={(e) => handleCheckboxClick(e, row)}
                      value={row.id}
                      checked={!!checkedItems?.find(({ id }) => id === getCheckedRowId(row))}
                    />
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <EmptyTableView responseReceived={responseReceived} emptyFilters={emptyFilters} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PageTable;
