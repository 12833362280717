import { getUniqueID } from 'helpers/AppHelpers';

export const generateChips = (options, filters) => {
  const filterHandlers = {
    categoryIds: handleFilterChips('looseItemCategories', 'name', options),
    looseItemIds: handleFilterChips('looseItems', 'name', options),
    locationIds: handleFilterChips('locations', 'siteCode', options),
    locationJobNumberIds: handleFilterChips('locations', 'locationJobNumber', options),
    sublocationIds: handleFilterChips('sublocations', 'name', options),
    statusNames: handleFilterNames('statuses', 'displayName', options),
    showWithBaa: handleBooleanFilter('Show with BAA qty'),
    showWithReorderTrigger: handleBooleanFilter('Show with reorder trigger')
  };

  let newChips = [];

  Object.entries(filterHandlers).forEach(([key, handler]) => {
    const chips = handler(filters[key], key);
    newChips = newChips.concat(chips);
  });

  return newChips.filter(Boolean);
};

const handleFilterChips = (dataKey, labelKey, filterCriteriaData) => (filterIds, key) => {
  const data = filterCriteriaData[dataKey];
  if (!data || !filterIds?.length) return [];

  return filterIds.flatMap((id) => {
    const item = data.find((option) => option.id === id);
    if (!item?.id && item?.id !== 0) return [];
    return {
      itemId: item.id,
      label: item[labelKey],
      chipId: getUniqueID(),
      key
    };
  });
};

const handleFilterNames = (dataKey, labelKey, filterCriteriaData) => (filterNames, key) => {
  const data = filterCriteriaData[dataKey];
  if (!data || !filterNames?.length) return [];

  return filterNames.flatMap((name) => {
    const item = data.find((option) => option.name === name);
    if (!item?.name) return [];
    return { itemName: item.name, label: item[labelKey], chipId: getUniqueID(), key };
  });
};

const handleBooleanFilter = (label) => (isActive, key) => {
  if (isActive) return [{ chipId: getUniqueID(), key, label }];
  return [];
};
