import {
  SET_MANAGE_PICKLIST_ITEM_ACTIVE_PICKLIST_DATA,
  CLEAR_MANAGE_PICKLIST_ITEM_STATE
} from 'constants/reduceConstants';

const initialState = {
  picklistData: {
    locationLooseItemDetails: {},
    activePicklist: {}
  },
  isEditMode: false
};

const managePicklistItemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MANAGE_PICKLIST_ITEM_ACTIVE_PICKLIST_DATA:
      return {
        ...state,
        picklistData: {
          locationLooseItemDetails: payload?.locationLooseItemDetails || {},
          activePicklist: payload?.activePicklist || {}
        },
        isEditMode: !!payload?.locationLooseItemDetails?.id
      };
    case CLEAR_MANAGE_PICKLIST_ITEM_STATE:
      return initialState;
    default:
      return state;
  }
};

export default managePicklistItemReducer;
