import React, { useState } from 'react';
import styles from './MultipleAssetSelectionField.module.scss';

import CheckIcon from '@material-ui/icons/Check';
import NoSsr from '@material-ui/core/NoSsr';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { ClickAwayListener, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  searchAssetByString,
  searchAssetsByDRCodes
} from 'actions/multipleAssetSelectionFieldActions';

import { allKeyboardLatSymbols, reformatInputValue } from 'helpers/AppHelpers';

import Tag from './Tag/Tag';

import clsx from 'clsx';

const displayName = 'drCode';

function MultipleAssetSelectionField({
  name,
  value,
  onSelect,
  index,
  onPaste,
  queryOptions,
  isInvalid,
  styleType,
  getAssetsBySearchingAction,
  getAssetsByPastingAction
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getFormattedInputValue = (value) => {
    const string = value?.toString()?.toLowerCase()?.trim();
    const divided = string?.match(/((?:[a-z]+-*\d{0,2} *)* *(?:[a-z]+-*[0-9-]{3,}){1})/g);
    if (divided?.length) {
      return divided.map((el) => el.trim());
    } else return [string];
  };
  const isAssetAlreadySelected = (assetId) => value.map(({ id }) => id).includes(assetId);
  const getAssetsByArray = (assets) =>
    getAssetsByPastingAction(assets, index, queryOptions).then(handleArrayPaste);
  const isOptionSelected = (option) => value.map(({ id }) => id).includes(option.id);
  const handleArrayPaste = (payload) => {
    if (payload?.value?.length) {
      onPaste(payload, index);
    }
    setInputValue('');
  };

  const onInput = (searchQuery) => {
    const value = searchQuery ? searchQuery.replace(allKeyboardLatSymbols, '') : '';
    if (value === '') return;
    getAssetsBySearchingAction(searchQuery, queryOptions).then((res) =>
      setOptions(res?.items || [])
    );
  };

  const handleInputChange = (value) => {
    if (value === undefined) return;
    const stringValue = value ? reformatInputValue(value, 30, allKeyboardLatSymbols) : '';
    if (stringValue?.length <= 2) {
      setInputValue(stringValue);
    } else {
      const assetsArray = getFormattedInputValue(value);
      if (assetsArray && assetsArray.length === 1) {
        onInput(stringValue);
        setInputValue(stringValue);
      } else {
        // onPaste handler
        getAssetsByArray(assetsArray);
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl
  } = useAutocomplete({
    inputValue: inputValue,
    onInputChange: (e) => handleInputChange(e?.target?.value),
    value: value,
    options: options || [],
    clearOnEscape: true,
    clearOnBlur: false,
    disableCloseOnSelect: true,
    open: open,
    getOptionLabel: (option) => option[displayName] || '',
    filterOptions: (options) => options
  });

  const handleListItemClick = (asset) => {
    if (!asset?.id) return;
    if (isAssetAlreadySelected(asset.id)) {
      const codes = value.filter(({ id }) => id !== asset.id);
      onSelect(codes, index);
    } else {
      const codes = value.concat([asset]);
      onSelect(codes, index);
    }
    setInputValue('');
  };

  const handleClickAway = () => {
    if (open) {
      setInputValue('');
      setOpen(false);
      setOptions([]);
    }
  };
  const handleOpen = () => setOpen(true);
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      getAssetsByArray([inputValue]);
    }
  };

  const handleRemoveChip = (assetId) => {
    const codes = value.filter(({ id }) => id !== assetId);
    onSelect(codes, index);
  };

  const handleClearAll = () => {
    onSelect([], index);
    setInputValue('');
    setOpen(false);
    setOptions([]);
  };

  return (
    <NoSsr>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.wrapper}>
          <div
            {...getRootProps()}
            onClick={handleOpen}
            ref={setAnchorEl}
            className={clsx(
              styles.inputWrapper,
              focused && 'focused',
              isInvalid && styles.error,
              styleType && styles[styleType]
            )}>
            {!!value.length &&
              value.map((option, index) => (
                <Tag
                  key={index}
                  label={option[displayName]}
                  tagId={option.id}
                  {...getTagProps({ index })}
                  onDelete={handleRemoveChip}
                />
              ))}
            <input name={name} onKeyDown={onKeyDown} {...getInputProps()} />
            {!!value.length && (
              <div className={clsx(styles.controls, styleType === 'extended' && styles.stretched)}>
                <IconButton className={styles.controls__clear} onClick={handleClearAll}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
          {groupedOptions.length > 0 ? (
            <ul className={styles.list} {...getListboxProps()} role="list-box">
              {groupedOptions.map((option, index) => (
                <li
                  key={option.id || index}
                  {...getOptionProps({ option, index })}
                  onClick={() => handleListItemClick(option)}
                  className={clsx(isOptionSelected(option) && styles.selected)}>
                  <span>{option[displayName]}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </ClickAwayListener>
    </NoSsr>
  );
}

const mapDispatchToProps = {
  getAssetsBySearchingAction: searchAssetByString,
  getAssetsByPastingAction: searchAssetsByDRCodes
};

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(MultipleAssetSelectionField);
