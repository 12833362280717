import React, { useEffect, useRef, useState } from 'react';
import styles from './BOMConfigurationEdit.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import MainButton from 'components/StyledComponents/MainButton';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectSingleConfigurationData } from '../selectors';
import {
  clearConfigurationState,
  createConfiguration,
  getSingleConfiguration,
  updateConfiguration,
  getConfigurationCreationForm
} from 'actions/looseItemConfigurationActions';
import { getBackendErrors } from 'helpers/AppHelpers';
import {
  BOM_CONFIGURATION_DETAILED_PATH,
  BOM_CONFIGURATIONS_LIST_PATH
} from 'constants/routeConstants';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  CREATE_NEW_TENT_CONFIGURATION,
  EXIT_WITHOUT_SAVING,
  NEW_TENT_CONFIGURATION_TYPE,
  UPDATE_TENT_CONFIGURATION,
  UPDATE_TENT_CONFIGURATION_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { setUnsavedFormData } from 'actions/commonActions';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';
import { FormProvider, useForm } from 'react-hook-form';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import FormFilesUpload from 'components/FormComponents/FormFilesUpload/FormFilesUpload';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import ConfigurationItemsTable from './ConfigurationItemsTable';
import { selectUnsavedFormData } from 'pages/commonSelectors';

function BOMConfigurationEdit({
  setUnsavedFormDataAction,
  unsavedFormData,
  currentConfiguration,
  getConfigurationAction,
  createConfigurationAction,
  getCreationFormAction,
  clearStateAction,
  updateConfigurationAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();

  const breadcrumbs = useRef([{ name: 'Add configuration' }]);
  const routeUrl = useRef(null);

  const methods = useForm({
    defaultValues: {
      description: '',
      resources: [],
      name: '',
      looseItems: [{ quantity: 1 }]
    },
    mode: 'onChange'
  });
  const { formState, getValues, setError, reset } = methods;
  const { isDirty, errors } = formState;

  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  useEffect(() => {
    if (id) {
      getConfigurationAction(id);
    }
  }, [id]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    if (id && currentConfiguration?.id) {
      breadcrumbs.current = [
        {
          path: `${BOM_CONFIGURATION_DETAILED_PATH}/${currentConfiguration.id}`,
          name: currentConfiguration.name
        },
        { name: 'Edit configuration' }
      ];
      reset(currentConfiguration);
    }
  }, [currentConfiguration?.id]);

  const rules = [{ name: 'name', inputType: 'text', errorMessage: 'Name field is required' }];
  const tableRules = [
    { name: 'quantity', inputType: 'quantity-required', errorMessage: 'This field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    rules.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    tableRules.forEach(({ name, inputType, errorMessage }) => {
      getValues('looseItems').forEach((row, index) => {
        if (isParameterInvalid(row[name], inputType)) {
          setError(`looseItems[${index}].${name}`, getErrorsProperties(errorMessage), {
            shouldFocus: true
          });
          isFormValid = false;
        }
      });
    });
    return isFormValid;
  };

  const onCancel = () => {
    if (isDirty) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      history.goBack();
    }
  };

  const onCreate = () => {
    if (!validateForm()) return;
    if (id) {
      setDialogModalData({ ...UPDATE_TENT_CONFIGURATION, isOpened: true });
    } else {
      setDialogModalData({ ...CREATE_NEW_TENT_CONFIGURATION, isOpened: true });
    }
  };

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  const onAgree = () => {
    switch (dialogModalData.type) {
      case NEW_TENT_CONFIGURATION_TYPE: {
        const data = { ...getValues(), looseItems: getValues('looseItems').filter((el) => el.id) };
        closeModal();
        createConfigurationAction(data).then((err) => {
          if (err?.errors) {
            getBackendErrors(err).forEach(
              ({ name, type, message }) =>
                name !== 'looseItems' && setError(name, { type, message })
            );
          }
        });
        break;
      }
      case UPDATE_TENT_CONFIGURATION_TYPE: {
        const data = { ...getValues(), looseItems: getValues('looseItems').filter((el) => el.id) };
        closeModal();
        updateConfigurationAction(data).then((err) => {
          if (err?.errors) {
            getBackendErrors(err).forEach(
              ({ name, type, message }) =>
                name !== 'looseItems' && setError(name, { type, message })
            );
          }
        });
        break;
      }
      case WITHOUT_SAVING_TYPE:
        if (id) {
          history.goBack();
        } else {
          history.push(BOM_CONFIGURATIONS_LIST_PATH);
        }
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(routeUrl.current);
        break;
      default:
        break;
    }
  };

  const closeModal = () => setDialogModalData({ isOpened: false });
  const setSelectedRoute = (path) => (routeUrl.current = path);

  return (
    <section className={styles.pageContainer}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={closeModal} />
      {!isMobile && (
        <BreadcrumbsNav
          itemsArray={breadcrumbs.current}
          setDialogModalData={setDialogModalData}
          formIsChanged={isDirty}
          setRouteUrl={setSelectedRoute}
        />
      )}
      <div className={styles.headerBlock}>
        <h1>{id ? 'Edit configuration' : 'Add configuration'}</h1>
        {isMobile && <BackButton onCancel={onCancel} />}
      </div>
      <h3>General Details</h3>
      <FormProvider {...methods}>
        <div className={styles.formBlock}>
          <div className={styles.nameBlock}>
            <label>Name*</label>
            <FormInputText name="name" options={{ max: 40 }} />
          </div>
          <div className={styles.filesBlock}>
            <label>Upload File</label>
            <FormFilesUpload name="resources" />
          </div>
          <div className={styles.descriptionBlock}>
            <label>Description</label>
            <FormTextArea name="description" options={{ max: 1000 }} />
          </div>
        </div>
        <ConfigurationItemsTable />
      </FormProvider>
      <div className={styles.footerControls}>
        <MainButton text="cancel" type="secondary" action={onCancel} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={onCreate}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  currentConfiguration: selectSingleConfigurationData(),
  unsavedFormData: selectUnsavedFormData()
});

const mapDispatchToProps = {
  setUnsavedFormDataAction: setUnsavedFormData,
  getConfigurationAction: getSingleConfiguration,
  createConfigurationAction: createConfiguration,
  updateConfigurationAction: updateConfiguration,
  getCreationFormAction: getConfigurationCreationForm,
  clearStateAction: clearConfigurationState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BOMConfigurationEdit);
