import React from 'react';
import styles from './AddBomControls.module.scss';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';

import { useFormContext } from 'react-hook-form';
import { useAddBomActions, useAddBomSelector } from 'hooks/AddBomToPicklist';

import { modifyLooseItems } from '../../../helpers';

export default function Controls() {
  const { setValue, clearErrors } = useFormContext();

  const { configurations } = useAddBomSelector();
  const { getConfigurationDetailsAction } = useAddBomActions();

  const selectConfiguration = async (name, value) => {
    clearErrors();
    setValue(name, value);
    const { looseItems } = await getConfigurationDetailsAction({ id: value.id });
    setValue('looseItems', modifyLooseItems(looseItems));
  };

  return (
    <div className={styles.controls}>
      <div className={styles.controls__row}>
        <label>Configuration Name*</label>
        <FormSearchInput
          name="configuration"
          onSelect={selectConfiguration}
          options={configurations}
        />
      </div>
    </div>
  );
}
