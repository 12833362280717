import React from 'react';
import styles from './templates.module.scss';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import { LOCATION_REPORT_VALIDATION_RULES } from '../../../LocationReportList/LocationReportEdit/validationRules';

export default function ReportTextArea({ itemId, name }) {
  const max = LOCATION_REPORT_VALIDATION_RULES.find(({ id }) => id === itemId)?.maxSymbols || 0;
  const rule = LOCATION_REPORT_VALIDATION_RULES.find(({ id }) => id === itemId)?.regexp;

  return (
    <section className={styles.textarea}>
      <FormTextArea name={name} options={{ max, rule }} />
    </section>
  );
}
