import { useDispatch } from 'react-redux';

import { setUnsavedFormData } from 'actions/commonActions';

import {
  setActivePicklist,
  clearState,
  getConfigurations,
  getConfigurationDetails,
  addLooseItemsToPicklist
} from 'actions/addBomToPicklistActions';

export function useAddBomActions() {
  const dispatch = useDispatch();

  const setActivePicklistAction = (data) => dispatch(setActivePicklist(data));

  const getConfigurationsAction = (q) => dispatch(getConfigurations(q));
  const getConfigurationDetailsAction = (q) => dispatch(getConfigurationDetails(q));

  const addItemsToPicklistAction = (data) => dispatch(addLooseItemsToPicklist(data));

  const clearStateAction = () => dispatch(clearState());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  return {
    getConfigurationsAction,
    getConfigurationDetailsAction,
    setActivePicklistAction,
    addItemsToPicklistAction,
    clearStateAction,
    setUnsavedFormDataAction
  };
}
