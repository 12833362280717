import React from 'react';

import DetailsHeader from 'components/DetailsComponents/Header';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import HeaderButton from 'components/DetailsComponents/HeaderButton';

import { PICKLIST_SUMMARY_PATH, PICKLIST_DETAILED_PATH } from 'constants/routeConstants';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useManagePicklistItemSelector } from 'hooks/ManagePicklistItem';
import { useHistory } from 'react-router-dom';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function Header({ onLinkClick }) {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const { picklistData, isEditMode } = useManagePicklistItemSelector();
  const { activePicklist } = picklistData;
  const { id, name } = activePicklist;

  const detailsPath = `${PICKLIST_DETAILED_PATH}/${id}`;

  const titlePrefix = isEditMode ? 'Edit' : 'Add';
  const textPreposition = isEditMode ? 'of' : 'to';
  const text = !isMobile ? textPreposition + ' ' + 'Picklist' : '';
  const title = [titlePrefix, 'Loose items', text].join(' ');

  const crumbs = [
    { name: 'Picklist Summary', path: PICKLIST_SUMMARY_PATH },
    { name: `${name}`, path: detailsPath },
    { name: title }
  ];

  const CrumbsComponent = !isMobile && (
    <BreadcrumbsNav itemsArray={crumbs} onLinkClick={onLinkClick} />
  );

  const goBack = () => history.push(detailsPath);

  return (
    <DetailsHeader title={title} BreadCrumbsComponent={CrumbsComponent}>
      {isMobile && (
        <HeaderButton onClick={goBack}>
          <ChevronLeftIcon />
        </HeaderButton>
      )}
    </DetailsHeader>
  );
}
