import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectActiveLocationsListData, selectNearestLocationsData } from './selectors';
import ActiveLocationsFilter from './ActiveLocationsFilter/ActiveLocationsFilter';
import ActiveLocationsAdvancedFilter from './ActiveLocationsAdvancedFilter/ActiveLocationsAdvancedFilter';
import MapComponent from 'components/MapComponent/MapComponent';
import ActiveLocationsFilterSidebar from './ActiveLocationsAdvancedFilter/ActiveLocationsFilterSidebar';
import {
  clearLocationState,
  getActiveLocationsList,
  getNearestMapLocations,
  setMapAdvancedPrefilter
} from 'actions/locationsActions';
import { Button } from '@material-ui/core';
import RedMarkerIcon from 'assets/images/markers/redmarker.svg';
import BlueMarkerIcon from 'assets/images/markers/bluemarker.svg';
import GreenMarkerIcon from 'assets/images/markers/greenmarker.svg';
import styles from './ActiveLocations.module.scss';
import { useMobileViewport } from 'hooks/useMobileViewport';

function ActiveLocations({
  nearestLocationsData,
  activeLocationsListData,
  getActiveLocationsListAction,
  getNearestMapLocationsAction,
  setMapAdvancedPrefilterAction,
  clearActiveLocationsListAction
}) {
  const isMobile = useMobileViewport();
  const [openFilter, setOpenFilter] = useState(false);
  const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
  const [customMarker, setCustomMarker] = useState({});
  const [showSidebar, setShowSidebar] = useState(!isMobile);
  const [filter, setFilter] = useState({
    filters: { projectIds: [], locationIds: [], locationJobNumberIds: [] }
  });
  const [advancedFilter, setAdvancedFilter] = useState({
    asset: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } },
    looseItem: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } },
    selectedPanelTab: 0
  });

  const isAdvancedFilterApplied = () =>
    (!!Object.keys(advancedFilter?.asset).length ||
      !!Object.keys(advancedFilter?.looseItem).length) &&
    !!nearestLocationsData?.nearestLocations?.length;

  const isFilterApplied = () =>
    !!filter?.filters?.projectIds?.length ||
    !!filter?.filters?.locationIds?.length ||
    !!filter?.filters?.locationJobNumberIds?.length;

  useEffect(() => {
    getActiveLocationsListAction(filter);
  }, []);

  useEffect(() => {
    return () => {
      clearActiveLocationsListAction();
    };
  }, []);

  const getMapData = (inputFilter) => getActiveLocationsListAction(inputFilter || filter);

  const onApply = (inputFilter) => {
    getMapData(inputFilter);
    setOpenAdvancedFilter(false);
    setMapAdvancedPrefilterAction({});
  };

  const onFilterOpen = (state) => {
    setOpenFilter(state);
    setOpenAdvancedFilter(false);
  };

  const onAdvancedFilterOpen = (state) => {
    setOpenAdvancedFilter(state);
    setOpenFilter(false);
  };

  return (
    <div className={styles.mapContainer}>
      <section className={styles.mapContainer__wrapper}>
        <ActiveLocationsFilter
          filter={filter}
          setFilter={setFilter}
          onApply={onApply}
          isMobile={isMobile}
          openFilter={openFilter}
          setOpenFilter={onFilterOpen}
          isAdvancedFilterApplied={isAdvancedFilterApplied}
        />
        <ActiveLocationsAdvancedFilter
          filter={advancedFilter}
          setFilter={setAdvancedFilter}
          setCustomMarker={setCustomMarker}
          isMobile={isMobile}
          openFilter={openAdvancedFilter}
          setOpenFilter={onAdvancedFilterOpen}
          isFilterApplied={isFilterApplied}
          getMapData={getMapData}
        />

        {isMobile && (
          <section className={styles.headerWrapper}>
            <div className={styles.pageHeader}>
              <h1>Map</h1>
            </div>
            {isAdvancedFilterApplied() && !showSidebar && (
              <Button className={styles.showMoreButton} onClick={() => setShowSidebar(true)}>
                View results
              </Button>
            )}
          </section>
        )}

        {!isAdvancedFilterApplied() && (
          <div className={styles.helperBlock}>
            <section className={styles.infoPrompt}>
              <div className={styles.infoPrompt__block}>
                <img src={RedMarkerIcon} alt="marker" />
                <span>Job Site</span>
              </div>
              <div className={styles.infoPrompt__block}>
                <img src={BlueMarkerIcon} alt="marker" />
                <span>Storage Yard</span>
              </div>
              <div className={styles.infoPrompt__block}>
                <img src={GreenMarkerIcon} alt="marker" />
                <span>Destination</span>
              </div>
            </section>
          </div>
        )}

        <MapComponent
          locations={activeLocationsListData?.items?.length ? activeLocationsListData.items : []}
          advancedFilterApplied={isAdvancedFilterApplied()}
          nearestLocationsData={nearestLocationsData}
          customMarker={customMarker}
        />
      </section>
      {isAdvancedFilterApplied() && showSidebar && (
        <ActiveLocationsFilterSidebar
          filter={advancedFilter}
          setFilter={setAdvancedFilter}
          nearestLocationsData={nearestLocationsData}
          getNearestMapLocationsAction={getNearestMapLocationsAction}
          hideSidebar={() => setShowSidebar(false)}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  activeLocationsListData: selectActiveLocationsListData(),
  nearestLocationsData: selectNearestLocationsData()
});

const mapDispatchToProps = {
  getActiveLocationsListAction: getActiveLocationsList,
  getNearestMapLocationsAction: getNearestMapLocations,
  setMapAdvancedPrefilterAction: setMapAdvancedPrefilter,
  clearActiveLocationsListAction: clearLocationState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ActiveLocations);
