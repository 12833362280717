import {
  SET_LOAD_MORE_FLAG,
  SET_REQUEST_ORDER_FILTER_DATA,
  CLEAR_REQUEST_ORDER_STATE,
  SET_REQUEST_ORDER_FILTER_CRITERIA_DATA,
  SET_REQUEST_ORDER_CREATION_FORM_DATA,
  SET_CURRENT_REQUEST_ORDER
} from '../constants/reduceConstants';
import api from '../api';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import { SUCCESS_CREATE_REQUEST_ORDER } from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import { REQUEST_ORDER_DETAILED_PATH } from '../constants/routeConstants';

export const getRequestOrderList = (query) => (dispatch) =>
  api.requestOrder
    .getTableDataApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getFilterCriteria = () => (dispatch) =>
  api.requestOrder
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_REQUEST_ORDER_FILTER_CRITERIA_DATA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getRequestOrder = (id) => (dispatch) => {
  return api.requestOrder
    .getRequestOrderApi(id)
    .then((res) => {
      dispatch({ type: SET_CURRENT_REQUEST_ORDER, payload: res });
    })
    .catch((err) => console.log(err));
};

export const getCreationFormData = (q) => (dispatch) =>
  api.requestOrder
    .getCreationFormDataApi(q)
    .then((res) => dispatch({ type: SET_REQUEST_ORDER_CREATION_FORM_DATA, payload: res }))
    .catch((err) => console.log(err));

export const createRequest = (data) => () => {
  return api.requestOrder.createRequestApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_REQUEST_ORDER);
      browserHistory.push(`${REQUEST_ORDER_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updateEntity = (data) => () => {
  return api.requestOrder.updateEntityApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updateEntities = (data) => () => {
  return api.requestOrder.updateEntitiesApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updateRequestOrderStatus = (data) => () => {
  return api.requestOrder.updateRequestOrderStatusApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deleteEntity = (query) => () => {
  return api.requestOrder.deleteEntityApi(query).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deleteRequestOrder = (id) => () => {
  return api.requestOrder.deleteRequestOrderApi(id).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const setRequestOrderFilter = (data) => (dispatch) => {
  dispatch({ type: SET_REQUEST_ORDER_FILTER_DATA, payload: data });
};

export const clearRequestOrdersState = () => (dispatch) => {
  dispatch({ type: CLEAR_REQUEST_ORDER_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};
