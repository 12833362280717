export const modifyLooseItems = (looseItems) => {
  return looseItems.flatMap((item) => {
    const { requiredQuantity, locations } = item;
    return locations.map((location) => ({ ...location, configRequiredQuantity: requiredQuantity }));
  });
};

export const transformValues = (values, picklist) => {
  const { configuration, looseItems } = values;

  const usedItems = looseItems.filter(({ selectedQuantity }) => selectedQuantity);

  const picklistItems = usedItems.map(({ selectedQuantity, selectedByAheadAccount, ...rest }) => {
    return {
      locationLooseItem: { ...rest },
      moveByAheadAccount: selectedByAheadAccount || 0,
      moveQuantity: selectedQuantity || 0
    };
  });

  return {
    createPicklistLooseItemDtos: picklistItems,
    looseItemConfiguration: configuration,
    picklist
  };
};

export const isQuantityColumnNotEmpty = (looseItems) => {
  return looseItems.some(({ selectedQuantity }) => !!selectedQuantity);
};

export const EMPTY_QUANTITY_COLUMN_ERROR_NAME = 'empty_quantity_column';

export const errorMessages = {
  exceedsMaxValue: 'Exceeds maximum value',
  baaMoreThanQty: '“From BAA” value must not exceed “Add QTY”',
  baaMoreThanAvailableBaa:
    'The “From BAA” value must not exceed “Available QTY(BAA)” value in the brackets',
  atLeastOneValue: 'At least one value is required'
};

export const isQuantityMoreThanAvailableQty = (qtyValue, availableQty) => {
  return qtyValue > availableQty;
};

export const isBaaMoreThanQty = (baaValue, qtyValue) => {
  return baaValue > qtyValue;
};

export const isBaaMoreThanAvailableBaa = (baaValue, availableBaa) => {
  return baaValue > availableBaa;
};
