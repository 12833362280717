import React, { useState } from 'react';
import styles from './FormInputWithCreation.module.scss';

import { TextField } from '@material-ui/core';

import clsx from 'clsx';

import { pluck, removeDuplicates } from 'helpers/AppHelpers';

import { Controller, useFormContext } from 'react-hook-form';

export default function FormInputWithCreation({
  name,
  itemLabel = 'name',
  hintText,
  modifyText,
  transformItem,
  isValidItem,
  errorMessage,
  disableErrorMessage,
  onKeyDownTriggered
}) {
  const { control, setValue } = useFormContext();

  const [inputValue, setInputValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const handleInput = (e) => {
    const value = modifyText(e.target.value);
    setInputValue(value);
    isInvalid && setIsInvalid(false);
  };

  const handleKeyDown = (e, value) => {
    if (e.key !== 'Enter' || !inputValue) return;

    let isValid = true;
    const items = inputValue
      .split(',')
      .filter(Boolean)
      .map((str) => str.trim());

    if (isValidItem) {
      items.forEach((item) => {
        if (!isValidItem(item)) {
          isValid = false;
          setIsInvalid(true);
        }
      });
    }

    if (!isValid) return;

    const mappedValues = pluck(value, itemLabel);
    const state = removeDuplicates([...mappedValues, ...items]);

    setValue(name, state.map(transformItem), { shouldDirty: true });
    setInputValue('');
    onKeyDownTriggered && onKeyDownTriggered();
  };

  return (
    <div className={styles.wrapper}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value }, fieldState: { error } }) => (
          <div className={styles.field}>
            <TextField
              name={name}
              className={clsx(styles.field__input, (isInvalid || error?.message) && styles.invalid)}
              variant="outlined"
              value={inputValue || ''}
              onChange={handleInput}
              onKeyDown={(e) => handleKeyDown(e, value)}
              inputProps={{ autoComplete: 'off' }}
            />
            {isInvalid && !disableErrorMessage ? (
              <span className={styles.field__error}>{errorMessage}</span>
            ) : (
              <span className={styles.field__tip}>{hintText}</span>
            )}
          </div>
        )}
      />
    </div>
  );
}
