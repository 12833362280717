import React from 'react';
import styles from './Counting.module.scss';

import Indicator from './Indicator';

export default function CountingRow({ color, label, count, boldLabel }) {
  return (
    <div className={styles.row}>
      <div className={styles.row_label}>
        <Indicator color={color} />
        <span className={boldLabel && styles.bold}>{label}:</span>
      </div>
      <div className={styles.row_count}>{count}</div>
    </div>
  );
}
