import api from '../api';
import {
  CLEAR_USER_LIST_STATE,
  SET_ACTIVE_LOCATIONS_USER,
  SET_CURRENT_USER,
  SET_LOAD_MORE_FLAG,
  SET_USER_DEPARTMENTS_LIST,
  SET_USER_ROLE_LIST,
  SET_USER_LIST_FILTER,
  SET_USER_EDIT_TAB,
  SET_USER_PREFIX_LIST,
  SET_USER_LIST_FILTER_CRITERIA
} from '../constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import { SUCCESS_DELETE_USER, SUCCESS_UPDATE_USER } from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import { USER_DETAILED_PATH, USER_LIST_PATH } from '../constants/routeConstants';

export const getUserList = (query) => (dispatch) =>
  api.user
    .getUserListApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getSingleUser = (id) => (dispatch) => {
  api.user
    .getSingleUserApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_USER, payload: res }))
    .catch((err) => console.log(err));
};

export const getFilterCriteria = () => (dispatch) =>
  api.user
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_USER_LIST_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const deleteUser = (id) => () => {
  return api.user.deleteUserApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_USER);
      browserHistory.push(USER_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const getUserRoleList = () => (dispatch) => {
  api.user
    .getUserRoleListApi()
    .then((res) => dispatch({ type: SET_USER_ROLE_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getDepartments = () => (dispatch) => {
  api.user
    .getDepartmentsApi()
    .then((res) => dispatch({ type: SET_USER_DEPARTMENTS_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getPrefixList = (q) => (dispatch) => {
  api.user
    .getPrefixListApi(q)
    .then((res) => dispatch({ type: SET_USER_PREFIX_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getRequestOrderRoles = () => () =>
  api.user
    .getRequestOrderRolesApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getActiveLocations = (query) => (dispatch) => {
  api.reportList
    .getLocationsListApi(query)
    .then((res) => dispatch({ type: SET_ACTIVE_LOCATIONS_USER, payload: res }))
    .catch((err) => console.log(err));
};

export const updateUser =
  (data, { skipRouting = false }) =>
  () => {
    return api.user.updateUserApi(data).then(
      (res) => {
        if (!skipRouting) {
          enqueueSuccessSnackbar(SUCCESS_UPDATE_USER);
          browserHistory.push(`${USER_DETAILED_PATH}/${res.id}`);
        }

        return res;
      },
      (err) => {
        const errors = JSON.parse(err.request.response);
        return errors;
      }
    );
  };

export const setUserEditTab = (data) => (dispatch) => {
  dispatch({ type: SET_USER_EDIT_TAB, payload: data });
};

export const setUserListFilter = (data) => (dispatch) => {
  dispatch({ type: SET_USER_LIST_FILTER, payload: data });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_USER_LIST_STATE });
};
