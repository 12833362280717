import React, { useState } from 'react';
import styles from './commonComponents.module.scss';
import { useFormContext } from 'react-hook-form';
import { wait, isEqual, cloneObj, enqueueErrorSnackbar } from 'helpers/AppHelpers';
import { useDispatch } from 'react-redux';
import { upgradeGeneralReport } from 'actions/reportListActions';
import useInterval from 'use-interval';
import { ERROR_ISSUE_SAVING_DATA_TO_SERVER } from 'constants/infoSnackbarData';

const AUTO_SAVE_INTERVAL = 5000;
const CLOSE_TAG_INTERVAL = 2000;
const MAX_CONSECUTIVE_FAILURES = 3;

const saveReportOptions = {
  type: 'save',
  shouldBlur: true,
  includeNotification: false,
  isAutoSave: true
};

export default function AutoSaveTag({ setAutoSavePosted }) {
  const dispatch = useDispatch();

  const [isPostRequestSent, setPostRequestFlag] = useState(false);

  const [open, setOpen] = useState(false);
  const [consecutiveFailures, setConsecutiveFailures] = useState(0);
  const [interval, setInterval] = useState(AUTO_SAVE_INTERVAL);

  const { getValues, reset } = useFormContext();

  const [lastSavedData, setLastSavedData] = useState(cloneObj(getValues()));

  const handleOpenTag = () => {
    setOpen(true);
    wait(CLOSE_TAG_INTERVAL).then(() => setOpen(false));
  };
  const refreshLastSavedValues = (data) => {
    const newFormDataWithoutReference = cloneObj(data);
    setLastSavedData(newFormDataWithoutReference);
  };

  const checkContributors = (oldData, newData) => {
    if (
      isEqual(oldData?.['additionalContributors'] || [], newData?.['additionalContributors'] || [])
    ) {
      return;
    }

    reset(newData);
  };

  const saveReport = (formData) => {
    !formData?.id && setAutoSavePosted(true);
    !formData?.id && setPostRequestFlag(true);
    dispatch(upgradeGeneralReport(formData, saveReportOptions)).then((res) => {
      if (res.status === 200 || res.status === 201) {
        handleOpenTag();
        if (!formData?.id) {
          const formDataWithId = { ...formData, id: res.data.id };
          reset(formDataWithId);
          refreshLastSavedValues(formDataWithId);
          setAutoSavePosted(false);
          setPostRequestFlag(false);
        } else {
          refreshLastSavedValues(formData);
        }
        checkContributors(formData, res.data);
        consecutiveFailures !== 0 && setConsecutiveFailures(0);
      } else {
        setConsecutiveFailures((prev) => prev + 1);
        if (consecutiveFailures >= MAX_CONSECUTIVE_FAILURES - 1) {
          setAutoSavePosted(false);
          setInterval(null);
          enqueueErrorSnackbar(ERROR_ISSUE_SAVING_DATA_TO_SERVER);
        }
      }
    });
  };

  const formChangesCheck = () => {
    const formData = getValues();

    if (isEqual(formData, lastSavedData)) return;
    if (isPostRequestSent && consecutiveFailures === 0) return;

    saveReport(formData);
  };

  useInterval(formChangesCheck, interval);

  return (
    open && (
      <div className={styles.tag_wrapper}>
        <div className={styles.save_tag}>
          <span>Saved...</span>
        </div>
      </div>
    )
  );
}
