import React from 'react';
import styles from './FormTextArea.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { reformatInputValue } from 'helpers/AppHelpers';

export default function FormTextArea_v2({ name, options, classes }) {
  const { control, setValue, clearErrors } = useFormContext();

  const {
    minRows,
    disableCounter,
    disableError,
    max,
    rule,
    fullHeight,
    onlyErrorHighlight,
    disabled
  } = options || {};

  const handleInput = (e, isInvalid) => {
    let value = e.target.value;
    if (value) {
      value = reformatInputValue(value, max, rule);
    }
    isInvalid && clearErrors(name);
    setValue(name, value, { shouldDirty: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div
          className={clsx(
            styles.wrapper,
            fullHeight && styles.fullHeight,
            disabled && styles.disabled
          )}>
          <TextField
            multiline
            className={clsx(
              !!classes && classes,
              styles.wrapper__input,
              onlyErrorHighlight ? error && styles.error : !disableError && error && styles.error
            )}
            minRows={minRows || 4}
            variant="outlined"
            {...field}
            onChange={(e) => handleInput(e, !!error?.message)}
          />
          {!disableCounter && (
            <span className={styles.wrapper__counter}>
              {field?.value?.length || 0}/{max}
            </span>
          )}
          {!onlyErrorHighlight && !disableError && !!error && (
            <span className={styles.wrapper__error}>{error.message}</span>
          )}
        </div>
      )}
    />
  );
}
