import React from 'react';
import styles from './templateFields.module.scss';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

export default function TextInputField({ name, max, rule, label, isRequired }) {
  return (
    <section className={styles.text_input_field}>
      {label && (
        <label>
          {label}
          {isRequired && '*'}
        </label>
      )}
      <FormInputText name={name} options={{ max, rule }} />
    </section>
  );
}
