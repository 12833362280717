import {
  CLEAR_LOOSE_ITEMS_STATE,
  SET_CATEGORIES_FOR_LOOSE_ITEM_CREATE,
  SET_CATEGORIES_FOR_ITEMS_FILTER,
  SET_CURRENT_SINGLE_ITEM,
  SET_LOAD_MORE_FLAG,
  SET_LOCATIONS_FOR_LOOSE_ITEM_CREATE,
  SET_LOCATIONS_FOR_LOOSE_ITEMS_FILTER,
  SET_LOOSE_ITEMS_FILTER,
  SET_LOOSE_ITEMS_LIST,
  SET_STATUSES_FOR_ITEMS_FILTER,
  SET_TOTAL_QUANTITY,
  SET_TOTAL_BAA,
  SET_CHECKED_LOOSE_ITEMS,
  SET_LOOSE_ITEM_CATEGORY_FIELDS,
  SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS,
  SET_LOOSE_ITEMS_FOR_ITEMS_FILTER,
  RESET_LOOSE_ITEMS_FILTER,
  SET_LOOSE_ITEMS_FILTER_CRITERIA
} from '../constants/reduceConstants';
import api from '../api';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import {
  SUCCESS_CREATE_LOOSE_ITEM,
  SUCCESS_DELETE_LOOSE_ITEM,
  SUCCESS_UPDATE_LOOSE_ITEM
} from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import { LOOSE_ITEM_DETAILED_PATH, LOOSE_ITEMS_LIST_PATH } from '../constants/routeConstants';

export const getTableData = (query) => (dispatch) => {
  return api.looseItemsAndPartsList
    .getTableDataApi(query)
    .then((res) => {
      dispatch({ type: SET_LOOSE_ITEMS_LIST, payload: res });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getLooseItemsCounts = (query) => () =>
  api.looseItemsAndPartsList
    .getLooseItemsCountsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLooseItem = (id) => (dispatch) => {
  api.looseItemsAndPartsList
    .getSingleLooseItemApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_SINGLE_ITEM, payload: res }))
    .catch((err) => console.log(err));
};

export const getFilterCriteria = () => (dispatch) =>
  api.looseItemsAndPartsList
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_LOOSE_ITEMS_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const deleteLooseItem = (id) => () => {
  return api.looseItemsAndPartsList.deleteLooseItemApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_LOOSE_ITEM);
      browserHistory.push(LOOSE_ITEMS_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const updateLooseItemPart = (data) => () => {
  return api.looseItemsAndPartsList.updateLooseItemPartApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const getLocationsWithSublocations = (query, isSummary) => (dispatch) =>
  api.looseItemsAndPartsList
    .getLocationsWithSublocationsApi(query)
    .then((res) => {
      if (isSummary) {
        dispatch({ type: SET_LOCATIONS_FOR_LOOSE_ITEMS_FILTER, payload: res });
      }
      return res;
    })
    .catch((err) => console.log(err));

export const getLocationsForCreateScreen = (query) => (dispatch) =>
  api.looseItemsAndPartsList
    .getLocationsWithSublocationsApi(query)
    .then((res) => dispatch({ type: SET_LOCATIONS_FOR_LOOSE_ITEM_CREATE, payload: res }))
    .catch((err) => console.log(err));

export const getStatuses = (query) => (dispatch) => {
  api.looseItemsAndPartsList
    .getStatusesApi(query)
    .then((res) => dispatch({ type: SET_STATUSES_FOR_ITEMS_FILTER, payload: res }))
    .catch((err) => console.log(err));
};

export const getCustomFields =
  (options = { disableRedux: false }) =>
  (dispatch) => {
    const { disableRedux } = options;

    return api.looseItemsAndPartsList
      .getLoostItemCustomFieldsApi()
      .then((res) => {
        !disableRedux && dispatch({ type: SET_LOOSE_ITEM_CATEGORY_FIELDS, payload: res });
        return res;
      })
      .catch((err) => console.log(err));
  };

export const getCategories = (query, isEditScreen) => (dispatch) =>
  api.looseItemsAndPartsList
    .getCategoriesApi(query)
    .then((res) => {
      if (isEditScreen) {
        dispatch({ type: SET_CATEGORIES_FOR_LOOSE_ITEM_CREATE, payload: res });
      } else {
        dispatch({ type: SET_CATEGORIES_FOR_ITEMS_FILTER, payload: res });
      }
      return res;
    })
    .catch((err) => console.log(err));

export const getLooseItems = () => (dispatch) =>
  api.looseItemsAndPartsList
    .getLooseItemsApi()
    .then((res) => dispatch({ type: SET_LOOSE_ITEMS_FOR_ITEMS_FILTER, payload: res }))
    .catch((err) => console.log(err));

export const createItem = (data, query) => () => {
  return api.looseItemsAndPartsList.createItemApi(data, query).then(
    (res) => {
      browserHistory.push(`${LOOSE_ITEM_DETAILED_PATH}/${res.id}`);
      enqueueSuccessSnackbar(SUCCESS_CREATE_LOOSE_ITEM);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateLooseItem = (data) => () => {
  return api.looseItemsAndPartsList.updateLooseItemApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_LOOSE_ITEM);
      browserHistory.push(`${LOOSE_ITEM_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      if (errors?.errors && 'Id' in errors.errors) {
        enqueueErrorSnackbar('', errors);
      }
      return errors;
    }
  );
};

export const setTotalQuantity = (payload) => (dispatch) => {
  dispatch({ type: SET_TOTAL_QUANTITY, payload });
};
export const setTotalBaa = (payload) => (dispatch) => {
  dispatch({ type: SET_TOTAL_BAA, payload });
};
export const setCheckedItems = (payload) => (dispatch) => {
  dispatch({ type: SET_CHECKED_LOOSE_ITEMS, payload });
};

export const setCustomColumns = (payload) => (dispatch) => {
  dispatch({ type: SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS, payload });
};

export const setListFilter = (filter) => (dispatch) => {
  dispatch({ type: SET_LOOSE_ITEMS_FILTER, payload: filter });
};
export const resetListFilter = (filter) => (dispatch) => {
  dispatch({ type: RESET_LOOSE_ITEMS_FILTER, payload: filter });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_LOOSE_ITEMS_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};
