import { useDispatch } from 'react-redux';

import { setUnsavedFormData } from 'actions/commonActions';
import {
  clearLocationState,
  createLocation,
  getProjectFilterList,
  getSingleLocation,
  getSiteTypesList,
  getStatesList,
  updateLocation
} from 'actions/locationsActions';

export function useLocationActions() {
  const dispatch = useDispatch();

  const getLocationAction = (id) => dispatch(getSingleLocation(id));

  const createLocationAction = (data) => dispatch(createLocation(data));
  const updateLocationAction = (data) => dispatch(updateLocation(data));

  const getSiteTypesListAction = () => dispatch(getSiteTypesList());
  const getStatesListAction = () => dispatch(getStatesList());
  const getProjectListAction = (q) => dispatch(getProjectFilterList(q));

  const clearStateAction = () => dispatch(clearLocationState());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  return {
    getLocationAction,
    createLocationAction,
    updateLocationAction,
    getSiteTypesListAction,
    getStatesListAction,
    getProjectListAction,
    clearStateAction,
    setUnsavedFormDataAction
  };
}
