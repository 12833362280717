import React from 'react';
import styles from './CustomDetails.module.scss';
import Title from './TemplateSection/Title';
import Subtitle from './TemplateSection/Subtitle';
import ReportTextField from './TemplateSection/ReportTextField';
import ReportTextArea from './TemplateSection/ReportTextArea';
import { useFormContext } from 'react-hook-form';

export default function TemplateSection({ field, index }) {
  const { getValues } = useFormContext();

  const isTemplateAvailable = (template) => {
    // Check FOOD SERVICE block show
    if (getValues('project')?.id) return true;

    const { templateId } = template || {};
    if (templateId === 11) {
      return getValues('location')?.hasFoodService;
      // Check CAMP CAPACITY block show
    } else if (templateId === 12) {
      return getValues('location')?.isBaseCamp;
    } else return true;
  };

  return isTemplateAvailable(field) && (
    <section className={styles.template_section}>
      {field.data?.templates?.length &&
        field.data.templates.map((item, fieldIndex) => (
          <React.Fragment key={fieldIndex}>
            {(() => {
              switch (item.type) {
                case 'Title':
                  return <Title field={item} isRequired={field?.data?.required} />;
                case 'Subtitle':
                  return <Subtitle text={item.text} />;
                case 'Textfield':
                  return (
                    <ReportTextField
                      itemId={item.id}
                      label={item.label}
                      name={`generalReportFields[${index}].data.templates[${fieldIndex}].value`}
                    />
                  );
                case 'Textarea':
                  return (
                    <ReportTextArea
                      itemId={item.id}
                      name={`generalReportFields[${index}].data.templates[${fieldIndex}].value`}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </React.Fragment>
        ))}
    </section>
  );
}
