import React, { useEffect, useState } from 'react';
import styles from './ReportEditWrapper.module.scss';

import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import HeaderButton from 'components/DetailsComponents/HeaderButton';
import { MainButton } from 'components/StyledComponents';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useReportActions, useReportSelector } from 'hooks/ReportList';
import { useUserConfig } from 'hooks/useUserConfig';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { getRootListPath } from './helpers';
import {
  EXIT_WITHOUT_SAVING,
  REMOVE_REPORT,
  REMOVE_REPORT_TYPE,
  SAVE_REPORT,
  SAVE_REPORT_TYPE,
  SUBMIT_REPORT,
  SUBMIT_REPORT_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';

import AutoSaveTag from '../AutoSaveTag';
import CustomDetails from '../CustomDetails/CustomDetails';
import SignatureDetails from '../SignatureDetails/SignatureDetails';
import FilesDetails from '../FilesDetails';
import CreatorAndEditorsDetails from '../CreatorAndEditorsDetails';
import AssignmentDetails from '../AssignmentDetails';
import { useSearchParams } from 'hooks/useSearchParams';

export default function ReportEditWrapper({ reportType, children }) {
  const { id } = useParams();
  const isMobile = useMobileViewport();

  const history = useHistory();

  const { generalReportTemplateId } = useSearchParams(['generalReportTemplateId']);

  const { formState, getValues, setError } = useFormContext();
  const { isDirty, errors } = formState;

  const [modalData, setModalData] = useState({});
  const [autoSavePosted, setAutoSavePosted] = useState(false);

  const {
    isAdminUser,
    isBasicRoleUser,
    isFieldTechUser,
    isPersonnelManagerUser,
    isEntityCreatorUser,
    isLocationInConfig,
    isConfigReceived
  } = useUserConfig();

  const { unsavedFormData, autoSaveEnabled, currentGeneralReport, creationForm } =
    useReportSelector();
  const {
    updateGeneralReportAction,
    removeGeneralReportAction,
    clearStateAction,
    setUnsavedFormDataAction,
    getSingleGeneralReportAction,
    startAutoSaveAction,
    stopAutoSaveAction
  } = useReportActions();
  const { users } = creationForm;

  useEffect(() => {
    if (id) {
      getSingleGeneralReportAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (!('isOpened' in modalData)) return;
    if (modalData?.isOpened) {
      stopAutoSaveAction();
    } else {
      startAutoSaveAction();
    }
  }, [modalData]);

  useEffect(() => {
    if (currentGeneralReport?.submitted && isBasicRoleUser) {
      history.push(getRootListPath(reportType));
    }
  }, [currentGeneralReport, isConfigReceived]);

  useEffect(() => {
    if (!id && !generalReportTemplateId) {
      history.push(getRootListPath(reportType));
    }
  }, []);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const shouldShowForm = () => +id === getValues('id') || !!users?.length;

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  const handleSubmitClick = () => {
    if (!validateForm()) return;
    setModalData({ ...SUBMIT_REPORT, isOpened: true });
  };

  const handleBackClick = () => {
    if (isDirty) {
      setModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      history.push(getRootListPath(reportType));
    }
  };

  const handleSaveClick = () => {
    if (currentGeneralReport?.submitted) {
      if (!validateForm()) return;
    }
    setModalData({ ...SAVE_REPORT, isOpened: true });
  };
  const handleRemoveClick = () => setModalData({ ...REMOVE_REPORT, isOpened: true });

  const agreeModal = () => {
    switch (modalData.type) {
      case SAVE_REPORT_TYPE:
        closeModal();
        updateGeneralReportAction(getValues(), {
          type: 'save',
          shouldBlur: false,
          includeNotification: id ? !getValues('submitted') : true
        });
        break;
      case SUBMIT_REPORT_TYPE:
        closeModal();
        updateGeneralReportAction(
          { ...getValues(), submitted: true },
          {
            type: 'submit',
            shouldBlur: false,
            includeNotification: true
          }
        );
        break;
      case REMOVE_REPORT_TYPE:
        closeModal();
        removeGeneralReportAction(getValues());
        break;
      case WITHOUT_SAVING_TYPE:
        history.push(getRootListPath(reportType));
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const closeModal = () => setModalData({ isOpened: false });

  const validateForm = () => {
    let isFormValid = true;

    const allFields = getValues('generalReportFields');
    const requiredFields = allFields.filter(({ data }) => data.required);

    requiredFields.forEach((el) => {
      const needIndex = allFields.findIndex(({ id }) => id === el.id);

      if (el.generalReportFieldType.fieldType === 'Text' && !el.data.value) {
        const errorName = `generalReportFields[${needIndex}].data.value`;

        setError(errorName, getErrorsProperties('Field is required'), { shouldFocus: true });
        isFormValid = false;
        return;
      }

      if (el.generalReportFieldType.fieldType === 'LocationTemplate') {
        el.data.templates.forEach((template, templateIndex) => {
          if (template.type === 'Subtitle' || template.type === 'Title') return;
          if (template.value) return;
          const errorName = `generalReportFields[${needIndex}].data.templates[${templateIndex}].value`;
          setError(errorName, getErrorsProperties('Field is required'), { shouldFocus: true });
          isFormValid = false;
        });
        return;
      }

      if (el.generalReportFieldType.fieldType === 'AssetTemplate') {
        el.data.templates.forEach((template, templateIndex) => {
          if (template.value) return;
          const errorName = `generalReportFields[${needIndex}].data.templates[${templateIndex}].value`;
          setError(errorName, getErrorsProperties('Field is required'), { shouldFocus: true });
          isFormValid = false;
        });
        return;
      }

      if (
        el.generalReportFieldType.fieldType === 'Choice' &&
        el.data.options.every(({ selected }) => !selected)
      ) {
        const errorName = `generalReportFields[${needIndex}].data.options`;

        setError(errorName, getErrorsProperties('At least one option must be chosen'), {
          shouldFocus: true
        });
        isFormValid = false;
      }

      if (el.generalReportFieldType.fieldType === 'Table') {
        el.data.rows.forEach(({ columns }, rowIndex) => {
          columns.forEach((col, colIndex) => {
            if (!col.value) {
              const errorName = `generalReportFields[${needIndex}].data.rows[${rowIndex}].columns[${colIndex}].value`;
              setError(errorName, getErrorsProperties('Field is required'), {
                shouldFocus: true
              });
              isFormValid = false;
            }
          });
        });
      }

      if (el.generalReportFieldType.fieldType === 'Signature') {
        el.data.textFields.forEach(({ value }, fieldIndex) => {
          if (!value) {
            const errorName = `generalReportFields[${needIndex}].data.textFields[${fieldIndex}].value`;
            setError(errorName, getErrorsProperties('Field is required'), {
              shouldFocus: true
            });
            isFormValid = false;
          }
        });
        if (!el.data?.resource?.id) {
          const errorName = `generalReportFields[${needIndex}].data.resource`;
          setError(errorName, getErrorsProperties('Signature is required'), {
            shouldFocus: true
          });
          isFormValid = false;
        }
      }
    });

    return isFormValid;
  };

  const isSubmitAvailable = () => {
    switch (reportType) {
      case 'location':
        return isBasicRoleUser
          ? isReportAvailableForBasicUser()
          : isFieldTechUser || isPersonnelManagerUser
          ? isReportAvailableForFieldTechOrPersonnelManager()
          : true;
      case 'asset':
        return isBasicRoleUser ? isReportAvailableForBasicUser() : true;
      case 'general': {
        if (isAdminUser) return true;
        else return !id || isEntityCreatorUser(getValues('createdByUser')?.email);
      }
    }
  };

  const isReportAvailableForBasicUser = () =>
    !id ||
    isEntityCreatorUser(getValues('createdByUser')?.email) ||
    isLocationInConfig(getValues('location')?.id);
  const isReportAvailableForFieldTechOrPersonnelManager = () =>
    !id ||
    isEntityCreatorUser(getValues('createdByUser')?.email) ||
    isLocationInConfig(getValues('location')?.id);

  return (
    <section className={styles.wrapper}>
      {shouldShowForm() && (
        <>
          <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
          {!!autoSaveEnabled && <AutoSaveTag setAutoSavePosted={setAutoSavePosted} />}
          {!isMobile && (
            <BreadcrumbsNav
              itemsArray={[{ name: id ? 'Edit a report' : 'Create a report' }]}
              setDialogModalData={setModalData}
              formIsChanged={isDirty}
            />
          )}
          <div className={styles.header}>
            <h1>{id ? 'Edit Report' : 'Create Report'}</h1>
            <div className={styles.header__controls}>
              {!!id && isMobile && isAdminUser && (
                <HeaderButton onClick={handleRemoveClick}>
                  <DeleteIcon />
                </HeaderButton>
              )}
              {isMobile && (
                <HeaderButton onClick={handleBackClick}>
                  <ChevronLeftIcon />
                </HeaderButton>
              )}
            </div>
          </div>
          {children}
          <CustomDetails reportType={reportType} />
          <SignatureDetails />
          <FilesDetails />
          <CreatorAndEditorsDetails reportType={reportType} />
          <AssignmentDetails />
          <div className={styles.footer}>
            {!!id && !isMobile && isAdminUser && (
              <MainButton text="remove" type="secondary" action={handleRemoveClick} />
            )}
            {!!id && !!getValues('submitted') && (
              <MainButton text="cancel" type="secondary" action={handleBackClick} />
            )}
            <MainButton
              text="save"
              type="primary"
              action={handleSaveClick}
              isDisabled={
                currentGeneralReport?.submitted
                  ? !!isFormInvalid() || autoSavePosted
                  : autoSavePosted
              }
            />

            {isSubmitAvailable() && !getValues('submitted') && (
              <MainButton
                text="submit report"
                type="primary"
                action={handleSubmitClick}
                isDisabled={!!isFormInvalid() || autoSavePosted}
              />
            )}
            {autoSavePosted && <span>Saving data in progress...</span>}
          </div>
        </>
      )}
    </section>
  );
}
