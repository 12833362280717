import {
  SET_LOCATIONS_LIST,
  SET_CURRENT_LOCATION,
  SET_SITE_TYPES_LIST,
  SET_STATES_LIST,
  CLEAR_LOCATION_LIST_STATE,
  SET_PROJECT_FILTER_LIST,
  SET_MAP_LOCATIONS_LIST,
  SET_ACTIVE_LOCATIONS_LIST,
  SET_ACTIVE_PROJECT_STATE,
  SET_MAP_FILTER_CRITERIA,
  SET_MAP_ADVANCED_PREFILTER,
  SET_NEAREST_LOCATIONS_DATA,
  CLEAR_ACTIVE_PROJECT_STATE,
  CLEAR_LOCATION_STATE,
  SET_ASSET_RENTAL_STATUSES_DATA,
  SET_LOCATION_LIST_FILTER,
  SET_LOCATION_LOOSE_ITEMS_AND_PARTS,
  SET_LOCATION_ASSETS_LIST,
  SET_LOCATION_OPENED_TICKET_LIST,
  SET_LOCATION_CLOSED_TICKET_LIST,
  SET_LOCATION_ASSETS_LIST_FILTER,
  SET_LOCATION_LOOSE_ITEMS_FILTER,
  SET_LOCATION_CLOSED_TICKET_LIST_FILTER,
  SET_LOCATION_OPENED_TICKET_LIST_FILTER,
  SET_SHORTED_LOCATIONS_LIST,
  SET_LOCATION_DETAILS_TAB,
  RESET_LOCATION_LIST_FILTER
} from 'constants/reduceConstants';
import { getClearedFilterByUnmount } from 'helpers/AppHelpers';

const pagination = { page: 1, totalPages: 1, limit: 10 };
const defaultFilter = {
  filters: {
    active: true,
    searchQuery: '',
    siteTypeIds: [],
    locationJobNumberIds: []
  },
  pagination,
  sortRules: [],
  responseReceived: false
};

const getItems = (state, payload) => {
  const { data, isAutoload } = payload;
  return isAutoload ? { ...data, items: state.items.concat(data.items) } : data;
};

const initialState = {
  locationsList: [],
  locationFilter: { ...defaultFilter },
  siteTypesList: [],
  shortedLocations: [],
  statesList: [],
  currentLocation: {},
  activeLocationsList: [],
  projectFilterList: [],
  locationsFilterList: [],
  activeProject: {},
  nearestLocationsData: {},
  mapFilterCriteria: {},
  mapAdvancedPrefilter: {},
  assetRentalStatuses: [],
  // location details tables
  selectedTab: 'locationAssets',
  locationLooseItems: [],
  looseItemsFilter: { filters: {}, pagination, sortRules: [], isDataReceived: false },
  openedTicketsList: [],
  openedTicketsListFilter: { filters: {}, pagination, sortRules: [], isDataReceived: false },
  closedTicketsList: [],
  closedTicketsListFilter: { filters: {}, pagination, sortRules: [], isDataReceived: false },
  assetsList: [],
  assetsListFilter: {
    filters: { sublocationIds: [], rentalIds: [], prefixIds: [], severities: [] },
    pagination,
    sortRules: [],
    isDataReceived: false
  }
};

const locationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOCATIONS_LIST:
      return { ...state, locationsList: payload };
    case SET_LOCATION_LIST_FILTER:
      return { ...state, locationFilter: payload };
    case RESET_LOCATION_LIST_FILTER:
      return {
        ...state,
        locationFilter: {
          ...state.locationFilter,
          filters: { ...defaultFilter.filters, ...payload }
        }
      };
    case SET_CURRENT_LOCATION:
      return { ...state, currentLocation: payload };
    case SET_SITE_TYPES_LIST:
      return { ...state, siteTypesList: payload };
    case SET_SHORTED_LOCATIONS_LIST:
      return { ...state, shortedLocations: payload };
    case SET_STATES_LIST:
      return { ...state, statesList: payload };
    case SET_ACTIVE_LOCATIONS_LIST:
      return { ...state, activeLocationsList: payload };
    case SET_PROJECT_FILTER_LIST:
      return { ...state, projectFilterList: payload };
    case SET_MAP_LOCATIONS_LIST:
      return { ...state, locationsFilterList: payload };
    case SET_ACTIVE_PROJECT_STATE:
      return { ...state, activeProject: payload };
    case SET_MAP_FILTER_CRITERIA:
      return { ...state, mapFilterCriteria: payload };
    case SET_MAP_ADVANCED_PREFILTER:
      return { ...state, mapAdvancedPrefilter: payload };
    case SET_NEAREST_LOCATIONS_DATA:
      return { ...state, nearestLocationsData: payload };
    case CLEAR_ACTIVE_PROJECT_STATE:
      return { ...state, activeProject: {} };
    case SET_ASSET_RENTAL_STATUSES_DATA:
      return { ...state, assetRentalStatuses: payload };
    // location details tables
    case SET_LOCATION_DETAILS_TAB:
      return { ...state, selectedTab: payload };
    case SET_LOCATION_LOOSE_ITEMS_AND_PARTS:
      return { ...state, locationLooseItems: getItems(state.locationLooseItems, payload) };
    case SET_LOCATION_LOOSE_ITEMS_FILTER:
      return { ...state, looseItemsFilter: payload };
    case SET_LOCATION_ASSETS_LIST:
      return { ...state, assetsList: getItems(state.assetsList, payload) };
    case SET_LOCATION_ASSETS_LIST_FILTER:
      return { ...state, assetsListFilter: payload };
    case SET_LOCATION_OPENED_TICKET_LIST:
      return { ...state, openedTicketsList: getItems(state.openedTicketsList, payload) };
    case SET_LOCATION_OPENED_TICKET_LIST_FILTER:
      return { ...state, openedTicketsListFilter: payload };
    case SET_LOCATION_CLOSED_TICKET_LIST:
      return { ...state, closedTicketsList: getItems(state.closedTicketsList, payload) };
    case SET_LOCATION_CLOSED_TICKET_LIST_FILTER:
      return { ...state, closedTicketsListFilter: payload };
    case CLEAR_LOCATION_LIST_STATE:
    case CLEAR_LOCATION_STATE: // for active locations
      return {
        ...initialState,
        locationFilter: getClearedFilterByUnmount(state.locationFilter),
        assetsListFilter: getClearedFilterByUnmount(state.assetsListFilter),
        openedTicketsListFilter: getClearedFilterByUnmount(state.openedTicketsListFilter),
        closedTicketsListFilter: getClearedFilterByUnmount(state.closedTicketsListFilter),
        looseItemsFilter: getClearedFilterByUnmount(state.looseItemsFilter),
        mapAdvancedPrefilter: state.mapAdvancedPrefilter,
        selectedTab: state.selectedTab
      };
    default:
      return state;
  }
};

export default locationsReducer;
