import React from 'react';
import styles from './CustomDetails.module.scss';
import TextSection from './CustomFields/TextSection';
import ChoiceSection from './ChoiceSection';
import TitleSection from './TitleSection';
import BreakLine from './BreakLine';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AssetTemplateSection from './CustomFields/AssetTemplateSection';
import TemplateSection from './TemplateSection';
import ResourceSection from './ResourceSection';
import CustomTableSection from './CustomTableSection';
import SignatureSection from './SignatureSection';

export default function CustomDetails({ reportType }) {
  const { control, getValues } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'generalReportFields'
  });

  const isTemplateAvailable = (template) => {
    // Check FOOD SERVICE block show
    const { templateId } = template || {};
    if (templateId === 11) {
      return getValues('location')?.hasFoodService;
      // Check CAMP CAPACITY block show
    } else if (templateId === 12) {
      return getValues('location')?.isBaseCamp;
    } else return true;
  };

  const isLineAvailable = (fieldIndex) =>
    reportType === 'location' ? isTemplateAvailable(fields[fieldIndex + 1]) : true;

  return (
    <div className={styles.custom_details}>
      {!!fields?.length &&
        fields.map((field, index) => (
          <React.Fragment key={field.id}>
            {(() => {
              switch (field['generalReportFieldType'].fieldType) {
                case 'Text':
                  return <TextSection field={field} index={index} />;
                case 'Choice':
                  return <ChoiceSection fieldItem={field} index={index} />;
                case 'Title':
                  return <TitleSection>{field['data'].text}</TitleSection>;
                case 'Resource':
                  return <ResourceSection field={field} />;
                case 'Table':
                  return <CustomTableSection field={field} fieldIndex={index} />;
                case 'Line':
                  return isLineAvailable(index) && <BreakLine />;
                case 'AssetTemplate':
                  return <AssetTemplateSection field={field} index={index} />;
                case 'LocationTemplate':
                  return <TemplateSection field={field} index={index} />;
                case 'Signature':
                  return <SignatureSection field={field} index={index} />;
                default:
                  return null;
              }
            })()}
          </React.Fragment>
        ))}
    </div>
  );
}
