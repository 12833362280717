import React, { useEffect, useState } from 'react';
import styles from './Wrapper.module.scss';

import Header from './Header/Header';
import Footer from './Footer/Footer';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import MatchingLocation from 'components/ModalContents/MatchingLocation';

import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import {
  useManagePicklistItemActions,
  useManagePicklistItemSelector
} from 'hooks/ManagePicklistItem';

import {
  ADD_ITEMS_TO_PICKLIST,
  ADD_ITEMS_TO_PICKLIST_TYPE,
  EDIT_ITEMS_OF_PICKLIST,
  EDIT_ITEM_OF_PICKLIST_TYPE,
  EXIT_WITHOUT_SAVING,
  PICKLIST_ITEMS_LOCATION_CHECK,
  PICKLIST_ITEMS_LOCATION_CHECK_TYPE
} from 'constants/dialogPopupsData';
import { PICKLIST_DETAILED_PATH } from 'constants/routeConstants';
import { WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_QTY } from 'constants/infoSnackbarData';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import { enqueueWarningSnackbar, isSuccessfulStatus, pluck } from 'helpers/AppHelpers';

import { checkByAheadAccount, checkMoveQuantity, checkRowForWarnings } from '../../helpers';

export default function CreateAssetBatchWrapper({ children }) {
  const history = useHistory();
  const [modalData, setModalData] = useState({});

  const { unsavedFormData } = useCommonSelector();
  const { setUnsavedFormDataAction } = useCommonActions();

  const {
    checkLooseItemsLocationAction,
    addLooseItemsToPicklistAction,
    editLooseItemOfPicklistAction
  } = useManagePicklistItemActions();
  const { picklistData, isEditMode } = useManagePicklistItemSelector();

  const { activePicklist, locationLooseItemDetails } = picklistData;
  const picklistPath = `${PICKLIST_DETAILED_PATH}/${activePicklist?.id}`;

  const { getValues, setValue, formState, setError } = useFormContext();
  const { isDirty } = formState;

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  const validateForm = () => {
    let isFormValid = true;

    const looseItems = getValues('items');

    looseItems.forEach((row, index) => {
      const moveQtyErrorMessage = checkMoveQuantity(row);
      const moveBaaErrorMessage = checkByAheadAccount(row);

      if (moveQtyErrorMessage) {
        setError(`items[${index}].moveQuantity`, getErrorsProperties(moveQtyErrorMessage));
        isFormValid = false;
      }

      if (moveBaaErrorMessage) {
        setError(`items[${index}].moveByAheadAccount`, getErrorsProperties(moveBaaErrorMessage));
        isFormValid = false;
      }
    });

    return isFormValid;
  };

  const validateWarnings = () => {
    const rowsWithWarnings = [];
    const warnings = getValues('warnings');
    const looseItems = getValues('items');

    looseItems.forEach((row, index) => {
      if (checkRowForWarnings(row)) {
        rowsWithWarnings.push(`items[${index}]`);
      }
    });

    const shouldDisplayPopup = !warnings?.length && !!rowsWithWarnings?.length;

    if (shouldDisplayPopup) {
      setValue('warnings', rowsWithWarnings);
      enqueueWarningSnackbar(WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_QTY);
    }

    return shouldDisplayPopup;
  };

  const handleBackClick = (item) => {
    if (isDirty || unsavedFormData) {
      setModalData({ ...EXIT_WITHOUT_SAVING, selectedItemPath: item.path });
    } else {
      history.push(item.path);
    }
  };

  const goToPicklistDetails = () => handleBackClick({ path: picklistPath });

  const handleCreateClick = () => {
    if (!validateForm()) return;

    if (validateWarnings()) return;

    if (isEditMode) {
      const modalTitle = `Do you want to edit items of the picklist ${activePicklist.name}?`;
      setModalData({ isOpened: true, ...EDIT_ITEMS_OF_PICKLIST, title: modalTitle });
    } else {
      const modalTitle = `Do you want to add items to the picklist ${activePicklist.name}?`;
      setModalData({ isOpened: true, ...ADD_ITEMS_TO_PICKLIST, title: modalTitle });
    }
  };

  const modifyItemForBackend = (el) => {
    const { moveQuantity, moveByAheadAccount, ...rest } = el;
    return {
      locationLooseItem: rest,
      moveByAheadAccount: moveByAheadAccount || null,
      moveQuantity
    };
  };

  const addItemsToPicklist = (itemsToExclude = []) => {
    const excludedItemIds = pluck(itemsToExclude, 'id');
    const items = getValues('items')
      .filter(({ id }) => !excludedItemIds.includes(id))
      .map(modifyItemForBackend);

    const params = {
      createPicklistLooseItemDtos: items,
      picklist: activePicklist
    };

    if (!params?.createPicklistLooseItemDtos?.length) return;

    addLooseItemsToPicklistAction(params).then((res) => {
      if (isSuccessfulStatus(res.status)) {
        history.push(picklistPath);
      }
    });
  };

  const agreeModal = () => {
    switch (modalData.type) {
      case EDIT_ITEM_OF_PICKLIST_TYPE: {
        const picklist = activePicklist;
        const picklistAssetId = locationLooseItemDetails.picklistAssetId;
        const params = {
          createPicklistLooseItemDto: modifyItemForBackend(getValues('items')[0]),
          picklist,
          picklistAssetId
        };
        editLooseItemOfPicklistAction(params).then((res) => {
          if (isSuccessfulStatus(res.status)) {
            history.push(picklistPath);
          }
        });
        break;
      }
      case ADD_ITEMS_TO_PICKLIST_TYPE: {
        const query = {
          picklistId: activePicklist.id,
          locationLooseItemIds: pluck(getValues('items'), 'id')
        };
        checkLooseItemsLocationAction(query).then((res) => {
          if (!res?.locationLooseItems?.length) {
            addItemsToPicklist();
          } else {
            setModalData({
              ...PICKLIST_ITEMS_LOCATION_CHECK,
              itemsToExclude: res.locationLooseItems,
              content: (
                <MatchingLocation
                  items={res.locationLooseItems}
                  options={{
                    itemType: 'looseItem',
                    documentType: 'picklist',
                    endpointType: res['isPicklistWithSublocation'] ? 'sublocation' : 'location'
                  }}
                />
              )
            });
          }
        });
        break;
      }
      case PICKLIST_ITEMS_LOCATION_CHECK_TYPE:
        addItemsToPicklist();
        break;
      default:
        closeModal();
        history.push(modalData.selectedItemPath);
        break;
    }
  };

  const closeModal = () => {
    if (modalData.type === PICKLIST_ITEMS_LOCATION_CHECK_TYPE) {
      addItemsToPicklist(modalData.itemsToExclude);
    }
    setModalData({});
  };

  return (
    <div className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <Header onLinkClick={handleBackClick} />
      {children}
      <Footer onCreate={handleCreateClick} onCancel={goToPicklistDetails} />
    </div>
  );
}
