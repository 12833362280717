import React from 'react';
import styles from './AssetGeneralDetails.module.scss';

import DetailedRow from 'components/DetailsComponents/DetailedRow';
import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import StatusIndicator from 'components/StatusIndicator';
import Subtitle from 'components/DetailsComponents/Subtitle';

import { useAssetSelector } from 'hooks/Asset';

import {
  ASSET_DETAILED_PATH,
  LEAVELIST_DETAILED_PATH,
  PICKLIST_DETAILED_PATH
} from 'constants/routeConstants';

import { formatDate, removeCharacterZ } from 'helpers/AppHelpers';

import NeedsToBeInspectedPopup from 'pages/AssetSummary/components/InspectedInfo/NeedsToBeInspectedPopup';

export default function AssetGeneralDetails() {
  const { currentAsset } = useAssetSelector();

  const {
    lastLocationHistory,
    containedInAsset,
    isInPicklist,
    isInLeavelist,
    rental,
    lastVerified,
    labeled,
    purchasedFrom,
    purchasedAtUtc,
    grade,
    isNonOwned,
    shortNote,
    severity,
    inspectionStatus
  } = currentAsset;

  return (
    <div className={styles.wrapper}>
      <Subtitle>General Details</Subtitle>
      <div className={styles.general}>
        <div className={styles.general__block}>
          <DetailedRow
            disableFlex
            label="Location"
            value={lastLocationHistory?.location?.siteCode || ''}
          />
          <DetailedRow
            disableFlex
            label="Sublocation"
            value={lastLocationHistory?.sublocation?.name || ''}
          />
          <DetailedRow
            disableFlex
            label="Contained In"
            value={
              containedInAsset?.drCode && (
                <LinkComponent
                  name={containedInAsset?.drCode}
                  path={`${ASSET_DETAILED_PATH}/${containedInAsset?.id}`}
                />
              )
            }
          />
          <DetailedRow label="Status" value={severity?.displayName || ''}>
            <div className={styles.status__indicator}>
              <StatusIndicator status={severity?.name} />
              {inspectionStatus?.name === 'NeedToBeInspected' && <NeedsToBeInspectedPopup />}
            </div>
          </DetailedRow>

          <DetailedRow
            disableFlex
            label="Rental Status"
            value={rental?.rentalStatus?.displayName || ''}
          />
          <DetailedRow
            disableFlex
            label="Start date"
            value={rental?.startDate ? formatDate(removeCharacterZ(rental?.startDate)) : ''}
          />
          <DetailedRow
            disableFlex
            label="End date"
            value={rental?.endDate ? formatDate(removeCharacterZ(rental?.endDate)) : ''}
          />
          {rental?.rentalStatus?.name === 'Reserved' && (
            <DetailedRow disableFlex label="Project Name" value={rental?.project?.name || ''} />
          )}
          <DetailedRow
            disableFlex
            label="Destination"
            value={rental?.destination?.siteCode || ''}
          />
          {(rental?.rentalStatus?.name === 'HoldForLow' ||
            rental?.rentalStatus?.name === 'HoldForHigh') && (
            <DetailedRow disableFlex label="Project Name" value={rental?.project?.name || ''} />
          )}
          <DetailedRow
            disableFlex
            disableColon
            label="Is in Picklist?"
            value={
              <LinkComponent
                name={isInPicklist?.name || ''}
                path={`${PICKLIST_DETAILED_PATH}/${isInPicklist?.id}`}
                isRedirectAvailable={!!isInPicklist?.id}
              />
            }
          />
          <DetailedRow
            disableFlex
            label="Is in Leavelist?"
            disableColon
            value={
              <LinkComponent
                name={isInLeavelist?.name || ''}
                path={`${LEAVELIST_DETAILED_PATH}/${isInLeavelist?.id}`}
                isRedirectAvailable={!!isInLeavelist?.id}
              />
            }
          />
        </div>
        <div className={styles.general__block}>
          <DetailedRow
            disableFlex
            label="Last inspected"
            value={
              lastVerified?.['inspectionDateAtUtc']
                ? formatDate(lastVerified['inspectionDateAtUtc'])
                : ''
            }
          />
          <div className={styles.general__block_checkbox}>
            <label>Labeled</label>
            <StyledCheckbox disabled={true} checked={labeled} />
          </div>
          <DetailedRow disableFlex label="Purchased From" value={purchasedFrom || ''} />
          <DetailedRow
            disableFlex
            label="Purchased Date"
            value={purchasedAtUtc ? formatDate(purchasedAtUtc) : ''}
          />
          <DetailedRow disableFlex label="Grade" value={grade?.displayName || ''} />
          <div className={styles.general__block_checkbox}>
            <label>Non-owned?</label>
            <StyledCheckbox disabled={true} checked={isNonOwned} />
          </div>
          <DetailedRow disableFlex label="Short Note" value={shortNote || ''} />
        </div>
      </div>
    </div>
  );
}
