import api from '../api';
import { browserHistory } from 'browserHistory';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

import {
  SET_LOCATIONS_LIST,
  SET_CURRENT_LOCATION,
  SET_SITE_TYPES_LIST,
  SET_STATES_LIST,
  CLEAR_LOCATION_LIST_STATE,
  SET_ACTIVE_LOCATIONS_LIST,
  SET_PROJECT_FILTER_LIST,
  SET_MAP_LOCATIONS_LIST,
  SET_ACTIVE_PROJECT_STATE,
  SET_MAP_FILTER_CRITERIA,
  SET_NEAREST_LOCATIONS_DATA,
  SET_MAP_ADVANCED_PREFILTER,
  CLEAR_ACTIVE_PROJECT_STATE,
  CLEAR_LOCATION_STATE,
  SET_LOAD_MORE_FLAG,
  SET_ASSET_RENTAL_STATUSES_DATA,
  SET_LOCATION_LIST_FILTER,
  SET_LOCATION_LOOSE_ITEMS_AND_PARTS,
  SET_LOCATION_ASSETS_LIST,
  SET_LOCATION_OPENED_TICKET_LIST,
  SET_LOCATION_CLOSED_TICKET_LIST,
  SET_LOCATION_ASSETS_LIST_FILTER,
  SET_LOCATION_LOOSE_ITEMS_FILTER,
  SET_LOCATION_CLOSED_TICKET_LIST_FILTER,
  SET_LOCATION_OPENED_TICKET_LIST_FILTER,
  SET_SHORTED_LOCATIONS_LIST,
  SET_LOCATION_DETAILS_TAB,
  RESET_LOCATION_LIST_FILTER
} from 'constants/reduceConstants';
import {
  ERROR_FIND_LOCATIONS,
  ERROR_LESS_AMOUNT,
  SUCCESS_CREATE_LOCATION,
  SUCCESS_DELETE_LOCATION,
  SUCCESS_UPDATE_LOCATION
} from '../constants/infoSnackbarData';
import { LOCATION_DETAILED_PATH, LOCATIONS_LIST_PATH } from '../constants/routeConstants';

export const getLocationsList = (query) => (dispatch) => {
  return api.locations
    .getLocationsListApi(query)
    .then((res) => {
      dispatch({ type: SET_LOCATIONS_LIST, payload: res });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getSingleLocation = (id) => (dispatch) => {
  api.locations
    .getSingleLocationApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_LOCATION, payload: res }))
    .catch((err) => console.log(err));
};

export const getAssetsFilterCriteria = () => () =>
  api.locations
    .getAssetsFilterCriteriaApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const createLocation = (data) => () => {
  return api.locations.createLocationApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_LOCATION);
      browserHistory.push(`${LOCATION_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateLocation = (data) => () => {
  return api.locations.updateLocationApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_LOCATION);
      browserHistory.push(`${LOCATION_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const deleteLocation = (id) => () => {
  return api.locations.deleteLocationApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_LOCATION);
      browserHistory.push(LOCATIONS_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const getRentalStatuses = (query) => (dispatch) => {
  return api.locations.getRentalStatuesApi(query).then(
    (res) => {
      dispatch({ type: SET_ASSET_RENTAL_STATUSES_DATA, payload: res });
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const getLocationLooseItems = (query, param) => (dispatch) => {
  return api.locations.getLocationLooseItemsApi(query).then(
    (res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_LOCATION_LOOSE_ITEMS_AND_PARTS, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    },
    (err) => console.log(err)
  );
};
export const setLocationLooseItemsFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_LOCATION_LOOSE_ITEMS_FILTER, payload });
};

export const getLocationAssetsList = (query, param) => (dispatch) => {
  return api.locations
    .getLocationAssetsListApi({
      ...query,
      filters: { ...query.filters, isNonOwned: false, isDeleted: false }
    })
    .then(
      (res) => {
        const isAutoload = param === 'AUTOLOAD';
        dispatch({ type: SET_LOCATION_ASSETS_LIST, payload: { data: res, isAutoload } });
        dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
        return res;
      },
      (err) => console.log(err)
    );
};
export const setLocationAssetsListFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_LOCATION_ASSETS_LIST_FILTER, payload });
};

export const getOpenedTicketsList = (query, param) => (dispatch) => {
  return api.locations
    .getTicketsListApi(query)
    .then((res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_LOCATION_OPENED_TICKET_LIST, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};
export const setOpenedTicketsListFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_LOCATION_OPENED_TICKET_LIST_FILTER, payload });
};

export const getClosedTicketsList = (query, param) => (dispatch) => {
  return api.locations
    .getTicketsListApi({ ...query, filters: { ...query.filters, isOpened: false } })
    .then((res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_LOCATION_CLOSED_TICKET_LIST, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};
export const setClosedTicketsListFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_LOCATION_CLOSED_TICKET_LIST_FILTER, payload });
};

export const updateRentalStatuses = (data) => () => {
  return api.locations.updateRentalStatuesApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const getSiteTypesList = () => (dispatch) => {
  api.locations
    .getSiteTypesListApi()
    .then((res) => dispatch({ type: SET_SITE_TYPES_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getShortedLocationList = () => (dispatch) => {
  api.locations
    .getShortedLocationListApi({ filters: { active: true } })
    .then((res) => dispatch({ type: SET_SHORTED_LOCATIONS_LIST, payload: res?.items || [] }))
    .catch((err) => console.log(err));
};

export const getStatesList = () => (dispatch) => {
  api.locations
    .getStatesListApi()
    .then((res) => dispatch({ type: SET_STATES_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const setLocationListFilter = (data) => (dispatch) => {
  dispatch({ type: SET_LOCATION_LIST_FILTER, payload: data });
};
export const resetLocationListFilter = (data) => (dispatch) => {
  dispatch({ type: RESET_LOCATION_LIST_FILTER, payload: data });
};
export const clearLocationsState = () => (dispatch) => {
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
  dispatch({ type: CLEAR_LOCATION_LIST_STATE });
};

export const getActiveLocationsList = (query) => (dispatch) => {
  api.activeLocations
    .getActiveLocationsListApi(query)
    .then((res) => dispatch({ type: SET_ACTIVE_LOCATIONS_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getProjectFilterList = (query) => (dispatch) => {
  api.activeLocations
    .getProjectFilterListApi(query)
    .then((res) => dispatch({ type: SET_PROJECT_FILTER_LIST, payload: res?.items || [] }))
    .catch((err) => console.log(err));
};

export const getMapProjectList = () => (dispatch) => {
  api.activeLocations
    .getMapProjectListApi()
    .then((res) => dispatch({ type: SET_PROJECT_FILTER_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getMapLocations = (q) => (dispatch) => {
  api.activeLocations
    .getMapLocationsApi(q)
    .then((res) => dispatch({ type: SET_MAP_LOCATIONS_LIST, payload: res?.items || [] }))
    .catch((err) => console.log(err));
};

export const getMapFilterCriteria = () => (dispatch) =>
  api.activeLocations
    .getMapFilterCriteriaApi()
    .then((res) => dispatch({ type: SET_MAP_FILTER_CRITERIA, payload: res }))
    .catch((err) => console.log(err));

export const getNearestMapLocations =
  (data, shouldShowPopup = true) =>
  (dispatch) =>
    api.activeLocations
      .getNearestMapLocationsApi(data)
      .then((res) => {
        if (!res.nearestLocations?.length) {
          enqueueErrorSnackbar(ERROR_FIND_LOCATIONS);
        } else {
          dispatch({ type: SET_NEAREST_LOCATIONS_DATA, payload: res });
          if (res?.isLessThanNeeded && shouldShowPopup) {
            enqueueErrorSnackbar(ERROR_LESS_AMOUNT);
          }
        }
        return res;
      })
      .catch((err) => console.log(err));

export const setMapAdvancedPrefilter = (data) => (dispatch) =>
  dispatch({ type: SET_MAP_ADVANCED_PREFILTER, payload: data });

export const clearAdvancedFilterState = () => (dispatch) =>
  dispatch({ type: SET_NEAREST_LOCATIONS_DATA, payload: {} });

export const setActiveProject = (project) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_PROJECT_STATE, payload: project });
};

export const setLocationDetailsTab = (payload) => (dispatch) => {
  dispatch({ type: SET_LOCATION_DETAILS_TAB, payload });
};

export const clearActiveProject = () => (dispatch) => {
  dispatch({ type: CLEAR_ACTIVE_PROJECT_STATE });
};

export const clearLocationState = () => (dispatch) => {
  dispatch({ type: CLEAR_LOCATION_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};
