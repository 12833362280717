import React, { useEffect, useState } from 'react';
import styles from './AddDeliveryItemsPopup.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';
import AddingNonExistingItemsField from 'components/AddingNonExistingItemsField/AddingNonExistingItemsField';
import MultipleAssetSelectionField from 'components/MultipleAssetSelectionField/MultipleAssetSelectionField';
import { QRCodeScanner } from 'components/QR';

import {
  allKeyboardLatSymbols,
  getAssetIdFromUrl,
  getErrorAssetInLeavelistMessage,
  getErrorAssetInPicklistMessage,
  getErrorAssetIsNotAvailableMessage,
  getErrorFindAssetMessage,
  enqueueErrorSnackbar
} from 'helpers/AppHelpers';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectBoLCreationFormData } from '../../../selectors';
import { getBillOfLadingLooseItems, getScannedAsset } from 'actions/billOfLadingActions';

import {
  transformCustomItems,
  transformLooseItems,
  transformAssets,
  transformItems
} from './helpers';

import qrCodeIco from 'assets/images/qrcode.svg';

import { IconButton } from '@material-ui/core';

export default function AddDeliveryItemsPopup({ isMobile, open, setOpen, data, onAddClick }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [values, setValues] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);

  const [openQR, setOpenQR] = useState(false);

  const [looseItems, setLooseItems] = useState([]);

  const {
    isCreationViaPicklist,
    isCreationViaLeavelist,
    excludePicklistAssetIds,
    excludeLeavelistAssetIds,
    excludeLooseItemIds,
    excludeAssetIds
  } = data;

  const isCustomBoL = !isCreationViaPicklist && !isCreationViaLeavelist;

  const creationForm = useSelector(selectBoLCreationFormData());
  const { itemsForAdding } = creationForm;

  const title = isMobile ? 'Add Item to BOL' : 'Add Item to Bill of Lading';

  useEffect(() => {
    if (isCustomBoL) {
      dispatch(getBillOfLadingLooseItems({ filters: { isBOL: true, BOLId: id || null } })).then(
        (res) => setLooseItems(res)
      );
    }
  }, []);

  const isFormInvalid = () =>
    !values?.['items']?.length &&
    !values?.['customItems']?.length &&
    !values?.['assets']?.length &&
    !values?.['looseItems']?.length;

  const handleCancelClick = () => setOpen(false);

  const handleAddClick = () => {
    if (isFormInvalid()) {
      setIsInvalid(true);
      return;
    }

    const customItems = transformCustomItems(values['customItems']);
    const items = transformItems(values['items'], itemsForAdding, isCreationViaPicklist);
    const looseItems = transformLooseItems(values.looseItems);
    const assets = transformAssets(values.assets);

    onAddClick([...customItems, ...items, ...looseItems, ...assets]);
    setOpen(false);
  };

  const handleSelect = (name, value) => {
    isInvalid && setIsInvalid(false);
    const updatedValues = { ...values, [name]: [...value] };
    setValues(updatedValues);
  };

  const getPicklistAssetOptions = () =>
    itemsForAdding
      .map(({ picklistAsset }) => picklistAsset)
      .filter(({ id }) => !excludePicklistAssetIds.includes(id)) || [];
  const getLeavelistAssetOptions = () =>
    itemsForAdding
      .map(({ leavelistAsset }) => leavelistAsset)
      .filter(({ id }) => !excludeLeavelistAssetIds.includes(id)) || [];

  const getUniqueAssets = (assetsArray) =>
    assetsArray.filter(
      (value, index, array) => array.findIndex((item) => item.drCode === value.drCode) === index
    );
  const onAssetsSelect = (codes) => {
    isInvalid && setIsInvalid(false);
    setValues({ ...values, assets: getUniqueAssets(codes) });
  };
  const handlePasteArray = ({ value }) => {
    if (!value?.length) return;
    isInvalid && setIsInvalid(false);
    setValues({
      ...values,
      assets: values?.assets?.length ? getUniqueAssets([...values.assets].concat(value)) : value
    });
  };

  const handleQRScan = (url) => {
    const assetId = getAssetIdFromUrl(url);

    dispatch(getScannedAsset(assetId)).then((res) => {
      if (!res?.id) return;
      const { id, drCode, deleted, isInPicklist, isInLeavelist, rental, assetFields } = res;

      if (deleted) {
        enqueueErrorSnackbar(getErrorFindAssetMessage(drCode));
        return;
      }

      if (isInPicklist?.name !== 'No') {
        enqueueErrorSnackbar(getErrorAssetInPicklistMessage(drCode));
        return;
      }

      if (isInLeavelist?.name !== 'No') {
        enqueueErrorSnackbar(getErrorAssetInLeavelistMessage(drCode));
        return;
      }

      if (rental?.rentalStatus?.name !== 'Available') {
        enqueueErrorSnackbar(getErrorAssetIsNotAvailableMessage(drCode));
        return;
      }

      if (excludeAssetIds.includes(id)) {
        return;
      }

      isInvalid && setIsInvalid(false);

      const weightValue =
        assetFields?.find(({ assetField }) => assetField?.name?.toLowerCase() === 'weight')
          ?.value || 0;

      const parseWeight = (weightStr) => {
        if (/[a-zA-Z]/.test(weightStr)) return 0;
        const weight = parseFloat(weightStr);
        return isNaN(weight) ? 0 : weight;
      };

      const asset = { ...res, weight: parseWeight(weightValue) };

      setValues({
        ...values,
        assets: values?.assets?.length
          ? getUniqueAssets([...values.assets].concat([asset]))
          : [asset]
      });
    });
  };

  return (
    <DialogWrapper open={open} onClose={handleCancelClick}>
      {openQR && <QRCodeScanner open={openQR} setOpen={setOpenQR} onScan={handleQRScan} />}
      <section className={styles.wrapper}>
        <DialogHeader onClose={handleCancelClick} title={title} />
        <div className={styles.form}>
          {(isCreationViaPicklist || isCreationViaLeavelist) && (
            <div className={styles.form__block}>
              <label className={styles.form__block_label}>
                Select Item from {isCreationViaPicklist ? 'Picklist' : 'Leavelist'}
              </label>
              <div className={styles.form__block_input}>
                <AutocompleteWithSelectAll
                  name="items"
                  label="itemName"
                  value={values?.['items'] || []}
                  options={
                    isCreationViaPicklist ? getPicklistAssetOptions() : getLeavelistAssetOptions()
                  }
                  onSelect={handleSelect}
                  selectAll={false}
                  disableByObjectTracker={false}
                  isInvalid={isInvalid}
                />
              </div>
            </div>
          )}
          {isCustomBoL && (
            <div className={styles.form__block}>
              <label className={styles.form__block_label}>
                Enter Asset DR Codes; codes must be valid DR Codes
              </label>
              <div className={styles.form__block_input}>
                <MultipleAssetSelectionField
                  value={values?.assets || []}
                  onSelect={onAssetsSelect}
                  onPaste={handlePasteArray}
                  queryOptions={{ isBOL: true, BOLId: id || null, excludeAssetIds }}
                  isInvalid={isInvalid}
                />
                <IconButton className={styles.form__block_qr} onClick={() => setOpenQR(true)}>
                  <img src={qrCodeIco} alt="qrcode" />
                </IconButton>
              </div>
            </div>
          )}
          {isCustomBoL && (
            <div className={styles.form__block}>
              <label className={styles.form__block_label}>Loose Item</label>
              <div className={styles.form__block_input}>
                <AutocompleteWithSelectAll
                  name="looseItems"
                  label="name"
                  value={values?.['looseItems'] || []}
                  options={looseItems.filter(({ id }) => !excludeLooseItemIds.includes(id)) || []}
                  onSelect={handleSelect}
                  selectAll={false}
                  disableByObjectTracker={false}
                  isInvalid={isInvalid}
                />
              </div>
            </div>
          )}
          <div className={styles.form__block}>
            <label className={styles.form__block_label}>
              Enter Non deployed resources owned Item
            </label>
            <div className={styles.form__block_input}>
              <AddingNonExistingItemsField
                name="customItems"
                hintText="After entering Item press “Enter” button"
                rule={allKeyboardLatSymbols}
                max={100}
                chipName="name"
                values={values?.['customItems'] || []}
                onChange={handleSelect}
                isInvalid={isInvalid}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={handleCancelClick} type="secondary" />
          <MainButton text="Add" action={handleAddClick} type="primary" isDisabled={isInvalid} />
          {isInvalid && <span>At least one field is required</span>}
        </div>
      </section>
    </DialogWrapper>
  );
}
