import api from '../api';
import { browserHistory } from '../browserHistory';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

import {
  SET_PROJECT_LIST,
  CLEAR_PROJECT_LIST_STATE,
  SET_CURRENT_PROJECT,
  SET_LOAD_MORE_FLAG,
  SET_PROJECT_LIST_FILTER,
  SET_PROJECT_STATES_LIST,
  RESET_PROJECT_LIST_FILTER
} from 'constants/reduceConstants';
import {
  SUCCESS_CREATE_PROJECT,
  SUCCESS_DELETE_PROJECT,
  SUCCESS_UPDATE_PROJECT,
  SUCCESS_UPDATE_PROJECT_LOCATIONS
} from '../constants/infoSnackbarData';

import { PROJECT_DETAILED_PATH, PROJECT_LIST_PATH } from '../constants/routeConstants';

export const getProjectList = (query) => (dispatch) => {
  return api.project
    .getProjectListApi(query)
    .then((res) => {
      dispatch({ type: SET_PROJECT_LIST, payload: res });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getProjectLocationList = (query) => () =>
  api.locations
    .getLocationsListApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const updateProjectLocations = (data) => () => {
  return api.project.updateProjectLocationsApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_PROJECT_LOCATIONS);
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const getSingleProject = (id) => (dispatch) => {
  api.project
    .getSingleProjectApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_PROJECT, payload: res }))
    .catch((err) => console.log(err));
};

export const getProjectStatesList = () => (dispatch) => {
  api.project
    .getStatesListApi()
    .then((res) => dispatch({ type: SET_PROJECT_STATES_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const createProject = (data) => () => {
  return api.project.createProjectApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_PROJECT);
      browserHistory.push(`${PROJECT_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateProject = (data) => () => {
  return api.project.updateProjectApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_PROJECT);
      browserHistory.push(`${PROJECT_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const deleteProject = (id) => () => {
  return api.project.deleteProjectApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_PROJECT);
      browserHistory.push(PROJECT_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const getLocationList = (query) => () =>
  api.project
    .getLocationListApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setProjectListFilter = (data) => (dispatch) => {
  dispatch({ type: SET_PROJECT_LIST_FILTER, payload: data });
};
export const resetProjectListFilter = (data) => (dispatch) => {
  dispatch({ type: RESET_PROJECT_LIST_FILTER, payload: data });
};

export const clearProjectListState = () => (dispatch) => {
  dispatch({ type: CLEAR_PROJECT_LIST_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};
