import React from 'react';
import styles from './CustomDetails.module.scss';
import SectionLabel from '../SectionLabel';
import { Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';

export default function CustomTableSection({ field, fieldIndex }) {
  const { data } = field;
  const { text, required, rows, compare } = data;

  return (
    <section className={styles.custom_table_section}>
      <SectionLabel>
        {text}
        {required && '*'}
      </SectionLabel>
      <div className={styles.table}>
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                {rows[0].columns.map(({ text }, i) => (
                  <StyledTableCell key={i}>{text || ''}</StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows?.length &&
                rows.map(({ text, columns }, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell>{text || ''}</StyledTableCell>
                    {columns?.length &&
                      columns.map((_, index) => (
                        <StyledTableCell key={index}>
                          <FormTextArea
                            name={`generalReportFields[${fieldIndex}].data.rows[${i}].columns[${index}].value`}
                            options={{
                              max: 500,
                              rule: compare && /[^0-9]/g,
                              minRows: 1,
                              disableCounter: true,
                              fullHeight: true,
                              onlyErrorHighlight: true
                            }}
                          />
                        </StyledTableCell>
                      ))}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
}
