import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import FilesUpload from 'components/FilesUploader/FilesUpload/FilesUpload';

export default function FormFilesUpload({ name = 'resources', format, csvOnly, max, placeholder }) {
  const { control, setValue, getValues } = useFormContext();

  const handleChange = (files) => {
    const resources = getValues(name) || [];
    const otherFiles = resources?.filter((resource) => resource['resourceType']?.id !== 2) || [];
    const result = [...otherFiles, ...files];
    setValue(name, result, { shouldDirty: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <FilesUpload
          uploadedFiles={value?.filter((resource) => resource['resourceType']?.id === 2) || []}
          onChange={handleChange}
          formatType={format}
          csvOnly={csvOnly}
          max={max}
          placeholder={placeholder}
        />
      )}
    />
  );
}
