import React from 'react';
import styles from './AddBomForm.module.scss';

import Controls from './Controls';
import Table from './Table';

export default function Form() {
  return (
    <div className={styles.form}>
      <Controls />
      <Table />
    </div>
  );
}
