import React, { useEffect, useState } from 'react';
import styles from './CustomDetails.module.scss';
import FormLabeledCheckbox from 'components/FormComponents/FormCheckbox/FormLabeledCheckbox';
import ChoiceHintText from './CustomFields/ChoiceHintText';
import SectionLabel from '../SectionLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import StyledRadio from 'components/StyledComponents/StyledRadio';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

export default function ChoiceSection({ fieldItem, index }) {
  const { id } = useParams();

  const { setValue, getValues, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;
  const [radioGroupValue, setRadioGroupValue] = useState('');
  const optionsName = `generalReportFields[${index}].data.options`;

  const getDefaultRadioValue = () => {
    const optionFieldName = `generalReportFields[${index}]`;
    const needField = getValues(optionFieldName);

    if (needField?.data?.multiple || !needField?.data?.options?.length) return;

    needField['data']['options'] = needField.data.options.map((option, i) => {
      if (i === 0) {
        return { ...option, selected: true };
      } else return option;
    });

    setValue(optionFieldName, needField);
  };

  useEffect(() => {
    if (!id) {
      if (fieldItem.data.multiple || !fieldItem.data.options.length) return;
      setRadioGroupValue(fieldItem.data.options[0].id);
      getDefaultRadioValue();
    } else {
      const optionId = getValues(optionsName)?.find(({ selected }) => selected)?.id || '';
      if (optionId) {
        setRadioGroupValue(optionId);
      } else {
        setRadioGroupValue(fieldItem.data.options[0].id);
        getDefaultRadioValue();
      }
    }
  }, [id]);

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setRadioGroupValue(value);
    const values = getValues(optionsName) || [];
    const newValues = values.map((el) => {
      if (el.id === value) return { ...el, selected: true };
      return { ...el, selected: false, comment: '' };
    });
    setValue(optionsName, newValues);
    checkIfErrorExist();
  };

  const checkIfErrorExist = () => {
    if (errors?.generalReportFields?.[index]?.data?.options?.message) {
      clearErrors(optionsName);
    }
  };

  const isRadioCommentAvailable = (optionId) =>
    fieldItem?.data?.['isComment'] && radioGroupValue === optionId;
  const isCheckboxCommentAvailable = (name) => fieldItem?.data?.['isComment'] && !!getValues(name);

  const handleCheckboxClick = (event, optionIndex) => {
    if (!event.target.checked) {
      setValue(`${optionsName}[${optionIndex}].comment`, '');
    }
    checkIfErrorExist();
  };

  return (
    <div className={styles.choice_wrapper}>
      <div
        className={clsx(
          styles.choice_section,
          !!errors?.generalReportFields?.[index]?.data?.options?.message && styles.error
        )}>
        <SectionLabel>
          {fieldItem.data.text}
          {fieldItem.data.required && '*'}
        </SectionLabel>
        {fieldItem.data.multiple ? (
          <div className={styles.choice_checkboxes}>
            {fieldItem.data.options.map((option, optionIndex) => (
              <>
                <div className={styles.checkbox_option} key={optionIndex}>
                  <FormLabeledCheckbox
                    name={`${optionsName}[${optionIndex}].selected`}
                    label={option.text}
                    extraAction={(e) => handleCheckboxClick(e, optionIndex)}
                  />
                  {option.createTicket?.needCreation && <ChoiceHintText />}
                </div>
                <Controller
                  name={`${optionsName}[${optionIndex}].selected`}
                  render={() =>
                    isCheckboxCommentAvailable(`${optionsName}[${optionIndex}].selected`) ? (
                      <div className={styles.comment}>
                        <FormInputText
                          name={`${optionsName}[${optionIndex}].comment`}
                          options={{ max: 65000 }}
                          classes={styles.comment__input}
                        />
                      </div>
                    ) : null
                  }
                />
              </>
            ))}
          </div>
        ) : (
          <div className={styles.choice_radios}>
            <FormControl component="fieldset">
              <Controller
                control={control}
                name={optionsName}
                render={({ field }) => (
                  <RadioGroup {...field} value={radioGroupValue} onChange={handleRadioChange}>
                    {fieldItem.data.options.map((option, optionIndex) => (
                      <>
                        <div className={styles.radio_option} key={optionIndex}>
                          <FormControlLabel
                            value={option.id}
                            control={<StyledRadio />}
                            label={<span className={styles.radio_label}>{option.text}</span>}
                          />
                          {option.createTicket?.needCreation && <ChoiceHintText />}
                        </div>
                        {isRadioCommentAvailable(option.id) && (
                          <div className={styles.comment}>
                            <FormInputText
                              name={`${optionsName}[${optionIndex}].comment`}
                              options={{ max: 65000 }}
                              classes={styles.comment__input}
                            />
                          </div>
                        )}
                      </>
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </div>
        )}
      </div>
      {!!errors?.generalReportFields?.[index]?.data?.options?.message && (
        <span className={styles.validation_error}>
          {errors.generalReportFields[index].data.options.message || ''}
        </span>
      )}
    </div>
  );
}
