import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  selectLocationListData,
  selectSingleAssembleData,
  selectStatusListFilterData
} from '../selectors';
import {
  clearAssembledLooseItemState,
  deleteAssemble,
  getLocationsWithSublocations,
  getSingleAssemble,
  getStatusListForFilter,
  updateAssemble
} from 'actions/assembledLooseItemActions';
import { useHistory, useParams } from 'react-router-dom';
import styles from './AssembleDetailed.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import {
  formatDate,
  isAdminUser,
  isTeamMemberUser,
  dateTimeFormat,
  truncateString,
  enqueueErrorSnackbar,
  enqueueSuccessSnackbar,
  getUserFullName
} from 'helpers/AppHelpers';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { Checkbox, Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import MainButton from 'components/StyledComponents/MainButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ASSEMBLED_BOM_LIST_PATH } from 'constants/routeConstants';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import SelectInput from 'components/SelectInput/SelectInput';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { TRY_AGAIN_MESSAGE } from 'constants/infoSnackbarData';
import { REMOVE_ASSEMBLE } from 'constants/dialogPopupsData';
import DeleteIcon from '@material-ui/icons/Delete';
import { selectUserConfig } from '../../../commonSelectors';
import clsx from 'clsx';
import { useMobileViewport } from 'hooks/useMobileViewport';
import DetailedRow from 'components/DetailsComponents/DetailedRow';

const InAssemblyStatus = 'InAssembly';
const AssembledStatus = 'Assembled';
const DisassembledStatus = 'Disassembled';
const ClosedStatus = 'Closed';

function AssembleDetailed({
  userConfig,
  currentAssemble,
  locationList,
  getLocationsAction,
  getAssembleAction,
  statusList,
  getStatusListAction,
  updateAssembleAction,
  deleteAssembleAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const [values, setValues] = useState({ active: false, isDisabled: false });
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [tableState, setTableState] = useState([]);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const { userRole } = userConfig;

  useEffect(() => {
    if (!locationList?.length) {
      getLocationsAction({ filters: { active: true } });
    }
  }, [locationList]);

  const getRows = (items, multiplier) => {
    const newRows = [];
    if (items?.length) {
      items.forEach((el) => {
        let row = {};
        for (let i = 0; i < el.locations.length; i += 1) {
          row = {
            id: el.id,
            name: el.name,
            location: el.locations[i].location,
            sublocation: el.locations[i]?.sublocation,
            pointOfOrigin: el.locations[i]?.pointOfOrigin,
            selectedQuantity: el.locations[i].selectedQuantity,
            selectedByAheadAccount: el.locations[i]?.selectedByAheadAccount,
            requiredQuantity: el.requiredQuantity * (multiplier ? multiplier : 1)
          };
          newRows.push(row);
        }
      });
    }
    return newRows;
  };

  useEffect(() => {
    if (id) {
      getAssembleAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (!statusList?.length) {
      getStatusListAction();
    }
  }, [statusList]);

  useEffect(() => {
    if (currentAssemble?.id) {
      setBreadcrumbs([{ name: truncateString(currentAssemble.name, 25) }]);
      if (
        currentAssemble.status.name === InAssemblyStatus ||
        currentAssemble.status.name === AssembledStatus
      ) {
        let newTableState = [];
        newTableState = getRows(currentAssemble.looseItems, currentAssemble.quantity);
        setTableState(newTableState);
      } else {
        const newValues = currentAssemble.looseItems.map((el) => {
          return { ...el, requiredQuantity: el.requiredQuantity * currentAssemble.quantity };
        });
        setTableState(newValues);
      }
    } else {
      setTableState([]);
    }
  }, [currentAssemble]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const isFirstNameCell = (index) => {
    return !(index !== 0 && tableState[index]?.id === tableState[index - 1]?.id);
  };

  const getCellNumber = (rowId) => {
    return tableState.filter(({ id }) => id === rowId)?.length;
  };

  const onChangeStatus = (status) => {
    const newLooseItems = isFirstTableView()
      ? currentAssemble.looseItems
      : [...tableState].map((el) => {
          return {
            ...el,
            requiredQuantity: el.requiredQuantity / currentAssemble.quantity
          };
        });
    updateAssembleAction({
      ...currentAssemble,
      status: { ...statusList.find((el) => el.name === status) },
      looseItems: newLooseItems
    }).then((res) => {
      if (res.id) {
        const message = `The assemble ${currentAssemble?.name || ''} status changed from ${
          currentAssemble?.status?.displayName || ''
        } to ${status}`;
        enqueueSuccessSnackbar(message);
      } else {
        enqueueErrorSnackbar(TRY_AGAIN_MESSAGE);
      }
    });
  };

  const onSelect = (name, value, index) => {
    const newLocation = { ...value };
    const newArray = [...tableState];
    newArray[index][name] = newLocation;
    if (name === 'location') {
      newArray[index].sublocation = null;
    }
    setTableState(newArray);
    if (values.active) setValues({ active: false, isDisabled: false });
  };
  const onSublocationSelect = (item, index) => {
    const newArray = [...tableState];
    newArray[index] = { ...newArray[index], sublocation: item.value };
    setTableState(newArray);
  };

  const getButtons = () => {
    switch (currentAssemble?.status?.name) {
      case InAssemblyStatus:
        return (
          <section className={styles.singleFooterControl}>
            {!isTeamMemberUser(userRole) && isOwnAssembly() && (
              <MainButton
                text={AssembledStatus}
                type="primary"
                action={() => onChangeStatus(AssembledStatus)}
              />
            )}
          </section>
        );
      case AssembledStatus:
        return (
          <section className={styles.singleFooterControl}>
            {!isTeamMemberUser(userRole) && isOwnAssembly() && (
              <MainButton
                text="Disassemble"
                type="primary"
                action={() => onChangeStatus(DisassembledStatus)}
              />
            )}
          </section>
        );
      case DisassembledStatus:
        return (
          <section className={styles.singleFooterControl}>
            {!isTeamMemberUser(userRole) && isOwnAssembly() && (
              <MainButton
                text="Close project"
                type="primary"
                action={() => onChangeStatus(ClosedStatus)}
              />
            )}
          </section>
        );
      case ClosedStatus:
        return <></>;
      default:
        return <></>;
    }
  };

  const isStatus = (status) => {
    return currentAssemble?.status?.name === status;
  };

  const enrichLocationOptions = (options, location) => {
    if (options?.length && location?.id && !options.map(({ id }) => id).includes(location.id)) {
      return options.concat({ ...location }).sort((a, b) => a.id > b.id);
    } else return options;
  };

  const onLocationSelect = (selectedItem) => {
    const newLocation = { ...selectedItem.value };
    const newArray = [...tableState].map((el) => {
      return { ...el, location: newLocation, sublocation: null };
    });
    setTableState(newArray);
    setValues({
      ...values,
      [selectedItem.name]: selectedItem.value,
      isDisabled: true
    });
  };

  const onAgree = () => {
    deleteAssembleAction(currentAssemble.id);
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  const onChange = () => {
    setValues({ active: !values.active });
  };

  const isOwnAssembly = () => {
    return (
      isAdminUser(userRole) ||
      (!isAdminUser(userRole) && currentAssemble?.createdByUser?.email === userConfig.email)
    );
  };

  const isFirstTableView = () => isStatus(InAssemblyStatus) || isStatus(AssembledStatus);
  const getSublocations = (locationId) =>
    locationList?.find(({ id }) => id === locationId)?.sublocations || [];

  return (
    <section className={styles.pageContainer}>
      <DialogPopup
        data={dialogModalData}
        onAgree={onAgree}
        onDissmiss={onDismiss}
        isMobile={isMobile}
      />
      {currentAssemble?.id === +id && (
        <>
          <div>
            {!isMobile && <BreadcrumbsNav itemsArray={breadcrumbs} />}
            <section className={styles.headerBlock}>
              {!isMobile && <h1>{currentAssemble.name}</h1>}
              <div className={styles.headerBlock__controls}>
                {(isStatus(InAssemblyStatus) || isStatus(ClosedStatus)) &&
                  !isTeamMemberUser(userRole) &&
                  isOwnAssembly() && (
                    <button
                      onClick={() => setDialogModalData({ ...REMOVE_ASSEMBLE, isOpened: true })}>
                      <DeleteIcon />
                      {!isMobile && <span>Remove assemble</span>}
                    </button>
                  )}
                {isMobile && (
                  <button onClick={() => history.push(ASSEMBLED_BOM_LIST_PATH)}>
                    <ChevronLeftIcon />
                  </button>
                )}
              </div>
            </section>
            <section className={styles.contentWrapper}>
              {isMobile && <h1>{currentAssemble.name}</h1>}

              <DetailedRow
                label="Location"
                value={currentAssemble?.location?.siteCode || ''}
                disableFlex
              />
              <DetailedRow
                label="Sublocation"
                value={currentAssemble?.sublocation?.name || ''}
                disableFlex
              />
              <DetailedRow
                label="Status"
                value={currentAssemble?.status?.displayName || ''}
                disableFlex
              />
              <DetailedRow
                label="Configuration"
                value={currentAssemble?.looseItemConfiguration?.name || ''}
                disableFlex
              />
              <DetailedRow label="Quantity" value={currentAssemble?.quantity || 0} disableFlex />
              <DetailedRow
                label="Created by"
                value={getUserFullName(currentAssemble?.createdByUser)}
                disableFlex
              />
              <DetailedRow
                label="Created"
                value={
                  currentAssemble?.createdAtUtc
                    ? formatDate(currentAssemble?.createdAtUtc, dateTimeFormat)
                    : ''
                }
                disableFlex
              />
              <DetailedRow label="Notes" value={currentAssemble?.notes || ''} disableFlex />
            </section>
            {!!tableState.length && (
              <TableContainer className={styles.tableContainer} component={Paper}>
                <Table>
                  <TableHead>
                    <StyledTableCell>Loose item</StyledTableCell>
                    {isFirstTableView() ? (
                      <>
                        <StyledTableCell>Required quantity</StyledTableCell>
                        <StyledTableCell>Point of origin(Sublocation)</StyledTableCell>
                        <StyledTableCell>Selected quantity</StyledTableCell>
                        <StyledTableCell>
                          From BAA<span className={styles.footnote}>[1]</span>
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell>
                          Released quantity(BAA<span className={styles.footnote}>[1]</span>)
                        </StyledTableCell>
                        <StyledTableCell>Point of origin(Sublocation)</StyledTableCell>
                        <StyledTableCell>Point of destination</StyledTableCell>
                        <StyledTableCell>Sublocation</StyledTableCell>
                      </>
                    )}
                  </TableHead>
                  <TableBody>
                    {tableState.map((row, index) =>
                      isFirstTableView() ? (
                        <StyledTableRow key={row.id}>
                          {isFirstNameCell(index) && (
                            <>
                              <StyledTableCell
                                rowSpan={getCellNumber(row.id)}
                                className={styles.nameCell}>
                                {row?.name || ''}
                              </StyledTableCell>
                              <StyledTableCell
                                rowSpan={getCellNumber(row.id)}
                                className={styles.quantityCell}>
                                {row?.requiredQuantity || 0}
                              </StyledTableCell>
                            </>
                          )}
                          <StyledTableCell className={styles.originCell}>
                            {isStatus(AssembledStatus) ? (
                              <>{row?.pointOfOrigin || ''}</>
                            ) : (
                              <>
                                {row?.location?.siteCode || ''}
                                {row?.sublocation?.id ? '(' + row.sublocation?.name + ')' : ''}
                              </>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className={styles.selectedQtyCell}>
                            {row?.selectedQuantity || 0}
                          </StyledTableCell>
                          <StyledTableCell className={styles.selectedBaaCell}>
                            {row?.selectedByAheadAccount || 0}
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell className={styles.nameCell}>
                            {row?.name || ''}
                          </StyledTableCell>
                          <StyledTableCell className={styles.quantityCell}>
                            {row?.requiredQuantity || 0}
                            {row?.requiredBaaQuantity ? '(' + row.requiredBaaQuantity + ')' : ''}
                          </StyledTableCell>
                          <StyledTableCell className={styles.originCell}>
                            {row?.pointOfOrigin || ''}
                          </StyledTableCell>
                          <StyledTableCell
                            className={clsx(
                              styles.locationCell,
                              isStatus(DisassembledStatus) && styles.noSpace
                            )}>
                            {isStatus(DisassembledStatus) &&
                            !isTeamMemberUser(userRole) &&
                            isOwnAssembly() ? (
                              <InputSearchWithMultiselect
                                name="location"
                                defaultValue={row?.location?.id || ''}
                                options={enrichLocationOptions(locationList, row.location) || []}
                                onFilterSelect={onSelect}
                                index={index}
                                multiselect={false}
                                tableView={true}
                              />
                            ) : (
                              <>{row?.location?.siteCode || ''}</>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            className={clsx(
                              styles.sublocationCell,
                              isStatus(DisassembledStatus) && styles.noSpace
                            )}>
                            {isStatus(DisassembledStatus) &&
                            !isTeamMemberUser(userRole) &&
                            isOwnAssembly() ? (
                              <SelectInput
                                name="sublocation"
                                value={row?.sublocation?.id || ''}
                                menuItems={getSublocations(row?.location?.id) || []}
                                onSelect={onSublocationSelect}
                                index={index}
                                tableView={true}
                              />
                            ) : (
                              <>{row?.sublocation?.name || ''}</>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <div className={styles.footer}>
              {!!tableState.length && (
                <label className={styles.hintLabel}>[1]Buy Ahead Account</label>
              )}
              {isStatus(DisassembledStatus) && !isTeamMemberUser(userRole) && isOwnAssembly() && (
                <section className={styles.tableFooter}>
                  <section className={styles.checkboxRow}>
                    <label>KEEP DESTINATION ALL THE SAME</label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        classes={{
                          root: styles.checkbox,
                          checked: styles.checked,
                          disabled: styles.disabled
                        }}
                        name="active"
                        onChange={onChange}
                        value={values?.active}
                        checked={!!values.active}
                        disabled={!!values.isDisabled}
                      />
                    </div>
                  </section>
                  <div className={styles.inputWrapper}>
                    <SelectInput
                      name="siteCode"
                      value={values.siteCode?.id || ''}
                      menuItems={locationList || []}
                      onSelect={onLocationSelect}
                      disabled={!values.active}
                    />
                  </div>
                </section>
              )}
            </div>
          </div>
          <>{getButtons()}</>
        </>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  currentAssemble: selectSingleAssembleData(),
  statusList: selectStatusListFilterData(),
  locationList: selectLocationListData(),
  userConfig: selectUserConfig()
});

const mapDispatchToProps = {
  getAssembleAction: getSingleAssemble,
  getLocationsAction: getLocationsWithSublocations,
  updateAssembleAction: updateAssemble,
  getStatusListAction: getStatusListForFilter,
  deleteAssembleAction: deleteAssemble,
  clearStateAction: clearAssembledLooseItemState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AssembleDetailed);
