import api from '../api';
import {
  CLEAR_ASSEMBLE_BOM_STATE,
  SET_ASSEMBLE_LIST,
  SET_LOCATION_LIST_FOR_CREATE_ASSEMBLE,
  SET_CONFIGURATION_LIST_FOR_CREATE_ASSEMBLE,
  SET_ASSEMBLE_CONFIG_OPTIONS,
  SET_LOCATION_LIST_FOR_FILTER,
  SET_CONFIGURATION_LIST_FOR_FILTER,
  SET_STATUS_LIST_FOR_FILTER,
  SET_ASSEMBLE_BOM_FILTER,
  SET_CURRENT_ASSEMBLE
} from '../constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import { SUCCESS_DELETE_ASSEMBLY, SUCCESS_CREATE_ASSEMBLE } from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import { ASSEMBLED_BOM_DETAILED_PATH, ASSEMBLED_BOM_LIST_PATH } from '../constants/routeConstants';

export const getAssembleList = (query) => (dispatch) => {
  api.assembleBOM
    .getAssembleListApi(query)
    .then((res) => dispatch({ type: SET_ASSEMBLE_LIST, payload: res }))
    .catch((err) => console.log(err));
};

export const getLocationsWithSublocations = (query) => (dispatch) => {
  api.assembleBOM
    .getLocationsWithSublocationsApi(query)
    .then((res) => dispatch({ type: SET_LOCATION_LIST_FOR_CREATE_ASSEMBLE, payload: res }))
    .catch((err) => console.log(err));
};

export const getConfigurationList = (query) => (dispatch) => {
  api.assembleBOM
    .getConfigurationListApi(query)
    .then((res) => dispatch({ type: SET_CONFIGURATION_LIST_FOR_CREATE_ASSEMBLE, payload: res }))
    .catch((err) => console.log(err));
};

export const getAssembledLooseItems = (query) => (dispatch) => {
  return api.assembleBOM
    .getAssembledLooseItemsApi(query)
    .then((res) => dispatch({ type: SET_ASSEMBLE_CONFIG_OPTIONS, payload: res }))
    .catch((err) => console.log(err));
};

export const createAssemble = (data) => () => {
  return api.assembleBOM.createAssembleApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_ASSEMBLE);
      browserHistory.push(`${ASSEMBLED_BOM_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateAssemble = (data) => (dispatch) => {
  return api.assembleBOM.updateAssembleApi(data).then(
    (res) => {
      dispatch({ type: CLEAR_ASSEMBLE_BOM_STATE });
      dispatch({ type: SET_CURRENT_ASSEMBLE, payload: res });
      browserHistory.push(`${ASSEMBLED_BOM_DETAILED_PATH}/${res.id}`);
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const getLocationListForFilter = (query) => (dispatch) => {
  api.assembleBOM
    .getLocationsListApi(query)
    .then((res) => dispatch({ type: SET_LOCATION_LIST_FOR_FILTER, payload: res }))
    .catch((err) => console.log(err));
};

export const getStatusListForFilter = (query) => (dispatch) => {
  api.assembleBOM
    .getStatusListApi(query)
    .then((res) => dispatch({ type: SET_STATUS_LIST_FOR_FILTER, payload: res }))
    .catch((err) => console.log(err));
};
export const getConfigurationListForFilter = () => (dispatch) => {
  api.assembleBOM
    .getConfigurationListApi()
    .then((res) => dispatch({ type: SET_CONFIGURATION_LIST_FOR_FILTER, payload: res }))
    .catch((err) => console.log(err));
};

export const getSingleAssemble = (id) => (dispatch) => {
  api.assembleBOM
    .getSingleAssembleApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_ASSEMBLE, payload: res }))
    .catch((err) => console.log(err));
};

export const deleteAssemble = (id) => () => {
  return api.assembleBOM.deleteAssembleApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_ASSEMBLY);
      browserHistory.push(ASSEMBLED_BOM_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const clearAssembledLooseItemState = () => (dispatch) => {
  dispatch({ type: CLEAR_ASSEMBLE_BOM_STATE });
};

export const setAssembleListFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSEMBLE_BOM_FILTER, payload });
};
