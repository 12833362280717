import api from '../api';
import {
  CLEAR_TEAM_MEMBER_STATE,
  SET_TEAM_MEMBER_CREATION_FORM,
  SET_CURRENT_TEAM_MEMBER,
  SET_TEAM_MEMBERS_FILTER,
  SET_LOAD_MORE_FLAG,
  SET_TEAM_MEMBERS_FILTER_CRITERIA
} from '../constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import {
  ERROR_NO_OPPORTUNITY_TO_CANCEL_LAST_ACTION,
  SUCCESS_CANCEL_SCHEDULE_LAST_ACTION,
  SUCCESS_CREATE_TEAM_MEMBER,
  SUCCESS_DELETE_TEAM_MEMBER,
  SUCCESS_UPDATE_TEAM_MEMBER
} from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import { TEAM_MEMBER_DETAILED_PATH, TEAM_MEMBER_LIST_PATH } from '../constants/routeConstants';

export const getTeamMembers = (query) => (dispatch) =>
  api.teamMember
    .getTeamMemberListApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getCreationForm = () => (dispatch) => {
  return api.teamMember
    .getCreationFormApi()
    .then((res) => {
      dispatch({ type: SET_TEAM_MEMBER_CREATION_FORM, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getFilterCriteria = (q) => (dispatch) =>
  api.teamMember
    .getTeamMemberFilterCriteriaApi(q)
    .then((res) => {
      dispatch({ type: SET_TEAM_MEMBERS_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getSingleTeamMember =
  (id, shouldUpdateState = true) =>
  (dispatch) =>
    api.teamMember
      .getSingleTeamMemberApi(id)
      .then((res) => {
        shouldUpdateState && dispatch({ type: SET_CURRENT_TEAM_MEMBER, payload: res });
        return res;
      })
      .catch((err) => console.log(err));

export const createTeamMember = (data) => () => {
  return api.teamMember.createTeamMemberApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_TEAM_MEMBER);
      browserHistory.push(`${TEAM_MEMBER_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      !('deletedTeamMemberId' in errors?.errors) && enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updateTeamMember = (data) => () => {
  return api.teamMember.updateTeamMemberApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_TEAM_MEMBER);
      browserHistory.push(`${TEAM_MEMBER_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      !('deletedTeamMemberId' in errors?.errors) && enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deleteTeamMember = (id, getUserConfigAction) => () => {
  return api.teamMember.deleteTeamMemberApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_TEAM_MEMBER);
      browserHistory.push(TEAM_MEMBER_LIST_PATH);
      getUserConfigAction();
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const restoreTeamMember = (id, getConfig) => (dispatch) => {
  return api.teamMember.restoreTeamMemberApi(id).then(
    (res) => {
      getConfig && dispatch({ type: SET_CURRENT_TEAM_MEMBER, payload: res });
      getConfig && getConfig();
      !getConfig && browserHistory.push(`${TEAM_MEMBER_DETAILED_PATH}/${id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const deleteTeamMemberParameter = (query, prefix) => () => {
  return api.teamMember.deleteTeamMemberParameterApi(query, prefix).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      prefix === 'Status' && enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updateTeamMemberParameter = (params, prefix) => () => {
  return api.teamMember.updateTeamMemberParameterApi(params, prefix).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const getTeamMemberCalendar = (query) => () =>
  api.teamMember
    .getTeamMemberCalendarApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getPermissionToCancelLastOperation = () => () =>
  api.teamMember
    .getPermissionToCancelLastOperationApi()
    .then((res) => {
      if (!res?.isCancelAction) {
        enqueueErrorSnackbar(ERROR_NO_OPPORTUNITY_TO_CANCEL_LAST_ACTION);
        return;
      }
      return 'APPROVED';
    })
    .catch((err) => console.log(err));

export const cancelLastOperation = () => () =>
  api.teamMember
    .cancelLastOperationApi()
    .then((res) => {
      if (res.status === 200) {
        enqueueSuccessSnackbar(SUCCESS_CANCEL_SCHEDULE_LAST_ACTION);
      }
      return res.status;
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const setFilter = (data) => (dispatch) => {
  dispatch({ type: SET_TEAM_MEMBERS_FILTER, payload: data });
};

export const clearState = (params) => (dispatch) => {
  dispatch({ type: CLEAR_TEAM_MEMBER_STATE, payload: params });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};

// Edit Capacities Popup
export const getCapacitiesCreationForm = () => () => {
  return api.teamMember
    .getCapacityListApi()
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const getTeamMemberCapacities = () => () => {
  return api.teamMember
    .getTeamMemberCapacitiesApi()
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const updateTeamMemberCapacities = (data) => () => {
  return api.teamMember.updateTeamMemberCapacitiesApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};
