import { useSelector } from 'react-redux';

import {
  selectIsEditModeData,
  selectPicklistData
} from 'pages/PicklistAndLeavelist/ManagePicklistItem/selectors';

export function useManagePicklistItemSelector() {
  const picklistData = useSelector(selectPicklistData());

  const isEditMode = useSelector(selectIsEditModeData());

  return {
    picklistData,
    isEditMode
  };
}
