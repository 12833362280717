import React, { useEffect } from 'react';
import styles from './AssetReportEdit.module.scss';

import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import ReportEditWrapper from '../../commonComponents/ReportEditWrapper';
import AssetReportDetails from './components/GeneralDetails';

import { useReportSelector, useReportActions } from 'hooks/ReportList';
import { useSearchParams } from 'hooks/useSearchParams';

import { formatDate, wait } from 'helpers/AppHelpers';
import { ASSET_REPORT_LIST_PATH } from 'constants/routeConstants';

export default function AssetReportEdit() {
  const { id } = useParams();
  const history = useHistory();

  const { generalReportTemplateId, assetId } = useSearchParams([
    'generalReportTemplateId',
    'assetId'
  ]);

  const { currentGeneralReport } = useReportSelector();
  const { startAutoSaveAction, getCreationFormAction } = useReportActions();

  const startAutoSave = () => {
    wait(1000).then(() => startAutoSaveAction());
  };

  const methods = useForm({
    defaultValues: {
      reportType: {},
      asset: {},
      generalReportTemplate: {},
      generalReportFields: [],
      resources: [],
      assignedAtUtc: null,
      signatures: []
    },
    mode: 'onChange'
  });
  const { reset, getValues } = methods;

  useEffect(() => {
    if (id) return;

    if (!assetId) {
      history.push(ASSET_REPORT_LIST_PATH);
      return;
    }

    getCreationFormAction({ assetId, generalReportTemplateId }).then((res) => {
      if (!res?.asset?.id) return;

      const { asset, generalReportTemplate } = res;
      const reportFields = generalReportTemplate?.generalReportFields.sort(
        (a, b) => a.order - b.order
      );

      const presetValues = {
        ...getValues(),
        createdAtUtc: formatDate(new Date()),
        reportType: { ...generalReportTemplate?.reportType },
        asset,
        location: { ...asset.location },
        generalReportTemplate: { id: generalReportTemplate?.id, name: generalReportTemplate?.name },
        generalReportFields: [...reportFields]
      };
      reset(presetValues);
      startAutoSave();
    });
  }, []);

  useEffect(() => {
    if (id && currentGeneralReport?.id) {
      reset(currentGeneralReport);
      const { generalReportTemplate, asset } = currentGeneralReport;
      const q = {
        generalReportTemplateId: generalReportTemplate.id,
        assetId: asset?.id || null
      };

      getCreationFormAction(q);
      if (!currentGeneralReport.submitted) {
        startAutoSave();
      }
    }
  }, [currentGeneralReport]);

  return (
    <section className={styles.pageContainer}>
      <FormProvider {...methods}>
        <ReportEditWrapper reportType="asset">
          <AssetReportDetails />
        </ReportEditWrapper>
      </FormProvider>
    </section>
  );
}
