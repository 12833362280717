import React from 'react';

import Wrapper from './components/Wrapper';
import Form from './components/Form';

import { FormProvider, useForm } from 'react-hook-form';

export default function AddBomToPicklist() {
  const methods = useForm({
    defaultValues: {
      configuration: {},
      looseItems: []
    },
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <Form />
      </Wrapper>
    </FormProvider>
  );
}
