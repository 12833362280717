import React, { useState } from 'react';
import styles from './Signature.module.scss';
import { Button, IconButton } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SignaturePopup from '../Popup/SignaturePopup';
import { Controller, useFormContext } from 'react-hook-form';
import DialogPopup from '../../DialogPopup/DialogPopup';
import { DELETE_SIGNATURE } from 'constants/dialogPopupsData';
import { useDispatch } from 'react-redux';
import { getResourceViaBytes } from 'actions/commonActions';

export default function Signature({ name }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const { getValues, setValue, clearErrors } = useFormContext();

  const openPopup = () => setOpen(true);
  const closePopup = () => setOpen(false);

  const clearStates = () => {
    closeModal();
    closePopup();
  };

  const handleDeleteClick = () => setModalData(DELETE_SIGNATURE);
  const handleApply = (baseStr) => {
    if (!baseStr) {
      clearStates();
      return;
    }
    dispatch(getResourceViaBytes(baseStr)).then((res) => {
      if (res?.status === 200) {
        clearStates();
        setValue(name, res.data);
        clearErrors(name);
      }
    });
  };
  const handleEditClick = () => openPopup();

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    setValue(name, null);
    clearStates();
  };

  return (
    <section className={styles.wrapper}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      {open && <SignaturePopup open={open} setOpen={setOpen} onApply={handleApply} />}
      <Controller
        name={name}
        render={({ fieldState: { error } }) => (
          <div className={styles.signature}>
            <div className={styles.signature__preview}>
              {getValues(name)?.id && (
                <div className={styles.signature__preview_image}>
                  <IconButton
                    onClick={handleDeleteClick}
                    className={styles.signature__preview_image_delete}>
                    <CancelOutlinedIcon />
                  </IconButton>
                  <img src={getValues(name)['link']} alt="image" />
                </div>
              )}
              <Button className={styles.signature__preview_button} onClick={handleEditClick}>
                <span>{getValues(name)?.id ? 'Edit signature' : 'Add signature'}</span>
              </Button>
            </div>
            {!!error?.message && <span className={styles.signature__error}>{error.message}</span>}
          </div>
        )}
      />
    </section>
  );
}
