import React, { useEffect, useRef, useState } from 'react';
import styles from './AssembleCreate.module.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  selectLocationListData,
  selectLooseItemConfigurationsData,
  selectConfigOptionsData
} from '../selectors';
import {
  clearAssembledLooseItemState,
  createAssemble,
  getAssembledLooseItems,
  getConfigurationList,
  getLocationsWithSublocations
} from 'actions/assembledLooseItemActions';
import MainButton from 'components/StyledComponents/MainButton';
import PageForm from './components/PageForm';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import PageTable from './components/PageTable';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  CREATE_ASSEMBLE,
  CREATE_ASSEMBLE_TYPE,
  EXIT_WITHOUT_SAVING,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { useHistory } from 'react-router-dom';
import { getValidationObj } from 'helpers/AppHelpers';
import { setTopPosition, setUnsavedFormData } from 'actions/commonActions';
import { useMobileViewport } from 'hooks/useMobileViewport';
import BackButton from 'components/BackButton/BackButton';

function AssembleCreate({
  locationList,
  configOptions,
  getLocationsAction,
  looseItemsConfigurationList,
  getConfigurationListAction,
  getAssembledLooseItemsAction,
  createAssembleAction,
  setUnsavedFormDataAction,
  setTopPositionAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const containerRef = useRef(null);
  const [values, setValues] = useState({});

  const initValue = {};

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [tableValidationErrors, setTableValidationErrors] = useState([]);
  const [validationQuantityErrors, setValidationQuantityErrors] = useState([]);
  const [tableState, setTableState] = useState([]);
  const [quantityList, setQuantityList] = useState([]);
  const [key, setKey] = useState(2);
  const [savePressed, setSavePressed] = useState(false);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });
  const [routeUrl, setRouteUrl] = useState('');

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  useEffect(() => {
    setUnsavedFormDataAction(!formIsNotChanged);
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [values]);

  useEffect(() => {
    getLocationsAction({ filters: { active: true } });
  }, []);

  useEffect(() => {
    getConfigurationListAction();
  }, []);

  useEffect(() => {
    setBreadcrumbs([{ name: 'Create assemble' }]);
  }, []);

  useEffect(() => {
    if (values?.looseItemConfiguration?.id) {
      getAssembledLooseItemsAction({ id: values.looseItemConfiguration.id });
      setTableValidationErrors([]);
    }
  }, [values?.looseItemConfiguration?.id]);

  useEffect(() => {
    if (configOptions?.looseItems?.length) {
      setTableState(getRows(configOptions.looseItems));
      getQuantityList(configOptions['maximumQuantity']);
      setValues({ ...values, quantity: 1 });
    }
  }, [configOptions]);

  useEffect(() => {
    if (values?.quantity) {
      setTableState(getRows(configOptions.looseItems, values.quantity));
      setTableValidationErrors([]);
      setValidationQuantityErrors([]);
    }
  }, [values?.quantity]);

  const getRows = (items, multiplier) => {
    const newRows = [];
    if (items?.length) {
      items.forEach((el) => {
        let row = {};
        for (let i = 0; i < el.locations.length; i += 1) {
          row = {
            id: el.id,
            name: el.name,
            requiredQuantity: el.requiredQuantity * (multiplier ? multiplier : 1),
            location: el.locations[i].location,
            sublocation: el.locations[i]?.sublocation || null,
            quantity: el.locations[i].quantity,
            byAheadAccount: el.locations[i]?.byAheadAccount,
            selectedQuantity: el?.locations[i]?.selectedQuantity || 0,
            fakeLocation: { ...el.locations[i] }
          };
          newRows.push(row);
        }
      });
    }
    return newRows;
  };

  const getQuantityList = (max) => {
    const quantityValues = [];
    if (max !== 0) {
      for (let i = 0; i < max; i += 1) {
        const item = { id: i + 1, name: `${i + 1}`, value: i + 1 };
        quantityValues.push(item);
      }
    } else {
      const item = { id: 0, name: `${0}`, value: 0 };
      quantityValues.push(item);
    }
    setQuantityList(quantityValues);
  };

  const validateForm = (key, newMessage) => {
    if (key) {
      setValidationErrors((state) => ({ ...state, [key]: newMessage }));
      return;
    }
    let isValid = true;
    if (!values.name || !values.name.trim()) {
      setValidationErrors((state) => ({
        ...state,
        name: 'Assembly name field is required'
      }));
      isValid = false;
    }
    if (Object.keys(values?.looseItemConfiguration || {}).length === 0) {
      setValidationErrors((state) => ({
        ...state,
        looseItemConfiguration: 'This field is required'
      }));
      isValid = false;
    }
    if (Object.keys(values?.location || {}).length === 0) {
      setValidationErrors((state) => ({
        ...state,
        location: 'Location field is required'
      }));
      isValid = false;
    }
    if (!values?.quantity) {
      setValidationErrors((state) => ({
        ...state,
        quantity: 'This field is required'
      }));
      isValid = false;
    }
    return isValid;
  };

  const parseTableState = () => {
    const newArray = [];
    const partIds = [...new Set(tableState.map((el) => el.id))];
    partIds.forEach((el) => {
      const locations = tableState
        .filter((item) => item.id === el)
        .map((option) => {
          return {
            location: option.location,
            selectedQuantity: option.selectedQuantity,
            selectedByAheadAccount: option.selectedByAheadAccount,
            sublocation: option?.sublocation?.id ? option.sublocation : null
          };
        });
      const partObject = {
        ...configOptions.looseItems.find((elem) => elem.id === el),
        requiredQuantity: tableState.find((elem) => elem.id === el).requiredQuantity,
        locations: locations
      };
      newArray.push(partObject);
    });
    return newArray;
  };

  const checkQuantities = (parts) => {
    const newArray = parts.map((el) => el.locations);
    const commonArray = [];
    for (let i = 0; i < newArray.length; i += 1) {
      const sum = newArray[i]
        .map((el) => el.selectedQuantity)
        .reduce((sum, current) => sum + current, 0);
      commonArray.push({
        partId: parts[i].id,
        partName: parts[i].name,
        quantitiesSum: sum
      });
    }
    const validArray = tableValidationErrors;
    commonArray.forEach((el) => {
      if (el.quantitiesSum !== parts.find((option) => el.partId === option.id).requiredQuantity) {
        validArray[el.partId] = {
          name: 'The required value exceeds selected value. Please, change the configuration value.'
        };
      }
    });
    setTableValidationErrors(validArray);
    setKey(new Date().getTime() + Math.random());
  };

  const filterEmptyLocations = (parts) => {
    return parts.map((el) => {
      return {
        ...el,
        locations: el.locations.filter((item) => item.selectedQuantity !== 0)
      };
    });
  };

  const onCancel = () => {
    if (!formIsNotChanged) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      history.goBack();
    }
  };

  const onCreate = () => {
    const looseItems = parseTableState();
    checkQuantities(looseItems);
    if (!validateForm() || !isTableValid()) {
      setSavePressed(true);
      return;
    }
    setDialogModalData({ ...CREATE_ASSEMBLE, isOpened: true });
  };

  const isTableValid = () => {
    let isValid = true;
    tableValidationErrors.forEach((el) => {
      if (Object.keys(el || {}).length !== 0) isValid = false;
    });
    validationQuantityErrors.forEach((el) => {
      if (!!el?.quantity || !!el?.byAheadAccount) isValid = false;
    });
    return isValid;
  };

  const formIsInvalid = () =>
    Object.values(validationErrors).filter(Boolean).length ||
    Object.keys(values?.looseItemConfiguration || {}).length === 0;

  const isEnoughQuantity = () => configOptions?.maximumQuantity !== 0;

  const formIsNotChanged = JSON.stringify(initValue) === JSON.stringify(values);

  const onAgree = () => {
    switch (dialogModalData.type) {
      case CREATE_ASSEMBLE_TYPE: {
        setDialogModalData({ isOpened: false });
        const looseItems = parseTableState();
        const filteredParts = filterEmptyLocations(looseItems);
        const data = { ...values, looseItems: [...filteredParts] };
        createAssembleAction(data).then((err) => {
          if (err?.errors) {
            setValidationErrors({ ...validationErrors, ...getValidationObj(err) });
            containerRef.current.scrollIntoView();
          }
        });
        break;
      }
      case WITHOUT_SAVING_TYPE:
        history.goBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(routeUrl);
        break;
      default:
        break;
    }
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  const validatedFields = {
    looseItemConfiguration: useRef(null),
    quantity: useRef(null),
    name: useRef(null),
    location: useRef(null)
  };

  useEffect(() => {
    if (formIsInvalid() && !isTableValid() && savePressed) {
      const firstInvalidElem =
        validatedFields[Object.keys(validationErrors).find((key) => validationErrors[key])];

      if (firstInvalidElem) {
        const elemRectData = firstInvalidElem.current?.getBoundingClientRect();
        setTopPositionAction(elemRectData?.y);
      }

      setSavePressed(false);
    }
  }, [validationErrors]);

  return (
    <section className={styles.pageContainer} ref={containerRef}>
      <DialogPopup
        data={dialogModalData}
        onAgree={onAgree}
        onDissmiss={onDismiss}
        isMobile={isMobile}
      />
      <div>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs}
            setDialogModalData={setDialogModalData}
            formIsChanged={!formIsNotChanged}
            setRouteUrl={setRouteUrl}
          />
        )}
        <div className={styles.pageHeader}>
          <h1>Create assemble</h1>
          {isMobile && <BackButton onCancel={onCancel} />}
        </div>
        <PageForm
          looseItemsConfigurationList={looseItemsConfigurationList}
          validationErrors={validationErrors}
          values={values}
          locationList={locationList}
          quantityList={quantityList}
          validatedFields={validatedFields}
          validateForm={validateForm}
          setValues={setValues}
        />
        {isEnoughQuantity() && (
          <>
            <PageTable
              tableState={tableState}
              setTableState={setTableState}
              configOptions={configOptions}
              tableValidationErrors={tableValidationErrors}
              validationQuantityErrors={validationQuantityErrors}
              setTableValidationErrors={setTableValidationErrors}
              setValidationQuantityErrors={setValidationQuantityErrors}
              key={key}
            />
            {!!tableState.length && (
              <label className={styles.hintLabel}>[1]Buy Ahead Account</label>
            )}
          </>
        )}
      </div>
      <div className={styles.footerControls}>
        <MainButton text="cancel" type="secondary" action={onCancel} />
        <MainButton
          text="Save"
          type="primary"
          action={onCreate}
          isDisabled={!!formIsInvalid() || !isTableValid() || !isEnoughQuantity()}
        />
      </div>
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  locationList: selectLocationListData(),
  looseItemsConfigurationList: selectLooseItemConfigurationsData(),
  configOptions: selectConfigOptionsData()
});

const mapDispatchToProps = {
  getLocationsAction: getLocationsWithSublocations,
  getConfigurationListAction: getConfigurationList,
  getAssembledLooseItemsAction: getAssembledLooseItems,
  createAssembleAction: createAssemble,
  setUnsavedFormDataAction: setUnsavedFormData,
  setTopPositionAction: setTopPosition,
  clearStateAction: clearAssembledLooseItemState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AssembleCreate);
