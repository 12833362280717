import React, { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MainButton from 'components/StyledComponents/MainButton';
import styles from './DialogPopup.module.scss';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DialogPopup({ data, onAgree, onDissmiss }) {
  return (
    <Dialog
      open={!!data.isOpened}
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop
        }
      }}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle
        classes={{
          root: styles.popupTitle
        }}
        id="alert-dialog-slide-title">
        {data.title}
      </DialogTitle>
      {data.text && (
        <DialogContent
          classes={{
            root: styles.popupText
          }}>
          <DialogContentText id="alert-dialog-slide-description">{data.text}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions
        classes={{
          root: styles.popupControls
        }}>
        <MainButton text={data.cancelText} action={onDissmiss} type="secondary" size="popup" />
        <MainButton text={data.agreeText} action={onAgree} type="primary" size="popup" />
      </DialogActions>
    </Dialog>
  );
}
