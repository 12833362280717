import React, { useState } from 'react';
import styles from './ConfigurationItemsTable.module.scss';

import {
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { selectConfigurationCreationFormData } from '../selectors';

import clsx from 'clsx';

import { pluck, removeDuplicates } from 'helpers/AppHelpers';
import CloseIcon from '@material-ui/icons/Close';

export default function ConfigurationItemsTable() {
  const { looseItemCategories, looseItems } = useSelector(selectConfigurationCreationFormData());

  const [render, setRender] = useState(0);

  const [categories, setCategories] = useState([]);

  const { control, formState, clearErrors, setValue, getValues } = useFormContext();
  const { errors } = formState;
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'looseItems'
  });

  const getFilteredLooseItems = (index) => {
    if (!looseItems?.length) return [];

    const rowLooseItem = getValues('looseItems')[index];
    const rowItemId = rowLooseItem?.id;
    const excludedItemIds = removeDuplicates(pluck(getValues('looseItems'), 'id').filter(Boolean));
    const excludedItemsWithoutRowItemId = excludedItemIds.filter((id) => id !== rowItemId);

    if (!categories?.length) {
      return looseItems.filter(({ id }) => !excludedItemsWithoutRowItemId.includes(id));
    } else {
      const categoryIds = pluck(categories, 'id');

      const options = looseItems
        .filter(({ id }) => !excludedItemsWithoutRowItemId.includes(id))
        .filter(
          ({ looseItemCategories }) =>
            !!looseItemCategories.filter(({ id }) => categoryIds.includes(id)).length
        );

      if (options?.length && rowItemId && !options.map(({ id }) => id).includes(rowItemId)) {
        return options.concat(rowLooseItem).sort((a, b) => a.id > b.id);
      } else return options;
    }
  };

  const onRemoveRow = (index) => {
    remove(index);
    clearErrors(`looseItems[${index}].quantity`);
  };

  const handleSelect = (name, value) => {
    setValue(name, { quantity: getValues(name)['quantity'], ...value });
    setRender(render + 1);
  };

  const onAddRow = () => append({ quantity: 1 });

  const categorySelect = (name, value) => setCategories(value);
  const deleteCategory = (itemId) => setCategories(categories.filter(({ id }) => id !== itemId));

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <div className={styles.controls__field}>
          <label>Narrow by Category</label>
          <InputSearchWithMultiselect
            name="categories"
            defaultValue={categories?.map(({ id }) => id) || []}
            options={looseItemCategories || []}
            onFilterSelect={categorySelect}
            multiselect
            shouldHideTags
          />
        </div>
        {!!categories.length && (
          <div className={styles.controls__chips}>
            {categories.map((category) => (
              <Chip
                key={category.id}
                label={category?.name || ''}
                deleteIcon={<CloseIcon />}
                className={styles.controls__chips_item}
                onDelete={() => deleteCategory(category.id)}
              />
            ))}
          </div>
        )}
      </div>
      <TableContainer component={Paper} className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Loose Item</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!fields?.length &&
              fields.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <FormSearchInput
                      name={`looseItems[${index}]`}
                      options={getFilteredLooseItems(index)}
                      onSelect={handleSelect}
                      classes={clsx(styles.select_input_style)}
                      disableError
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      errors?.looseItems?.[index]?.quantity?.message && styles.redBackground
                    )}>
                    <FormInputText
                      name={`looseItems[${index}].quantity`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        disableError: true
                      }}
                      classes={clsx(styles.quantity_input_style)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {fields.length !== 1 && (
                      <IconButton onClick={() => onRemoveRow(index)}>
                        <CancelIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <Button className={styles.add_button} onClick={onAddRow}>
          <AddIcon />
          <span>Add</span>
        </Button>
      </div>
    </div>
  );
}
