import React, { useEffect, useState } from 'react';

import { Counting } from 'components/Counting';

import { getSum, pluck, getSeverityColor } from 'helpers/AppHelpers';

const severityOrder = ['Operational', 'Non-Critical', 'Critical', 'Beyond repair', 'Total'];

export default function SeverityCalculations({ counting }) {
  const [counts, setCounts] = useState([]);

  useEffect(() => {
    if (!counting?.length) return;

    const data = counting.map(({ severity, count }) => ({
      label: severity?.displayName || '',
      color: getSeverityColor(severity.name),
      count
    }));

    const totalCount = getSum(pluck(counting, 'count'));
    data.push({
      label: 'Total',
      color: getSeverityColor('Total'),
      count: totalCount,
      boldLabel: true
    });

    const sortedData = severityOrder.map((name) => data.find(({ label }) => label === name));

    setCounts(sortedData);
  }, [counting]);

  return <Counting counts={counts} />;
}
