import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getLocationLooseItemsApi = (query) =>
  api.get(`/LocationLooseItems?${queryToString(query)}`).then((res) => res.data);

export const checkLooseItemsLocationApi = (q) =>
  api.get(`/PicklistAssets/LocationCheck?${queryToString(q)}`).then((res) => res.data);

export const addLooseItemsToPicklistApi = (params) =>
  api.post('/PicklistLooseItems', params).then((res) => res);

export const editLooseItemOfPicklistApi = (params) =>
  api.put('/PicklistLooseItem', params).then((res) => res);
