import {
  ASSET_REPORT_LIST_PATH,
  GENERAL_REPORT_LIST_PATH,
  LOCATION_REPORT_LIST_PATH
} from 'constants/routeConstants';

export const getRootListPath = (reportType) => {
  switch (reportType) {
    case 'location':
      return LOCATION_REPORT_LIST_PATH;
    case 'general':
      return GENERAL_REPORT_LIST_PATH;
    case 'asset':
      return ASSET_REPORT_LIST_PATH;
  }
};
