import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { selectLocationsFilterListData, selectProjectFilterListData } from '../selectors';
import { getMapLocations, getMapProjectList } from 'actions/locationsActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import FilterIcon from 'assets/images/filter.svg';
import { Chip, Fade, IconButton, Paper, Popper } from '@material-ui/core';
import styles from './ActiveLocationsFilter.module.scss';
import { MainButton } from 'components/StyledComponents';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';
import FilterHeader from 'components/FilterComponents/FilterHeader';
import AutocompleteWithSelectAll from 'components/AutocompleteWithSelectAll/AutocompleteWithSelectAll';

function ActiveLocationsFilter({
  isMobile,
  filter,
  onApply,
  setFilter,
  openFilter,
  setOpenFilter,
  projectFilterList,
  getProjectFilterListAction,
  isAdvancedFilterApplied,
  locationFilterList,
  getLocationsAction
}) {
  const { filters } = filter;
  const [anchorEl, setAnchorEl] = useState(null);
  const [chips, setChips] = useState([]);
  const [key, setKey] = useState(new Date().getTime() + Math.random());
  const [values, setValues] = useState({ ...filters });

  const createChips = (inputFilter) => {
    const chipsArray = [];
    if (inputFilter?.locationIds?.length) {
      inputFilter.locationIds.forEach((item) => {
        const newChip = locationFilterList.find((option) => option.id === item);
        chipsArray.push({ ...newChip, key: 'locationIds', chipName: newChip?.siteCode || '' });
      });
    }
    if (inputFilter?.locationJobNumberIds?.length) {
      inputFilter.locationJobNumberIds.forEach((item) => {
        const newChip = locationFilterList.find((option) => option.id === item);
        chipsArray.push({
          ...newChip,
          key: 'locationJobNumberIds',
          chipName: newChip?.locationJobNumber || ''
        });
      });
    }
    if (inputFilter?.projectIds.length) {
      inputFilter.projectIds.forEach((item) => {
        const newChip = projectFilterList.find((option) => option.id === item);
        chipsArray.push({
          ...newChip,
          key: 'projectIds',
          chipName: newChip?.name || ''
        });
      });
    }
    setChips(chipsArray);
  };

  useEffect(() => {
    getProjectFilterListAction();
  }, []);

  useEffect(() => {
    getLocationsAction({ filters: { active: true, includeUserCheck: true } });
  }, []);

  useEffect(() => {
    if (isAdvancedFilterApplied()) {
      clearFilterAfterAppliedAdvancedFilter();
    }
  }, [isAdvancedFilterApplied()]);

  const handleFilterOpen = (event) => {
    setOpenFilter(!openFilter);
    setAnchorEl(event.currentTarget);
    setValues(filters);
    isMobile && createChips(filters);
  };

  const onFilterSelect = (name, value) => {
    const commonFilter = {
      ...values,
      projectIds: [...value.map(({ id }) => id)]
    };
    setValues(commonFilter);
    isMobile && createChips(commonFilter);
  };

  const applyFilter = () => {
    const commonObj = { ...filter, filters: { ...values } };
    createChips(values);
    setOpenFilter(!openFilter);
    setFilter(commonObj);
    onApply(commonObj);
  };

  const clearFilterAfterAppliedAdvancedFilter = () => {
    const defaultState = { ...values, projectIds: [], locationIds: [], locationJobNumberIds: [] };
    setFilter({ ...filter, filters: { ...defaultState } });
    setValues(defaultState);
    setOpenFilter(!openFilter);
    setChips([]);
  };

  const handleClearFilter = () => {
    const defaultState = { ...values, projectIds: [], locationIds: [], locationJobNumberIds: [] };
    setValues(defaultState);
    isMobile && setChips([]);
  };

  const closeFilterHandle = () => {
    isMobile && createChips(filters);
    setOpenFilter(!openFilter);
  };

  const handleChipDelete = (item) => {
    const commonFilter = {
      ...values,
      locationIds:
        item.key === 'locationIds'
          ? values.locationIds.filter((id) => id !== item.id)
          : values.locationIds,
      locationJobNumberIds:
        item.key === 'locationJobNumberIds'
          ? values.locationJobNumberIds.filter((id) => id !== item.id)
          : values.locationJobNumberIds,
      projectIds:
        item.key === 'projectIds'
          ? values.projectIds.filter((id) => id !== item.id)
          : values.projectIds
    };
    setChips(chips.filter((chip) => !(chip.key === item.key && chip.id === item.id)));
    setValues(commonFilter);
    if (!isMobile) {
      setFilter({ ...filter, filters: { ...commonFilter } });
      onApply({ ...filter, filters: { ...commonFilter } });
    }
    isMobile && setKey(new Date().getTime() + Math.random());
  };

  const selectOption = (name, value) => {
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
    isMobile && createChips(updatedValues);
  };

  return (
    <div className={styles.filterWrapper}>
      <IconButton
        className={clsx(
          styles.filterButton,
          !isMobile
            ? (openFilter || chips?.length) && styles.filterButton__opened
            : chips?.length && styles.filterButton__opened
        )}
        onClick={handleFilterOpen}
        component="span">
        <img src={FilterIcon} alt="filter" />
      </IconButton>
      <Popper
        open={openFilter}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        className={styles.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={{ root: styles.filterContainer }}>
              {isMobile && <FilterHeader onBack={closeFilterHandle} />}
              <section className={styles.formColumn}>
                <section className={styles.formCell}>
                  <label>Location</label>
                  <div className={styles.inputWrapper}>
                    <AutocompleteWithSelectAll
                      name="locationIds"
                      label="siteCode"
                      value={values?.locationIds || []}
                      options={locationFilterList || []}
                      onSelect={selectOption}
                      selectAll={false}
                      disableByObjectTracker={true}
                    />
                  </div>
                </section>
                <section className={styles.formCell}>
                  <label>Job Number</label>
                  <div className={styles.inputWrapper}>
                    <AutocompleteWithSelectAll
                      name="locationJobNumberIds"
                      label="locationJobNumber"
                      value={values?.locationJobNumberIds || []}
                      options={locationFilterList || []}
                      onSelect={selectOption}
                      selectAll={false}
                      disableByObjectTracker={true}
                    />
                  </div>
                </section>
                <section className={styles.formCell}>
                  <label>Projects</label>
                  <div className={styles.inputWrapper}>
                    <InputSearchWithMultiselect
                      name="projects"
                      defaultValue={values?.projectIds || []}
                      options={projectFilterList || []}
                      onFilterSelect={onFilterSelect}
                      multiselect={true}
                      refreshKey={key}
                    />
                  </div>
                </section>
                {isMobile && (
                  <div className={styles.chipsBlock}>
                    {!!chips?.length &&
                      chips.map((item) => (
                        <Chip
                          classes={{ icon: styles.chipIcon }}
                          key={`${item.id}-${item.key}`}
                          label={item.chipName}
                          deleteIcon={<CloseIcon />}
                          className={styles.fieldsChip}
                          onDelete={() => handleChipDelete(item)}
                        />
                      ))}
                  </div>
                )}
                <section className={styles.buttonWrapper}>
                  <ClearFilterButton onClick={handleClearFilter} />
                  <div className={styles.primary}>
                    <MainButton
                      text="Cancel"
                      action={closeFilterHandle}
                      type="secondary"
                      size="filter_popup"
                    />
                    <MainButton
                      text="Apply"
                      action={applyFilter}
                      type="primary"
                      size="filter_popup"
                    />
                  </div>
                </section>
              </section>
            </Paper>
          </Fade>
        )}
      </Popper>
      {!isMobile && (
        <section className={styles.chipsBlock}>
          {!!chips?.length &&
            chips.map((item) => (
              <Chip
                classes={{ icon: styles.chipIcon }}
                key={`${item.id}-${item.key}`}
                label={item.chipName}
                deleteIcon={<CloseIcon />}
                className={styles.fieldsChip}
                onDelete={() => handleChipDelete(item)}
              />
            ))}
        </section>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  projectFilterList: selectProjectFilterListData(),
  locationFilterList: selectLocationsFilterListData()
});

const mapDispatchToProps = {
  getProjectFilterListAction: getMapProjectList,
  getLocationsAction: getMapLocations
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ActiveLocationsFilter);
