import React, { useEffect, useState } from 'react';
import styles from './BOMConfigurationDetailed.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import { useHistory, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectSingleConfigurationData } from '../selectors';
import {
  clearConfigurationState,
  deleteConfiguration,
  getSingleConfiguration
} from 'actions/looseItemConfigurationActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  BOM_CONFIGURATION_EDIT_PATH,
  BOM_CONFIGURATIONS_LIST_PATH,
  LOOSE_ITEM_DETAILED_PATH
} from 'constants/routeConstants';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  DELETE_TENT_CONFIGURATION,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_TYPE
} from 'constants/dialogPopupsData';
import { fileDownload, isAdminUser, isOperationsManagerUser } from 'helpers/AppHelpers';
import { selectUserConfig } from 'pages/commonSelectors';
import { useMobileViewport } from 'hooks/useMobileViewport';
import LinkComponent from 'components/LinkComponent/LinkComponent';

function BOMConfigurationDetailed({
  userConfig,
  currentConfiguration,
  getConfigurationAction,
  deleteConfigurationAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const { userRole } = userConfig;

  useEffect(() => {
    if (id) {
      getConfigurationAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (currentConfiguration?.id) {
      setBreadcrumbs([{ name: currentConfiguration.name }]);
    }
    return () => {
      if (currentConfiguration?.id) {
        clearStateAction();
      }
    };
  }, [currentConfiguration]);

  const onAgree = () => {
    switch (dialogModalData.type) {
      case DOWNLOAD_FILE_TYPE:
        setDialogModalData({ isOpened: false });
        fileDownload(selectedFile);
        break;
      default:
        deleteConfigurationAction(currentConfiguration.id);
        break;
    }
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  const getFiles =
    currentConfiguration?.resources?.filter((resource) => resource.resourceType?.id === 2) || [];

  const onFileDownload = (file) => {
    setDialogModalData({ ...DOWNLOAD_FILE, isOpened: true });
    setSelectedFile(file);
  };

  return (
    <section className={styles.pageContainer}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
      {currentConfiguration?.id === +id && (
        <div>
          {!isMobile && <BreadcrumbsNav itemsArray={breadcrumbs} />}
          <section className={styles.headerBlock}>
            {!isMobile && <h1>{currentConfiguration.name}</h1>}
            <div className={styles.headerBlock__controls}>
              {(isAdminUser(userRole) || isOperationsManagerUser(userRole)) && (
                <button
                  onClick={() =>
                    history.push(`${BOM_CONFIGURATION_EDIT_PATH}/${currentConfiguration.id}`)
                  }>
                  <CreateIcon />
                  {!isMobile && <span>Edit configuration</span>}
                </button>
              )}
              {isAdminUser(userRole) && (
                <button
                  onClick={() =>
                    setDialogModalData({ ...DELETE_TENT_CONFIGURATION, isOpened: true })
                  }>
                  <DeleteIcon />
                  {!isMobile && <span>Remove configuration</span>}
                </button>
              )}
              {isMobile && (
                <button onClick={() => history.push(BOM_CONFIGURATIONS_LIST_PATH)}>
                  <ChevronLeftIcon />
                </button>
              )}
            </div>
          </section>
          <section className={styles.contentWrapper}>
            {isMobile && <h1>{currentConfiguration.name}</h1>}
            <div className={styles.descriptionBlock}>{currentConfiguration?.description || ''}</div>
            <div className={styles.filesBlock}>
              <h1>Files</h1>
              <div className={styles.filesWrapper}>
                {getFiles.length
                  ? getFiles.map((file) => (
                      <p
                        key={file.id}
                        onClick={() => onFileDownload(file)}
                        className={styles.fileName}>
                        {file.name}
                      </p>
                    ))
                  : ''}
              </div>
            </div>
            <TableContainer className={styles.tableContainer} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={styles.firstRow}>Loose Item</StyledTableCell>
                    <StyledTableCell className={styles.lastRow}>Quantity</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentConfiguration?.looseItems?.length ? (
                    currentConfiguration.looseItems.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell>
                          <LinkComponent
                            path={`${LOOSE_ITEM_DETAILED_PATH}/${row.id}`}
                            name={row.name}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <span>{row.quantity || 0}</span>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={2} className={styles.emptyTable}>
                        No available data in the table.
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </section>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  currentConfiguration: selectSingleConfigurationData(),
  userConfig: selectUserConfig()
});

const mapDispatchToProps = {
  getConfigurationAction: getSingleConfiguration,
  deleteConfigurationAction: deleteConfiguration,
  clearStateAction: clearConfigurationState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BOMConfigurationDetailed);
