const fromCustom = ({ name }) => ({
  id: 0,
  description: '',
  name,
  qty: 1,
  weight: '0'
});
const fromLooseItem = ({ id, name, description, weight }) => ({
  id: 0,
  description,
  name,
  qty: 1,
  weight: weight || '0',
  looseItemId: id
});
const fromPicklistAsset = (el, items) => {
  const isLooseItem = !!el?.locationLooseItem?.id;
  return {
    id: 0,
    description: items?.find(({ picklistAsset }) => picklistAsset.id === el.id)?.description || '',
    name: isLooseItem
      ? el?.locationLooseItem?.looseItem?.name || el?.itemName || ''
      : el?.asset?.drCode || '',
    qty: isLooseItem ? el?.moveQuantity || 0 : 1,
    weight: items?.find(({ picklistAsset }) => picklistAsset.id === el.id)?.weight || '0',
    picklistAssetId: el.id,
    originLocationId: el?.location?.id || null
  };
};
const fromLeavelistAsset = (el, items) => {
  const isLooseItem = !!el?.locationLooseItem?.id;
  return {
    id: 0,
    description:
      items?.find(({ leavelistAsset }) => leavelistAsset.id === el.id)?.description || '',
    name: isLooseItem
      ? el?.locationLooseItem?.looseItem?.name || el?.itemName || ''
      : el?.asset?.drCode || '',
    qty: isLooseItem ? el?.moveQuantity || 0 : 1,
    weight: items?.find(({ leavelistAsset }) => leavelistAsset.id === el.id)?.weight || '0',
    leavelistAssetId: el.id
  };
};
const fromAsset = (el) => {
  const { id, drCode, assetPrefix, weight } = el;
  return {
    id: 0,
    description: assetPrefix?.description || '',
    name: drCode || '',
    qty: 1,
    weight: weight || '0',
    assetId: id
  };
};

export const transformCustomItems = (customItems) => {
  return customItems?.map(fromCustom) || [];
};

export const transformItems = (items, itemsForAdding, viaPicklist) => {
  return (
    items?.map((el) => {
      return viaPicklist
        ? fromPicklistAsset(el, itemsForAdding)
        : fromLeavelistAsset(el, itemsForAdding);
    }) || []
  );
};

export const transformLooseItems = (looseItems) => {
  return looseItems?.map(fromLooseItem) || [];
};

export const transformAssets = (assets) => {
  return assets?.map(fromAsset) || [];
};
