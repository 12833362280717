import React, { useEffect, useState } from 'react';
import styles from './AddBomWrapper.module.scss';

import { useAddBomActions, useAddBomSelector } from 'hooks/AddBomToPicklist';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { PICKLIST_DETAILED_PATH, PICKLIST_SUMMARY_PATH } from 'constants/routeConstants';
import {
  ADD_BOM_TO_PICKLIST,
  ADD_BOM_TO_PICKLIST_TYPE,
  EXIT_WITHOUT_SAVING
} from 'constants/dialogPopupsData';

import { getErrorsProperties } from 'helpers/ErrorValidator';
import {
  EMPTY_QUANTITY_COLUMN_ERROR_NAME,
  errorMessages,
  isQuantityColumnNotEmpty,
  transformValues
} from '../../helpers';

import Header from './Header';
import Footer from './Footer';

import DialogPopup from 'components/DialogPopup/DialogPopup';

export default function AddBomWrapper({ children }) {
  const { formState, getValues, setError } = useFormContext();
  const { isDirty } = formState;

  const [modalData, setModalData] = useState({});

  const history = useHistory();

  const { activePicklist, unsavedFormData } = useAddBomSelector();
  const {
    getConfigurationsAction,
    setUnsavedFormDataAction,
    addItemsToPicklistAction,
    clearStateAction
  } = useAddBomActions();

  useEffect(() => {
    if (activePicklist?.id) return;
    history.push(PICKLIST_SUMMARY_PATH);
  }, []);

  useEffect(() => {
    getConfigurationsAction();
  }, []);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const goToPicklist = () => history.push(`${PICKLIST_DETAILED_PATH}/${activePicklist.id}`);

  const handleBackClick = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      goToPicklist();
    }
  };

  const validateForm = () => {
    const fieldsToValidate = [
      {
        name: 'configuration',
        errorMessage: 'This field is required',
        isInvalid: !getValues('configuration')?.id
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid }) => {
      if (isInvalid) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const handleCreateClick = () => {
    if (!validateForm()) {
      return;
    }

    if (!isQuantityColumnNotEmpty(getValues('looseItems'))) {
      setError(
        EMPTY_QUANTITY_COLUMN_ERROR_NAME,
        getErrorsProperties(errorMessages.atLeastOneValue)
      );
      return;
    }

    setModalData({
      ...ADD_BOM_TO_PICKLIST,
      title: `Do you want to add items to the picklist ${activePicklist?.name}?`
    });
  };

  const closeModal = () => setModalData({});

  const agreeModal = () => {
    if (modalData?.type === ADD_BOM_TO_PICKLIST_TYPE) {
      addItemsToPicklistAction(transformValues(getValues(), activePicklist));
      return;
    }
    goToPicklist();
  };

  return (
    <div className={styles.wrapper}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <Header onBack={handleBackClick} setModalData={setModalData} isDirty={isDirty} />
      {children}
      <Footer onCancel={handleBackClick} onAdd={handleCreateClick} />
    </div>
  );
}
