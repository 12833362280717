import React from 'react';
import styles from './templateFields.module.scss';
import FormDatePicker from 'components/FormComponents/FormDatePicker/FormDatePicker';

export default function DateField({ name, label, isRequired }) {
  return (
    <section className={styles.date_field}>
      {label && (
        <label>
          {label}
          {isRequired && '*'}
        </label>
      )}
      <div className={styles.date_input}>
        <FormDatePicker name={name} />
      </div>
    </section>
  );
}
