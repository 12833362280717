import {
  SUCCESS_ADD_LOOSE_ITEMS_TO_PICKLIST,
  SUCCESS_EDIT_LOOSE_ITEMS_WITHIN_PICKLIST
} from 'constants/infoSnackbarData';
import api from '../api';
import {
  SET_MANAGE_PICKLIST_ITEM_ACTIVE_PICKLIST_DATA,
  CLEAR_MANAGE_PICKLIST_ITEM_STATE
} from 'constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

export const getLocationLooseItems = (query) => () =>
  api.managePicklistItem
    .getLocationLooseItemsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const checkLooseItemsLocation = (query) => () =>
  api.managePicklistItem
    .checkLooseItemsLocationApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const addLooseItemsToPicklist = (data) => () => {
  return api.managePicklistItem.addLooseItemsToPicklistApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(
        SUCCESS_ADD_LOOSE_ITEMS_TO_PICKLIST + ' ' + data?.picklist?.name || ''
      );
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const editLooseItemOfPicklist = (data) => () => {
  return api.managePicklistItem.editLooseItemOfPicklistApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(
        SUCCESS_EDIT_LOOSE_ITEMS_WITHIN_PICKLIST + ' ' + data?.picklist?.name || ''
      );
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const setPicklistData = (payload) => (dispatch) =>
  dispatch({ type: SET_MANAGE_PICKLIST_ITEM_ACTIVE_PICKLIST_DATA, payload });

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_MANAGE_PICKLIST_ITEM_STATE });
