import React from 'react';
import styles from './ConfirmationPopup.module.scss';

import MainButton from 'components/StyledComponents/MainButton';
import DialogWrapper from 'components/DialogComponents/DialogWrapper';

import clsx from 'clsx';

export default function ConfirmationPopup({ data, onAgree, onDismiss }) {
  return (
    <DialogWrapper open={!!data.isOpened} onClose={onDismiss} isConfirmPopup>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {data.content ? (
            data.content
          ) : (
            <h2 className={clsx(styles.title, styles.reducedWidth)}>{data.title}</h2>
          )}
        </div>
        <div className={styles.controls}>
          <MainButton text={data.cancelText} action={onDismiss} type="secondary" />
          <MainButton text={data.agreeText} action={onAgree} type="primary" />
        </div>
      </div>
    </DialogWrapper>
  );
}
