import React from 'react';
import styles from './AddBomFooter.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

import { useFormContext } from 'react-hook-form';

import { EMPTY_QUANTITY_COLUMN_ERROR_NAME } from '../../../helpers';

export default function Footer({ onCancel, onAdd }) {
  const { formState } = useFormContext();
  const { errors } = formState;

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  return (
    <div className={styles.footer}>
      <MainButton text="cancel" type="secondary" action={onCancel} />
      <MainButton text="add" type="primary" action={onAdd} isDisabled={isFormInvalid()} />
      {errors?.[EMPTY_QUANTITY_COLUMN_ERROR_NAME] && (
        <span>{errors[EMPTY_QUANTITY_COLUMN_ERROR_NAME].message}</span>
      )}
    </div>
  );
}
