import React, { useEffect } from 'react';
import styles from './LocationReportEdit.module.scss';

import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import { useReportActions, useReportSelector } from 'hooks/ReportList';
import { useSearchParams } from 'hooks/useSearchParams';
import { useHistory } from 'react-router-dom';

import { formatDate, wait } from 'helpers/AppHelpers';

import ReportEditWrapper from '../../commonComponents/ReportEditWrapper';
import GeneralDetails from './components/GeneralDetails';

import { getWeatherDetails, getWeatherValue } from './helpers';
import { LOCATION_REPORT_LIST_PATH } from 'constants/routeConstants';

export default function LocationReportEdit() {
  const { id } = useParams();
  const history = useHistory();

  const { generalReportTemplateId, locationId, projectId } = useSearchParams([
    'generalReportTemplateId',
    'locationId',
    'projectId'
  ]);

  const { getLocationWeatherAction, getCreationFormAction, startAutoSaveAction } =
    useReportActions();
  const { currentGeneralReport } = useReportSelector();

  const startAutoSave = () => {
    wait(1000).then(() => startAutoSaveAction());
  };

  const methods = useForm({
    defaultValues: {
      reportType: {},
      generalReportTemplate: {},
      generalReportFields: [],
      resources: [],
      assignedAtUtc: null,
      signatures: []
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const getWeather = (date, location) => {
    const lat =
      currentGeneralReport?.location?.latitude ||
      currentGeneralReport?.project?.latitude ||
      location?.latitude ||
      null;
    const lng =
      currentGeneralReport?.location?.longitude ||
      currentGeneralReport?.project?.longitude ||
      location?.longitude ||
      null;
    if (!lat || !lng) return;

    getLocationWeatherAction({ lat, lng, date }).then((res) => {
      const weatherString = getWeatherValue(res);
      const { fieldName, doesWeatherExist } = getWeatherDetails(getValues('generalReportFields'));
      if (doesWeatherExist) {
        setValue(fieldName, weatherString);
      }
    });
  };

  useEffect(() => {
    if (id) return;

    if (!locationId && !projectId) {
      history.push(LOCATION_REPORT_LIST_PATH);
      return;
    }

    const q = { generalReportTemplateId };

    if (locationId) {
      q.locationId = locationId;
    } else {
      q.projectId = projectId;
    }

    getCreationFormAction(q).then((res) => {
      if (!res?.generalReportTemplate?.id) return;

      const { generalReportTemplate } = res;
      const reportFields = generalReportTemplate?.generalReportFields.sort(
        (a, b) => a.order - b.order
      );

      const presetValues = {
        ...getValues(),
        createdAtUtc: formatDate(new Date()),
        reportType: { ...generalReportTemplate?.reportType },
        generalReportTemplate: { id: generalReportTemplate?.id, name: generalReportTemplate?.name },
        generalReportFields: [...reportFields]
      };

      if (locationId) {
        presetValues.location = res.location;
        getWeather(new Date(), res.location);
      } else {
        presetValues.project = res.project;
        getWeather(new Date(), res.project);
      }

      reset(presetValues);
      startAutoSave();
    });
  }, []);

  useEffect(() => {
    if (id && currentGeneralReport?.id) {
      reset(currentGeneralReport);

      const { generalReportTemplate, location, project } = currentGeneralReport;
      const q = {
        generalReportTemplateId: generalReportTemplate.id,
        locationId: location?.id || null,
        projectId: location?.id ? null : project.id
      };

      getCreationFormAction(q);

      if (!currentGeneralReport.submitted) {
        startAutoSave();
      }
    }
  }, [currentGeneralReport]);

  return (
    <section className={styles.pageContainer}>
      <FormProvider {...methods}>
        <ReportEditWrapper reportType="location">
          <GeneralDetails getWeather={getWeather} />
        </ReportEditWrapper>
      </FormProvider>
    </section>
  );
}
